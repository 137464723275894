<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { OpenAPIJSON, OpenAPIExcel } from 'api/openapi';
import vSelect from 'vue-select';
// import Breadcrumbs from '@/views/claims/details/Breadcrumbs.vue';
import DashboardContracts from '@/views/user-management/DashboardContracts.vue';
import UploadNewContract from '@/views/user-management/UploadNewContract.vue';
import PaginationButtons from '../../components/admission/PaginationButtons.vue';
import EditConsultantCostModal from '@/views/user-management/EditConsultantCostModal.vue';
import DuplicateContractModal from '@/views/user-management//DuplicateContractModal.vue';

// State management for contracts and pagination
const insurers = ref([]); // Holds the list of insurers
const selectedInsurer = ref(null); // Currently selected insurer
const contractList = ref(null); // List of contracts
const isUploadModalOpen = ref(false); // Modal state for uploading a new contract
const isEditConsultantCostModalOpen = ref(false); // Modal state for editing consultant cost
const contractName = ref(''); // Name of the selected contract
const insurerName = ref(''); // Name of the selected insurer
const validFrom = ref(''); // Valid from date for the contract
const validTo = ref(''); // Valid to date for the contract
const procedureCodes = ref([]); // Holds procedure codes for the selected contract
const contractId = ref(''); // Contract ID
const insurerId = ref(''); // Insurer ID
const totalItems = ref(0); // Total number of items (for pagination)
const itemsPerPage = ref(20); // Number of items to display per page
const currentPage = ref(1); // Current page in the pagination
const searchTerm = ref(''); // Search term for filtering procedure codes
const selectedProcedureCode = ref(null); // Selected procedure code for editing
const duplicateContract = ref(false); // Modal state for duplicating a contract
const route = useRoute(); // Vue router instance
const redirectAppId = ref(null); // App ID to redirect back to
const claimType = ref(null); // Claim type for the contract
const appointmentId = ref(null); // Appointment ID for the contract

// Breadcrumbs for navigation
// const breadcrumbs = [
//   { name: 'Home', to: '/settings' },
//   { name: 'Manage pricing', to: '/settings/contracts' }
// ];
// const breadcrumbsContracts = [
//   { name: 'Home', to: '/settings' },
//   { name: 'Manage pricing', to: '/settings/contracts' },
//   { name: contractName }
// ];

// Table headers for the contract and procedure codes data
const headers = ref([
  { key: 'contract_name', label: 'CONTRACT NAME', width: 300 },
  { key: 'valid_from', label: 'VALID FROM', width: 250, date: true }
]);

const headersContracts = ref([
  { key: 'procedure_code', label: 'PROCEDURE CODE', width: 100 },
  { key: 'procedure_code_description', label: 'PROCEDURE DESCRIPTION', width: 350 },
  { key: 'price.participating_consultant_cost', label: 'CONSULTANT COST IN €', type2: true, width: 150 }
]);

// Helper function to format the date for display
const formatDate = date => {
  if (!date) {
    return false; // Returns error message if the date is invalid
  }
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, '0');
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const year = d.getFullYear();
  return `${day}-${month}-${year}`; // Returns formatted date
};

// Handle row click event to fetch contract details
const handleRowClick = row => {
  contractName.value = row.contract_name;
  insurerName.value = row.insurer_name;
  insurerId.value = row.insurer_id;
  validFrom.value = row.valid_from;
  validTo.value = row.valid_to;
  contractId.value = row.contract_id;
  listProcedureCodes(insurerId.value, contractId.value); // Fetch procedure codes
};

// Handle row click event to edit procedure code
const editProcedureCode = row => {
  selectedProcedureCode.value = row;
  if (row.price.participating_consultant_cost === null) {
    row.price.participating_consultant_cost = 0;
  }
  isEditConsultantCostModalOpen.value = true;
};

const closeEditConsultantCostModal = () => {
  selectedProcedureCode.value = null;
  isEditConsultantCostModalOpen.value = false;
};

const closeDuplicateModal = () => {
  duplicateContract.value = false;
};

// Fetch the list of insurers from the API
const listInsurers = async () => {
  const oapi = new OpenAPIJSON();
  const request = await oapi.searchContracts();

  // Filter to ensure unique insurer IDs
  const uniqueIds = new Set();
  const uniqueInsurers = request.items.filter(insurer => {
    if (!uniqueIds.has(insurer.insurer_id)) {
      uniqueIds.add(insurer.insurer_id);
      return true;
    }
    return false;
  });

  insurers.value = uniqueInsurers; // Update the insurers list
};

// Handle page change for pagination
const onPageChange = async newPage => {
  currentPage.value = newPage; // Update the current page
  await listProcedureCodes(insurerId.value, contractId.value); // Fetch procedure codes for the new page
};
// Download contract as CSV
const downloadContract = async (insurerId, contractId, contractName = null) => {
  const openApiExcel = new OpenAPIExcel();
  await openApiExcel.contractDownload(contractId, insurerId, contractName);
};

const updateProcedureCode = updatedCode => {
  const index = procedureCodes.value.findIndex(code => code.procedure_code === updatedCode.procedure_code);
  if (index !== -1) {
    procedureCodes.value[index] = updatedCode; // Update the local procedure code data
  }
};

// Fetch procedure codes for a given insurer and contract
const listProcedureCodes = async (insurerId, contractId) => {
  const start_idx = (currentPage.value - 1) * itemsPerPage.value + 1;
  const end_idx = start_idx + itemsPerPage.value - 1;

  const pagination = {
    start_idx: start_idx,
    end_idx: end_idx,
    page_size: null
  };

  const oapi = new OpenAPIJSON();
  const request = await oapi.searchProcedureCodes(
    null,
    null,
    null,
    insurerId,
    null,
    contractId,
    null,
    null,
    null,
    searchTerm.value.trim(), // Trimmed search term to avoid leading/trailing spaces
    null,
    null,
    null,
    pagination
  );
  procedureCodes.value = request.items; // Update the procedure codes
  totalItems.value = request.total_items; // Update total items for pagination
};

// Fetch contracts based on the selected insurer
const listContracts = async newValue => {
  const oapi = new OpenAPIJSON();
  const request = await oapi.searchContracts(newValue);
  contractList.value = request.items; // Update selected contract data
  mapContractValidTo(); // Map valid_to field for display
};

const mapContractValidTo = () => {
  if (contractList.value.length === 0) return;
  for (let i = 0; i < contractList.value.length; i++) {
    if (i === contractList.value.length - 1) {
      contractList.value[i].valid_to = null;
      break;
    }
    contractList.value[i].valid_to = contractList.value[i + 1].valid_from;
  }
};

// Clear search term and reload procedure codes
const cleanSearch = async () => {
  searchTerm.value = ''; // Clear search term
  listProcedureCodes(insurerId.value, contractId.value); // Fetch procedure codes again without search term
};

// Open and close modal for uploading a new contract
const openUploadModal = () => {
  isUploadModalOpen.value = true;
};

const closeUploadModal = async newContractId => {
  if (newContractId) {
    await listContracts(selectedInsurer.value.insurer_id);
    const newContract = contractList.value.find(contract => contract.contract_id === newContractId);
    handleRowClick(newContract);
  }
  isUploadModalOpen.value = false;
};

// Reset selection to initial state
const resetSelection = () => {
  selectedInsurer.value = null;
  contractList.value = null;
  contractName.value = '';
  insurerName.value = '';
  validFrom.value = '';
  validTo.value = '';
  procedureCodes.value = [];
  contractId.value = '';
  insurerId.value = '';
};

// Reset contract selection
const resetContract = () => {
  contractName.value = '';
  validFrom.value = '';
  validTo.value = '';
  procedureCodes.value = [];
  contractId.value = '';
  listContracts(selectedInsurer.value.insurer_id);
};

// Watcher to update contracts when a new insurer is selected
watch(
  () => selectedInsurer.value,
  async newValue => {
    if (newValue) {
      listContracts(newValue.insurer_id);
    }
  }
);

// Initial call to fetch insurers when the component loads
listInsurers();

// Check if called from admission claim form with a contract name
const redirectToContract = async () => {
  const oapi = new OpenAPIJSON();
  const request = await oapi.searchContracts(null, null, route.query.contract_name);
  if (request.items.length > 0) {
    redirectAppId.value = route.query.claim_id;
    claimType.value = route.query.type;
    appointmentId.value = route.query.appointment_id;
    handleRowClick(request.items[0]);
  }
};

onMounted(() => {
  // Check if redirect to contract is needed
  route.query.contract_name && redirectToContract();
});
</script>

<template>
  <div>
    <!-- Breadcrumbs -->
    <nav aria-label="Breadcrumb" class="mb-10">
      <ol class="flex flex-row">
        <li>
          <span class="capitalize cursor-pointer underline" @click="resetSelection">Home</span>
          <i class="fas fa-chevron-right mx-3"></i>
        </li>
        <li>
          <span class="capitalize cursor-pointer underline" @click="resetSelection">Manage pricing</span>
          <i v-if="selectedInsurer || contractName" class="fas fa-chevron-right mx-3"></i>
        </li>
        <li v-if="selectedInsurer">
          <span class="capitalize cursor-pointer underline" @click="resetContract">{{
            selectedInsurer.insurer_name
          }}</span>
          <i v-if="contractName" class="fas fa-chevron-right mx-3"></i>
        </li>
        <li v-if="contractName">
          <span class="capitalize">{{ contractName }}</span>
        </li>
      </ol>
    </nav>
    <div v-if="!contractName">
      <!-- Breadcrumbs and page title for managing pricing -->

      <h1 class="text-4xl font-bold text-medosync-violet-highlight mb-6">Manage pricing</h1>

      <p class="mb-4">Please select the insurer you want to change the procedure codes for.</p>

      <!-- Insurer selection and option to upload new contract -->
      <div
        class="grid grid-cols-[minmax(200px,_2fr)_minmax(300px,_1fr)] gap-x-4 lg:gap-x-16 xl:gap-x-24 2xl:gap-x-48 items-end mb-6"
      >
        <div class="max-w-[600px]">
          <label for="insurer" class="block text-md font-bold text-gray-700 mb-3">Select Insurer</label>
          <v-select
            v-model="selectedInsurer"
            label="insurer_name"
            placeholder="Select Insurer"
            :options="insurers"
            data-test-id="insurer-select"
            :clearable="false"
          />
        </div>

        <div class="flex justify-end" v-if="selectedInsurer">
          <button
            type="button"
            data-test-id="upload-new-contract-btn"
            class="flex justify-center items-center bg-medosync-blue rounded-full py-2 px-6"
            @click="openUploadModal"
          >
            <i class="fa-solid fa-upload fa-lg pr-3" />
            <span class="block">Upload new contract</span>
          </button>
        </div>
      </div>

      <!-- Display contracts related to the selected insurer -->
      <div>
        <DashboardContracts
          v-if="selectedInsurer"
          :headers="headers"
          :data="contractList"
          @row-click="handleRowClick"
        />
      </div>

      <!-- Modal for uploading new contracts -->
      <UploadNewContract
        v-if="isUploadModalOpen"
        :contractList="contractList"
        :insurer="selectedInsurer"
        @close="closeUploadModal"
      />
    </div>

    <!-- View for displaying procedure codes once a contract is selected -->
    <div v-else>
      <RouterLink
        v-if="redirectAppId && (claimType === 'consclaim' || claimType === 'compositeclaim')"
        :to="{
          name: claimType === 'consclaim' ? 'ConsultantClaimsDetails' : 'CompositeClaimsDetails',
          params: { id: redirectAppId }
        }"
        class="flex items-center mb-5"
      >
        <i class="fas fa-chevron-left mr-5"></i>
        <span class="underline">Back to App.ID {{ appointmentId }}</span>
      </RouterLink>

      <div class="grid grid-cols-[2fr,_250px]">
        <div>
          <h1 class="text-4xl font-bold text-medosync-violet-highlight mb-6">{{ contractName }}</h1>
          <p class="mb-4 text-[26px] font-instrument">Insurer: {{ insurerName }}</p>
          <div class="flex gap-5">
            <p class="mb-4 text-[16px] font-instrument">Valid from: {{ formatDate(validFrom) }}</p>
          </div>
        </div>

        <div class="flex flex-col mt-5">
          <button
            type="button"
            data-test-id="download-new-contract-btn"
            class="flex justify-center items-center bg-medosync-blue rounded-full shadow py-3 px-6 mb-6"
            @click="downloadContract(insurerId, contractId, contractName)"
          >
            <i class="fa-solid fa-download fa-lg pr-3" />
            <span class="block">Download Contract</span>
          </button>

          <button
            type="button"
            data-test-id="duplicate-contract-btn"
            class="flex justify-center items-center bg-medosync-blue rounded-full shadow py-3 px-6 mb-6"
            @click="duplicateContract = true"
          >
            <span class="block">Duplicate Contract</span>
          </button>
        </div>
      </div>

      <!-- Search bar for procedure codes -->
      <div>
        <p class="mb-4 text-[16px] font-instrument">Search for procedure code</p>
        <div class="relative flex items-center max-w-[600px] mb-4">
          <input
            data-test-id="dashboard-search-input"
            type="text"
            v-model="searchTerm"
            placeholder="Search procedure codes ..."
            class="search-input-field_"
            @keyup.enter="listProcedureCodes(insurerId, contractId)"
          />
          <i v-if="searchTerm" class="fas fa-times clear-search-icon" @click="cleanSearch()"></i>
          <div class="search-icon-wrapper search-icon">
            <i
              data-test-id="search-magnifying-glass"
              class="fas fa-search search-icon"
              @click="listProcedureCodes(insurerId, contractId)"
            ></i>
          </div>
        </div>
      </div>

      <h2 class="text-2xl text-medosync-violet-highlight mb-6">Procedure Codes and Costs</h2>

      <!-- Table for displaying procedure codes -->
      <div>
        <DashboardContracts
          data-test-id="procedure-codes-table"
          :headers="headersContracts"
          :data="procedureCodes"
          @row-click="editProcedureCode"
        />

        <div class="mt-6">
          <PaginationButtons
            v-if="procedureCodes"
            @update:page="onPageChange"
            :totalItems="totalItems"
            :itemsPerPage="itemsPerPage"
            :currentPage="currentPage"
          ></PaginationButtons>
        </div>
      </div>

      <EditConsultantCostModal
        v-if="isEditConsultantCostModalOpen"
        @close="closeEditConsultantCostModal"
        :procedure-code="selectedProcedureCode"
        :insurer-id="insurerId"
        @updateProcedureCode="updateProcedureCode"
      />
      <DuplicateContractModal
        v-if="duplicateContract"
        :contract-name="contractName"
        :contract-id="contractId"
        @close="closeDuplicateModal"
      />
    </div>
  </div>
</template>

<style scoped>
/* Styling for the search input */
.search-input-field_ {
  width: 100%;
  height: 45px;
  outline: none;
  background: #f9f9f9;
  padding-right: 40px;
  padding-left: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
}

/* Styling for the search button */
.search-icon-wrapper {
  display: flex;
  align-items: center;
  background: #00a7e1;
  padding: 0 13px;
  border-radius: 0 4px 4px 0;
  height: 45px;
}

/* Styling for the search icon */
.search-icon {
  color: #fff;
  cursor: pointer;
}

/* Styling for the clear search button */
.clear-search-icon {
  position: absolute;
  right: 55px;
  top: 50%;
  transform: translateY(-50%);
  color: #050505;
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.underline:hover {
  color: #0c276c;
}
</style>
