<script setup lang="ts">
import { ref, computed } from 'vue';
import Modal from '@/components/modal/Modal.vue';
import { ToastComponent } from 'ui';

defineProps({
  claimId: {
    type: Object,
    default: null
  },
  item: {
    type: Object,
    default: () => ({})
  }
});

const emit = defineEmits(['close', 'update-cost']);

const toast = ref(null);
const newConsultantCost = ref<number | null>(null);

const closeModal = () => {
  newConsultantCost.value = null;
  emit('close');
};

// Computed property to check if the cost is valid (non-negative and not null)
const isCostValid = computed(() => {
  return newConsultantCost.value !== null && newConsultantCost.value >= 0;
});

// Computed property to check if the cost is negative
const isCostNegative = computed(() => {
  return newConsultantCost.value !== null && newConsultantCost.value < 0;
});

const set_contract_procedure_code = () => {
  if (isCostValid.value) {
    emit('update-cost', newConsultantCost.value);
    closeModal();
  }
};
</script>

<template>
  <Modal @close-modal="closeModal">
    <template #header>
      <h3 class="text-2xl text-medosync-violet-highlight font-semibold">
        Edit Consultant Cost for Claim {{ claimId }}
      </h3>
    </template>
    <template #body>
      {{}}
      <div class="grid grid-cols-[1fr,_3fr] mt-6 gap-10 mb-10">
        <div>
          <h5 class="font-bold mb-1">Procedure Code:</h5>
          <p>{{ item.procedure_code }}</p>
        </div>
        <div>
          <h5 class="font-bold mb-1">Procedure Description:</h5>
          <p>{{ item.description }}</p>
        </div>
        <div>
          <h5 class="font-bold mb-1">Current Consultant Cost</h5>
          <p>{{ item.amount }} €</p>
        </div>
        <div>
          <h5 :class="{ 'text-medosync-red': isCostNegative, 'text-black': !isCostNegative }" class="font-bold mb-1">
            New Consultant Cost in € *
          </h5>
          <input
            type="number"
            data-test-id="consultant-cost-input"
            v-model="newConsultantCost"
            min="0"
            class="rounded mb-2"
          />
          <p v-if="isCostNegative" class="text- text-medosync-red mb-2">
            Cost cannot be negative. Please update the cost to enable the save option.
          </p>
          <p class="text-xs">* Changes on the Consultant Cost will affect only the Claim {{ claimId }}</p>
        </div>
      </div>

      <div class="flex justify-between items-center">
        <button
          type="button"
          data-test-id="cancel-consultant-cost-edit-btn"
          class="flex justify-center items-center bg-[#CBD5E0] shadow rounded-full py-3 px-8"
          @click="closeModal"
        >
          <span class="block">Cancel</span>
        </button>

        <button
          type="button"
          :disabled="!isCostValid"
          data-test-id="save-cost-for-app-id-btn"
          class="flex justify-center items-center bg-medosync-blue shadow rounded-full py-3 px-8 disabled:opacity-50 disabled:cursor-not-allowed"
          @click="set_contract_procedure_code"
        >
          <span class="block">Apply</span>
        </button>
      </div>
    </template>
  </Modal>
  <ToastComponent ref="toast"></ToastComponent>
</template>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

/* Disable button styling */
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
