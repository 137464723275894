<script setup lang="ts">
import { defineComponent, computed } from 'vue';
import { useField } from 'vee-validate';
import InputErrorMessage from './InputErrorMessage.vue';

defineComponent({ InputErrorMessage });

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  editMode: {
    type: Boolean,
    default: true
  },
  customErrorMessage: {
    type: String,
    default: ''
  },
  enableValidation: {
    type: Boolean,
    default: true
  }
});

const { value, errorMessage } = useField(() => props.name);

const showError = computed<boolean>(() => {
  return !!(props.enableValidation && errorMessage.value);
});

const formatDate = (date: string) => {
  if (!date) return 'N/A';
  const [year, month, day] = date.split('-');
  return `${day}-${month}-${year}`;
};
</script>

<template>
  <div :id="id + '-container'" :name="name">
    <div class="flex">
      <label :for="id" class="text-sm sm:text-base font-bold" :class="{ 'text-medosync-red': showError }">
        {{ label }}
      </label>
    </div>
    <div v-if="editMode" class="mt-1">
      <client-only>
        <DatePicker
          v-if="(showError as boolean)"
          :id="id"
          v-model:value="value"
          :placeholder="placeholder"
          value-type="YYYY-MM-DD"
          format="DD.MM.YYYY"
          prefix-class="xmx"
          :editable="false"
          style="width: 300px; height: 40px"
          :append-to-body="false"
        />
        <DatePicker
          v-else
          :id="id"
          v-model:value="value"
          :placeholder="placeholder"
          value-type="YYYY-MM-DD"
          format="DD.MM.YYYY"
          :editable="false"
          style="width: 300px; height: 40px"
          :append-to-body="false"
        />
      </client-only>
    </div>
    <div v-else class="mt-1">
      <p :id="id + '-readonly'" class="text-lg text-gray-500 ml-4 mr-6">
        {{ value === '' || !value ? 'N/A' : formatDate(String(value)) }}
      </p>
    </div>
    <InputErrorMessage v-if="(showError as boolean)">
      {{ customErrorMessage !== '' ? customErrorMessage : `Please enter the date ${label.toLowerCase()}` }}
    </InputErrorMessage>
  </div>
</template>

<style lang="scss">
$namespace: 'xmx';
$input-border-color: #ac0606 !default;
$input-color: #ac0606 !default;
$input-hover-border-color: #ac0606 !default;

@import 'vue-datepicker-next/scss/index.scss';

.mx-input {
  border: 1px solid #6b7280;
  font-size: 16px;
}

.xmx-input {
  font-size: 16px;
}

.xmx-datepicker-main {
  z-index: 11000;
}

.mx-datepicker-main {
  z-index: 11000;
}
</style>
