<template>
  <div>
    <SideNav v-if="showNav" />
    <div :class="{ 'content-section': showNav }">
      <router-view v-if="!loading" />
    </div>
  </div>
</template>

<script>
import { userStore } from '@/store/user';
import { hospitalConfigStore } from '@/store/config';
import SideNav from '@/components/navigation/SideNav.vue';
import { OpenAPIJSON } from 'api/openapi';

// global scss
import '@/assets/scss/variables.scss';
import '@/assets/scss/global.scss';
import '@/assets/scss/claims.scss';
import '@/assets/scss/custom.scss';
import '@/assets/scss/transitions.scss';
import '@/assets/scss/additional-attachments.scss';

// redesign 2022 css
import './assets/scss/redesign-components.scss';

export default {
  name: 'AppMain',
  components: {
    SideNav
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    showNav() {
      const publicRoutes = ['/', '/login', '/reset-password', '/not-authorized'];
      return !publicRoutes.includes(this.$route.path) && localStorage.getItem('_token');
    }
  },
  watch: {
    $route: {
      immediate: true,
      async handler(to) {
        if (to.path !== '/' && to.path !== '/login' && localStorage.getItem('_token')) {
          await this.initializeApp();
        }
      }
    }
  },
  methods: {
    async initializeApp() {
      if (!localStorage.getItem('_token')) {
        this.loading = false;
        return;
      }

      try {
        const oapi = new OpenAPIJSON();
        await this.loadConfigAndUser(oapi);
      } catch (error) {
        console.error(`LOGOUT(me): Can not get initial user data ${userStore?.getters?.user?.email}. `, error);
        this.handleLogout();
      } finally {
        this.loading = false;
      }
    },

    async loadConfigAndUser(oapi) {
      const [configRoute, me] = await Promise.all([oapi.getConfig(), oapi.me()]);

      localStorage.setItem('configRoute', JSON.stringify(configRoute));
      localStorage.setItem('me', JSON.stringify(me));
    },

    handleLogout() {
      sessionStorage.clear();
      localStorage.removeItem('_token');
      localStorage.removeItem('tokenExpiration');
      localStorage.removeItem('configsLoaded');
      window.location.href = '/';
    }
  },

  async created() {
    if (localStorage.getItem('_token')) {
      await this.initializeApp();
    } else {
      this.loading = false;
    }
  }
};
</script>

<style lang="scss">
/* font roboto */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.content-section {
  float: left;
  margin-left: 65px;
  width: calc(100% - 65px);
}
</style>
