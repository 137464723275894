<script setup>
import { ref } from 'vue';

import { OpenAPIExcel } from 'api';

const props = defineProps({
  currentValidContract: { type: Object, required: true },
  insurer: { type: Object, required: true }
});

const collapsed = ref(false);

const downloadCurrentContract = async () => {
  const openApiExcel = new OpenAPIExcel();
  await openApiExcel.contractDownload(
    props.currentValidContract?.contract_id,
    props.insurer?.insurer_id,
    props.currentValidContract?.contract_name
  );
};
</script>

<template>
  <div id="downloadTemplate" class="bg-[#4DC0EB] p-4 relative">
    <i
      v-if="!collapsed"
      class="fas fa-chevron-up cursor-pointer absolute top-[20px] right-[20px]"
      @click="collapsed = true"
    />
    <i v-else class="fas fa-chevron-down cursor-pointer absolute top-[20px] right-[20px]" @click="collapsed = false" />

    <h3 v-if="!collapsed" class="text-xl font-medium">Download currently valid Insurance Contract as template</h3>
    <p v-if="!collapsed" class="my-5">
      You can download the currently valid Insurance Contract as a template to add a new Insurance Contract.
    </p>
    <h5 class="underline cursor-pointer" @click="downloadCurrentContract">
      Download Insurance Contract as template ({{ props.currentValidContract?.contract_name }}.xlsx)
    </h5>
  </div>
</template>
