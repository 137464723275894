<template>
  <div>
    <SecondMenuFinance v-model="collapsed" />

    <div class="main-content" :class="[collapsed ? 'full-width' : 'part-width']">
      <div class="title medo-secondary">Consultant Files</div>

      <div class="col-12">
        <div class="col-6 flex px-2">
          <select
            v-model="searchMonth"
            class="custom-select mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option value="" disabled selected hidden>Select Month</option>
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
        </div>

        <div class="col-6 flex flex-col px-2 mt-3">
          <button
            type="button"
            class="inline-flex items-center px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            UPLOAD FILE
          </button>
        </div>
      </div>

      <div class="col-12">
        <div class="flex flex-col mt-5">
          <div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table v-if="files" class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Category
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Download
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Scan
                      </th>
                    </tr>
                  </thead>

                  <!-- <tr class="bg-gray-50"> -->

                  <tbody>
                    <tr v-for="file in files" :key="file.fileId" class="bg-white">
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ file.fileName }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ file.fileCategory }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <CustomDatePicker v-model="file.creationDate" />
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <button
                          type="button"
                          class="inline-flex items-center px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          DOWNLOAD
                        </button>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <button
                          type="button"
                          class="inline-flex items-center px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          SCAN
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import api from 'api/api';
import axios from 'axios';

import SecondMenuFinance from '@/components/navigation/SecondMenuFinance.vue';
import CustomDatePicker from '@/components/claim-forms/components/Datepicker.vue';
import { ToastComponent } from 'ui';

export default {
  name: 'ConsultantFiles',
  components: {
    SecondMenuFinance,
    CustomDatePicker,
    ToastComponent
  },

  data() {
    return {
      searchMonth: '',
      collapsed: false,
      personId: null,
      files: null
    };
  },
  methods: {
    async init() {
      let response = await axios.get(import.meta.env.VITE_BASE_SERVER + 'me', {
        headers: {
          Authorization: localStorage.getItem('_token')
        }
      });

      this.personId = parseInt(response.data.person_id);

      await api
        .listConsultantFiles(this.personId, '')
        .then(res => {
          this.files = res.data.data.listConsultantFiles.files;
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.main-content {
  float: left;
  height: 100vh;
  padding: 35px;
  overflow: auto;
}
.full-width {
  width: calc(100% - 110px);
}
.part-width {
  width: calc(100% - 350px);
}
.title {
  margin-bottom: 30px;
  font-size: 36px;
  font-weight: 600;
}

.notes {
  float: left;
  width: 250px;
}
</style>
