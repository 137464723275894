import { OpenAPIJSON } from 'api/openapi';
import _ from 'lodash';
import { mapState, mapMutations } from 'vuex';

/**
 * Mixin for all claim forms that implements common APIs.
 */
export default {
  emits: ['set-previous-form-state'],
  watch: {
    showRevertModal(state) {
      if (state === true) {
        this.updateSaveModal();
        this.setRevertModal(false);
      }
    }
  },
  computed: {
    ...mapState(['showRevertModal'])
  },
  methods: {
    ...mapMutations(['setRevertModal']),

    updateSaveModal() {
      if (_.isEqual(this.claimData, this.claimDataPreviousState)) {
        this.setTextBoxDisabled(true);
      } else {
        this.revertModal = true;
        this.titleText = 'Revert all changes from Claim Form?';
        this.titleText2 =
          'Are you sure you want to leave this page and revert all changes you made in this claim form?';
        this.titleText3 = 'Please choose the button “Cancel” to review the claim form again.';
        this.saveReplace = 'Revert changes';
      }
    },
    enableTextBox() {
      this.revertModal = false;
      this.showManageModalClaims = false;
      this.setTextBoxDisabled(true);
      this.setPreviousFormState();
    },
    // This function cleans certain data structures that are required for successful form submission but
    // would otherwise break the FE components.
    prepClaimForSubmission(orig_claim_data) {
      // The claim data is cloned because we don't want to mess with the content represented in the UI
      // for this save-specific operation.
      let claim_data = _.clone(orig_claim_data);
      if (claim_data.patient)
        claim_data.patient.full_name = claim_data.patient.first_name + ' ' + claim_data.patient.last_name;
      return claim_data;
    },

    async updateClaim() {
      let claim_data = this.prepClaimForSubmission(this.claimData);
      try {
        const openApi = new OpenAPIJSON();
        await openApi.claimform_update(claim_data);
        this.$refs.toast.showToast('Changes were saved successfully .', 'fas fa-check', 'success');
      } catch (error) {
        this.$refs.toast.showToast(
          'An error occurred. Please contact the support.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
        this.claimData = this.claimDataPreviousState;
        this.$emit('set-previous-form-state');
      }
      this.showManageModalClaims = false;
      this.setTextBoxDisabled(true);
    },
    async updateClaimNoModal() {
      let claim_data = this.prepClaimForSubmission(this.claimData);
      try {
        const openApi = new OpenAPIJSON();
        await openApi.claimform_update(claim_data);
        this.$refs.toast.showToast('Changes were saved successfully .', 'fas fa-check', 'success');
        this.$emit('set-previous-form-state', this.claimData);
        this.setTextBoxDisabled(false);
      } catch (error) {
        this.$refs.toast.showToast(
          error + 'An error occurred. Please contact the support.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
        this.claimData = this.claimDataPreviousState;
        this.$emit('set-previous-form-state');
      }
    },
    setPreviousFormState() {
      this.$refs.toast.showToast('Changes were reverted .', 'fas fa-check', 'other');
      this.claimData = this.claimDataPreviousState;
      this.$emit('set-previous-form-state');
    }
  }
};
