<template>
  <div v-if="!loading">
    <div class="claim-title medo-secondary">
      <router-link to="/settings/groups">
        <i class="fas fa-chevron-left medo-primary mr-5 custom-back"></i>
      </router-link>
      Edit Group: {{ group.name }}
    </div>

    <div style="clear: both"></div>

    <div class="col-12 mt-5 overflow-auto">
      <div class="col-6 pr-5">
        <!-- <div class="flex justify-between">
                    <label for="email" class="block text-sm font-medium text-gray-700">Group Name:</label>
                </div>
                <div class="mt-1">
                    <input v-model='group.name' type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
                </div> -->
        <h4 class="medo-secondary font-medium">Group Name:</h4>
        <h5>{{ group.name }}</h5>
      </div>

      <div class="col-6 pr-5">
        <!--     <div class="flex justify-between">
                    <label for="email" class="block text-sm font-medium text-gray-700">Group Description:</label>
                </div>
                <div class="mt-1">
                    <input v-model='group.description' type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
                </div> -->
        <h4 class="medo-secondary font-medium">Group Description:</h4>
        <h5>{{ group.description }}</h5>
      </div>
    </div>

    <div style="clear: both"></div>

    <div class="grid grid-cols-12 mt-5 relative">
      <div class="col-span-12 mb-5">
        <h4 class="text-center medo-secondary text-xl font-medium">ADD USERS TO THE SELECTED GROUP</h4>
      </div>
      <div class="col-span-5">
        <h4 :class="{ 'text-indigo-500': selectedUsers.length }" class="text-center text-lg font-medium">
          Non Members
        </h4>

        <div class="mt-2 px-1">
          <input
            v-model="searchAllUsers"
            type="text"
            class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="search all users..."
            aria-describedby="email-optional"
          />
        </div>

        <div
          :class="[selectedUsers.length > 0 ? 'border-indigo-300 border-2' : 'border-gray-300 border']"
          class="user-menu overflow-auto rounded-lg mt-2 p-4"
        >
          <div
            v-for="user in filteredAllUsers"
            :key="user.loginAccountId"
            @click="selectUser(user)"
            :class="[
              selectedUsers.some(item => item.loginAccountId == user.loginAccountId)
                ? 'bg-indigo-400 text-white'
                : 'hover:bg-indigo-100'
            ]"
            class="border border-gray-300 rounded-lg my-2 text-center cursor-pointer"
          >
            {{ user.userName }}
          </div>
        </div>
      </div>

      <div class="col-span-2 flex flex-col justify-center items-center">
        <i
          @click="addUsersToAclGroup()"
          :class="[selectedUsers.length > 0 ? 'text-indigo-600 cursor-pointer' : 'text-gray-600 cursor-not-allowed']"
          class="fas fa-arrow-alt-circle-right arrow-icon mt-5"
        ></i>
      </div>

      <div class="col-span-5">
        <h4 class="text-center text-lg font-medium">Group Members</h4>

        <div class="mt-2 px-1">
          <input
            v-model="searchGroupUsers"
            type="text"
            class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="search group users..."
            aria-describedby="email-optional"
          />
        </div>

        <div class="user-menu border-gray-300 border overflow-auto rounded-lg mt-2 p-4">
          <div
            v-for="user in filteredGroupUsers"
            :key="user.loginAccountId"
            class="border border-gray-300 rounded-lg my-2 text-center"
          >
            {{ user.userName }}
          </div>
        </div>
      </div>

      <div class="col-span-12 mb-5">
        <h4 class="text-left text-lg font-medium mt-4">Selected: {{ selectedUsers.length }}</h4>
      </div>
    </div>

    <div style="clear: both"></div>

    <TransferMenu
      :group1="allAcls"
      :group2="groupAcls"
      displayProperty="name"
      objectIdentifier="aclId"
      title="ADD OR REMOVE PERMISSIONS"
      group1Title="AVAILABLE PERMISSIONS"
      group2Title="GROUP PERMISSIONS"
      hoverProperty="description"
      @add-to-group="addAclsToAclGroup"
      @remove-from-group="removeAclsFromAclGroup"
    />

    <div style="clear: both"></div>

    <div class="col-12 mt-8 text-right">
      <!-- EDIT ACL GROUP ON BACKEND NEEDED -->
      <!--     
          <button type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Edit Group
          </button>
         -->
    </div>
  </div>
  <ToastComponent ref="toast"></ToastComponent>
</template>

<script>
import api from 'api/api';
import TransferMenu from '@/components/groups/TransferMenu.vue';
import { ToastComponent } from 'ui';

export default {
  name: 'Edit-group',
  components: {
    TransferMenu,
    ToastComponent
  },
  data() {
    return {
      loading: false,
      group: {
        name: '',
        description: ''
      },

      searchAllUsers: '',
      searchGroupUsers: '',

      allUsers: [],
      groupUsers: [],

      selectedUsers: [],

      allAcls: [],
      groupAcls: []
    };
  },
  computed: {
    filteredAllUsers() {
      if (this.searchAllUsers.toLowerCase() == '') {
        return this.allUsers;
      } else {
        return this.allUsers.filter(item => {
          if (item.userName.toLowerCase().includes(this.searchAllUsers.toLowerCase())) {
            return item;
          }
        });
      }
    },

    filteredGroupUsers() {
      if (this.searchGroupUsers.toLowerCase() == '') {
        return this.groupUsers;
      } else {
        return this.groupUsers.filter(item => {
          if (item.userName.toLowerCase().includes(this.searchGroupUsers.toLowerCase())) {
            return item;
          }
        });
      }
    }
  },
  methods: {
    async init() {
      this.loading = true;

      await api
        .listAclGroups()
        .then(res => {
          this.group = res.data.data.listAclGroup.groups.filter(group => group.aclGroupId == this.$route.params.id)[0];
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });

      await api
        .listLogins()
        .then(res => {
          this.populateGroups(res.data.data.listLogins.logins);
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });

      await api
        .listGroupAcls(this.group.name)
        .then(res => {
          this.groupAcls = res.data.data.listAcls;
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });

      await api
        .listAcls()
        .then(res => {
          this.allAcls = res.data.data.listAcls.filter(item =>
            this.groupAcls.every(item2 => item2.aclId != item.aclId)
          );
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });

      this.loading = false;
    },

    populateGroups(users) {
      this.allUsers = users.filter(user => user.aclGroupId != this.$route.params.id);
      this.groupUsers = users.filter(user => user.aclGroupId == this.$route.params.id);
    },

    selectUser(user) {
      this.selectedUsers.some(item => item.loginAccountId == user.loginAccountId)
        ? (this.selectedUsers = this.selectedUsers.filter(item => item.loginAccountId != user.loginAccountId))
        : this.selectedUsers.push(user);
    },

    async addUsersToAclGroup() {
      for (const user of this.selectedUsers) {
        await api
          .setUserAclGroup(user.hospitalId, user.loginAccountId, this.group.aclGroupId)
          .then(() => {})
          .catch(error => {
            this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
          });
      }

      this.init();
    },

    async addAclsToAclGroup(selectedArray) {
      for (const acl of selectedArray) {
        //canRead and canWrite set to false !!!!!!
        await api
          .addAclToGroup(this.group.aclGroupId, acl.aclId, true, true)
          .then(() => {})
          .catch(error => {
            this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
          });
      }

      this.init();
    },

    async removeAclsFromAclGroup(selectedArray) {
      const aclIds = selectedArray.map(acl => acl.aclId);

      await api
        .removeAclsFromGroup(this.group.aclGroupId, aclIds)
        .then(() => {})
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });

      this.init();
    }
  },
  created() {},
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.optional-nav {
  position: fixed;
  top: 0;
  left: 65px;
  height: 100vh;
  overflow: auto;
  background: #ededed;
}
.user-menu {
  height: 250px;
}

.arrow-icon {
  font-size: 2rem;
}

.custom-back {
  font-size: 28px;
  cursor: pointer;
  transition: all 0.25s;

  &:hover {
    opacity: 0.65;
  }
}

.claim-title {
  margin-bottom: 30px;
  font-size: 26px;
  font-weight: 600;
}

.custom-input {
  height: 40px;
  padding-left: 8px;
  line-height: 40px;
  border: 1px solid #dedede;
}

.custom-select {
  height: 40px;
  padding-left: 8px;
  line-height: 40px;
  border: 1px solid #dedede;
}
</style>
