<template>
  <div class="text-left text-sm mb-1">
    <label for="value" class="font-medium text-gray-700 mr-2">Insurance Policy Name:*</label>
  </div>
  <div class="col-12" v-if="textBoxDisabled">
    <span class="text-sm text-gray-500">{{ localData.ro.name ? localData.ro.name : 'N/A' }}</span>
  </div>
  <div v-else class="col-12">
    <Typeahead
      id="insurer-policy"
      v-model="selectedInsurerPolicy"
      :items="policies"
      placeholder="Search for insurer policies"
      :min-input-length="1"
      :item-projection="show_suggestion"
      :item-value="show_name"
      @select-item="select_item"
      @update:modelValue="check_if_matching"
      :fullW="true"
      :data-test-id="data_test_id"
    />
  </div>

  <ToastComponent ref="toast"></ToastComponent>
</template>

<script>
import Typeahead from '@/components/claim-forms/components/Typeahead.vue';
import { mapState } from 'vuex';
import { ToastComponent } from 'ui';
import { OpenAPIJSON } from 'api';

/**
 * Typeahead for lookup of insurance companies that are registered to the hospital.
 *
 * Emits a data structure result though the event update:modelValue:
 *     {
 *       "insurer": {
 *         "id": 1,
 *         "ro": {
 *           "insurer_id": 1,
 *           "insurer_name": "The Good Insurance Company",
 *           "contact": {
 *             "postal_address": [
 *               "6531 Insurance Street",
 *               "Dundrum",
 *               "Dublin",
 *               "Ireland"
 *             ],
 *             "telephone_landline": null,
 *             "telephone_mobile": null,
 *             "telephone_fax": null,
 *             "email": null
 *           }
 *         },
 *         "type": "id"
 *       },
 *       "policy_plan": {
 *         "id": 3,
 *         "ro": {
 *           "id": 3,
 *           "name": "Great Care 001"
 *         },
 *         "type": "id"
 *       },
 *       "insurer_id": 1,
 *       "insurer_name": "The Good Insurance Company",
 *       "policy_plan_id": 3,
 *       "policy_plan_name": "Great Care 001"
 *     }
 */

export default {
  name: 'InsurancePolicy',
  components: {
    Typeahead,
    ToastComponent
  },
  props: {
    modelValue: {},
    id: { require: true, type: String },
    insurer_id: { require: true, type: Number },
    on_date: { require: false, default: null },
    policy_name: { require: false },
    data_test_id: { require: false, default: '' }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      inputId: this.id || `insurance_policy_${(Math.random() * 1000).toFixed()}`,
      api: new OpenAPIJSON(),
      selectedInsurerPolicy: '',
      policies: []
    };
  },
  computed: {
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },
    localData: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  },
  watch: {
    insurer_id: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectedInsurerPolicy = ''; // Clear the selected policy
        this.policies = []; // Clear the list of policies
      }
    },
    policy_name: function (new_val) {
      this.selectedInsurerPolicy = new_val ? new_val : '';
    },
    selectedInsurerPolicy: async function (new_filter) {
      let search_term = new_filter.trim();
      let results = { items: [] };
      if (search_term.length > 0) {
        results = await this.api.getInsurancePolicyPlans(this.insurer_id, null, this.on_date, new_filter, null);
      }
      if (results.items.length > 0) {
        this.policies = results.items;
      } else {
        this.policies = [];
      }
    }
  },
  methods: {
    show_suggestion(item) {
      if (item === '') {
        return '';
      }
      return item.policy_plan.ro.name;
    },
    show_name(item) {
      this.$emit('update:modelValue', item.policy_plan);
      this.selectedInsurerPolicy = item.policy_plan.ro.name;
      return item.policy_plan.ro.name;
    },
    select_item(item) {
      this.$emit('update:modelValue', item.policy_plan);
      this.selectedInsurerPolicy = item.policy_plan.ro.name;
    },
    check_if_matching(item) {
      if (item.length === 0) {
        this.$emit('update:modelValue', {});
      }
    },
    mounted() {
      console.log('mounted ', this.modelValue);
      if (this.modelValue && this.modelValue.ro) {
        this.selectedInsurerPolicy = this.modelValue.ro.name;
      }
    }
  }
};
</script>
