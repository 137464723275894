<template>
  <div class="h-full custom-side-nav" v-if="isVisible">
    <div class="logo">
      <img src="@/assets/img/medosync-logo.png" />
    </div>

    <div class="links">
      <router-link
        class="link-item"
        :class="{ 'router-link-active': isClaimsPage && link.name == 'claims' }"
        v-for="(link, index) in filteredLinks"
        :key="index"
        :to="link.link"
        :title="link.title"
        @click.prevent="refreshPage(link)"
        :data-test-id="'nav-icon-' + link.name"
      >
        <i :class="link.icon"></i>
      </router-link>
    </div>

    <router-link v-if="settingsLink" class="settings-btn" to="/settings" title="Settings">
      <i class="fas fa-gear"></i>
    </router-link>

    <div class="signout-btn" @click="signout" title="Sign Out">
      <i class="fas fa-arrow-right-from-bracket"></i>
    </div>
    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import { ToastComponent } from 'ui';
import { hospitalConfigStore } from '@/store/config';
import { userStore } from '@/store/user';
import { COMPOSITE_HOSTS } from '@/util/helper';

export default {
  name: 'SideNav',
  components: { ToastComponent },
  computed: {
    isVisible() {
      const path = this.$route.path;

      return !['/', '/login', '/reset-password'].includes(path);
    },
    isClaimsPage() {
      return this.$route.path.split('/')[1] === 'claims';
    },
    filteredLinks() {
      const hasAccess = hospitalConfigStore.getters.has_product_access;
      const hasNavigator = hospitalConfigStore.getters.has_product_navigator;

      if (hasAccess && hasNavigator) {
        return this.links;
      }

      if (hasNavigator) {
        return this.links.filter(link => link.name !== 'admission');
      }

      if (hasAccess) {
        return this.links.filter(link => link.name === 'admission');
      }

      return [];
    },
    settingsLink() {
      const hasNavigator = hospitalConfigStore.getters.has_product_navigator;

      if (hasNavigator) {
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      links: this.getLinks(),
      notificationCount: 0
    };
  },
  methods: {
    getLinks() {
      const hostname = window.location.hostname;

      const links = [
        { name: 'admission', icon: 'fa-solid fa-hospital-user', link: '/access/admission', title: 'Admission' },
        { name: 'claims', icon: 'fas fa-table', link: '/navigator/claims', title: 'Claims' },
        { name: 'notifications', icon: 'far fa-envelope', link: '/navigator/notifications/list', title: 'Messages' },
        { name: 'finance', icon: 'fas fa-circle-dollar-to-slot', link: '/navigator/finance', title: 'Credit Control' },
        { name: 'reporting', icon: 'fas fa-chart-line', link: '/navigator/finance/reporting', title: 'Reports' }
      ];

      if (COMPOSITE_HOSTS.includes(hostname)) {
        const claimsIndex = links.findIndex(link => link.name === 'claims');
        if (claimsIndex !== -1) {
          links[claimsIndex].link = '/navigator/compositeclaims';
        }
      }

      return links;
    },
    signout() {
      console.info(`LOGOUT(signout): User ${userStore?.getters?.user?.email} signing out.`);
      sessionStorage.clear();
      localStorage.removeItem('_token');
      localStorage.removeItem('tokenExpiration');
      this.$refs.toast.showToast('Logged Out Successfully', 'fas fa-check', 'success');
      window.location.href = '/';
    },
    refreshPage(link) {
      const currentPath = this.$route.path;
      const isNavigatingToNotificationList =
        currentPath.includes('/navigator/notifications/list') && link.name === 'notifications';

      if (isNavigatingToNotificationList) {
        this.$router.push('/navigator/notifications/list').then(() => {
          this.$router.go();
        });
      } else if (currentPath === link.link) {
        this.$router.go();
      } else {
        this.$router.push(link.link);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-side-nav {
  position: fixed;
  width: 65px;
  height: 100vh;
  background: #0c276c;
  color: #fff;
}

.logo {
  padding: 10px;
  filter: brightness(0) invert(1);
}

.links {
  margin-top: 38px;

  .link-item {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 24px;
    padding: 10px 0;
    cursor: pointer;
    transition: all 0.25s;

    &:hover {
      opacity: 0.7;
    }
  }
}

.settings-btn {
  display: block;
  position: absolute;
  bottom: 60px;
  width: 100%;
  text-align: center;
  font-size: 24px;
  padding: 10px 0;
  cursor: pointer;
  transition: all 0.25s;
}

.signout-btn {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: 24px;
  padding: 10px 0;
  cursor: pointer;
  transition: all 0.25s;
}

.router-link-active {
  background-color: #00a7e1;
  color: #000101;
}
</style>
