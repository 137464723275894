<script setup lang="ts">
import { ref } from 'vue';
import { OpenAPIJSON } from 'api/openapi';
import Modal from '@/components/modal/Modal.vue';
import { ToastComponent } from 'ui';
import vSelect from 'vue-select';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';

// Define props with types and defaults
const props = defineProps({
  contractName: {
    type: String,
    default: ''
  },
  contractId: {
    type: Number,
    default: null
  }
});

// Emit event for closing modal
const emit = defineEmits(['close']);

// Reference for the toast notification component
const toast = ref<InstanceType<typeof ToastComponent> | null>(null);

// Reactive state variables with appropriate types
const cloneContractId = ref<number | null>(props.contractId);
const cloneName = ref<string | null>(null);
const contractNameClone = ref<string>(props.contractName);
const cloneInsurerId = ref<{ insurer_id: number } | null>(null);
const cloneStartDate = ref<string>('');
const insurers = ref([]);
const isLoading = ref(false);

// Validate user input before making the API call
const validateData = (): boolean => {
  if (!cloneContractId.value) {
    showToast('Please select a contract to clone.', 'error');
    return false;
  }
  if (!cloneName.value) {
    showToast('Please enter a new contract name.', 'error');
    return false;
  }
  if (!cloneInsurerId.value) {
    showToast('Please select an insurer.', 'error');
    return false;
  }
  if (!cloneStartDate.value) {
    showToast('Please select a start date.', 'error');
    return false;
  }
  return true;
};

// Fetch the list of insurers, ensuring unique insurer IDs
const listInsurers = async (): Promise<void> => {
  const oapi = new OpenAPIJSON();
  const request = await oapi.searchContracts();

  // Use Set to filter out duplicate insurer IDs
  const uniqueIds = new Set<number>();
  insurers.value = request.items.filter((insurer: { insurer_id: number }) => {
    if (!uniqueIds.has(insurer.insurer_id)) {
      uniqueIds.add(insurer.insurer_id);
      return true;
    }
    return false;
  });
};

// Duplicate contract by sending the relevant data to the API
const duplicateContract = async (): Promise<void> => {
  if (validateData()) {
    try {
      const oapi = new OpenAPIJSON();
      const requestData = {
        insurer: {
          type: 'id',
          id: cloneInsurerId.value!.insurer_id // Insurer ID is guaranteed here due to validation
        },
        contract_name: cloneName.value,
        valid_from: cloneStartDate.value,
        from_contract: {
          type: 'id',
          id: cloneContractId.value
        }
      };
      isLoading.value = true;
      await oapi.contractCopy(requestData);
      isLoading.value = false;

      closeModal();
    } catch (error) {
      console.error('Contract clone fetch error', error);
      showToast('Error duplicating contract.', 'error');
    }
  }
};

// Close modal and reset state
const closeModal = (): void => {
  cloneName.value = '';
  cloneInsurerId.value = null;
  cloneStartDate.value = null;
  emit('close');
};

// Show toast notification
const showToast = (message: string, type: 'error' | 'success'): void => {
  toast.value?.showToast(message, 'fa-solid fa-triangle-exclamation', type);
};

// Fetch insurers list on component setup
listInsurers();
</script>

<template>
  <Modal @close-modal="closeModal">
    <template #header>
      <h2 class="text-2xl font-semibold text-medosync-violet-highlight mb-8">Duplicate and Name New Contract</h2>
    </template>

    <template #body>
      <div>
        <!-- Original Contract Section -->
        <h2 class="text-xl font-semibold text-medosync-violet-highlight mb-2">Original Contract</h2>
        <label for="contract-name" class="block font-bold mb-2">Contract to Duplicate</label>
        <input
          id="contract-name"
          type="text"
          placeholder="Enter contract name"
          v-model="contractNameClone"
          class="w-full rounded mb-2"
        />

        <label for="insurer-select" class="block font-bold mt-4 mb-2">Select Insurer</label>
        <v-select
          v-model="cloneInsurerId"
          label="insurer_name"
          placeholder="Select Insurer"
          :options="insurers"
          data-test-id="insurer-select"
          :clearable="false"
        />

        <!-- New Contract Section -->
        <h2 class="text-xl font-semibold text-medosync-violet-highlight mt-8 mb-3">New Contract</h2>
        <p class="mb-4">
          If you proceed, this will create a duplicate contract from the selected one. After a new contract is created
          you can edit individual procedure codes by clicking "MANAGE PRICES" .
        </p>
        <label for="new-contract-name" class="block font-bold mb-2">New Contract Name</label>
        <input
          id="new-contract-name"
          type="text"
          placeholder="Enter new contract name"
          v-model="cloneName"
          class="w-full rounded mb-8"
        />

        <!-- Start Date -->
        <label for="start-date" class="block font-bold mb-2">Start Date</label>
        <date-picker
          id="start-date"
          v-model:value="cloneStartDate"
          class="mb-8"
          value-type="YYYY-MM-DD"
          format="DD-MM-YYYY"
        />
      </div>

      <!-- Buttons Section -->
      <div class="flex justify-between items-center">
        <button
          type="button"
          class="bg-[#CBD5E0] shadow rounded-full py-3 px-8 flex justify-center items-center"
          @click="closeModal"
        >
          Cancel
        </button>

        <button
          type="button"
          class="bg-medosync-blue shadow rounded-full py-3 px-8 flex justify-center items-center"
          @click="duplicateContract"
        >
          Duplicate Contract
        </button>
      </div>
    </template>
  </Modal>
  <SpinnerLoader v-if="isLoading" />
  <ToastComponent ref="toast" />
</template>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
