<template>
  <div class="my-3">
    <div class="flex flex-row justify-between header-title-height">
      <h3 class="s-view-darker-title">App. ID {{ appointment.hospital_appointment_id }}</h3>
      <FormStatus v-if="appointment.preadmit_state" class="h-10" :status="appointment.preadmit_state" />
      <div v-if="!isEditMode">
        <!-- This component needs fixing -->
        <AdmissionActionButton
          v-if="showSendReminder && !singleConsultantHospital"
          data-test-id="admission-send-form"
          class="relative w-[250px]"
          action="reminder"
          @sendReminder="sendInsurerFormModal = true"
        />

        <div
          v-if="showRateInsurerDropdown"
          class="relative w-[250px]"
          v-click-outside="closeDropdown"
          :class="{ disabled: disableOn }"
        >
          <div
            class="flex items-center justify-between bg-medosync-blue border-medosync-blue px-5 py-3 w-full"
            :class="[
              dropdownOpen ? 'border-t-2 border-r-2 border-l-2 rounded-t-3xl' : 'border-2 rounded-full',
              { 'cursor-pointer': !disableOn, 'cursor-not-allowed': disableOn }
            ]"
            @click="toggleDropdown"
          >
            <i class="fa-solid fa-puzzle-piece"></i>
            <p>Rate Insurer Form</p>
            <i class="fa-solid" :class="[dropdownOpen ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
          </div>

          <div
            :class="{ hidden: !dropdownOpen }"
            class="absolute z-[1000] bg-white flex flex-column justify-end w-full"
          >
            <div class="border-r-2 border-b-2 border-l-2 border-medosync-blue bg-white w-full overflow-auto">
              <ul class="bg-white text-sm text-gray-700">
                <li
                  class="h-12 px-3 flex items-center hover:bg-medosync-blue hover:bg-opacity-40 cursor-pointer"
                  @click="disableOn ? null : handleDropdownAction('approve')"
                >
                  <i class="fa-solid fa-check text-xl mr-2"></i>
                  <span class="w-full text-center">Approve insurer form</span>
                </li>
                <li
                  class="h-12 px-3 flex items-center hover:bg-medosync-blue hover:bg-opacity-40 cursor-pointer"
                  @click="disableOn ? null : handleDropdownAction('resend')"
                >
                  <i class="fa-solid fa-xmark text-xl mr-2"></i>
                  <span class="w-full text-center">Follow up with patient</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="w-[230px]"></div>
      <BaseInsurerForm
        v-if="sendInsurerFormModal"
        :appointmentId="appointment.appointment_id"
        :appointmentDate="appointment.scheduled_on"
        :patientClaimId="claimPatientId"
        :claimFormType="claimFormType"
        :patientInfo="appointment.patient"
        :hospitalName="hospitalName"
        :fetchClaimFormData="fetchedClaimformData"
        @refresh-data="$emit('refresh-data')"
        @send-form="sendPatientRegistrationForm"
        @close="sendInsurerFormModal = false"
      />
    </div>

    <div v-if="isEditMode" class="grid grid-cols-6 mt-8 mb-12">
      <div class="col-span-2">
        <div class="name-wrap"><span class="font-bold">Patient Name: </span>{{ appointment.patient.full_name }}</div>
      </div>
      <div class="col-span-2">
        <div><span class="font-bold">MRN: </span>{{ appointment.patient.mrn }}</div>
      </div>
      <div class="col-span-2">
        <span class="font-bold">Insurer: </span>
        <span> {{ appointment.insurance?.[0].insurer_name }} </span>
      </div>
    </div>
    <div v-else class="grid grid-cols-6 mt-4 mb-10">
      <div class="col-span-2 pr-10">
        <h4 class="mb-2">
          <span class="font-bold">Consultant Name: </span>{{ appointment.admitted_by?.full_name || '/' }}
        </h4>
        <div class="name-wrap"><span class="font-bold">Patient Name: </span>{{ appointment.patient.full_name }}</div>
        <div>
          <span class="font-bold">Insurer: </span>
          <span> {{ appointment.insurance?.[0].insurer_name }} </span>
        </div>
      </div>
      <div class="col-span-2">
        <p class="mb-2">&nbsp;</p>
        <div><span class="font-bold">MRN: </span>{{ appointment.patient.mrn }}</div>
        <div><span class="font-bold">App. ID: </span>{{ appointment.hospital_appointment_id }}</div>
      </div>
      <div class="col-span-2">
        <p class="mb-2">&nbsp;</p>
        <div></div>
        <div></div>
      </div>
    </div>
    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import { OpenAPIJSON } from 'api/openapi';
import { PreAdmitState } from 'enums';
import BaseInsurerForm from '@/components/modal/SendInsurerForm/BaseInsurerForm.vue';
import FormStatus from '@/components/claim-forms/components/FormStatus.vue';
import AdmissionActionButton from '@/components/admission/AdmissionActionButton.vue';
import { ToastComponent } from 'ui';
import { hospitalConfigStore } from '@/store/config';

export default {
  name: 'AdmissionDetailsHeader',
  components: { BaseInsurerForm, FormStatus, AdmissionActionButton, ToastComponent },
  props: {
    appointment: {
      type: Object,
      required: true
    },
    hospitalName: {
      type: String,
      required: false,
      default: ''
    },
    isEditMode: {
      type: Boolean,
      default: false
    },
    claimFormId: {
      type: [String, Number],
      required: true
    },
    claimPatientId: {
      type: Number,
      required: false
    },
    claimFormType: {
      type: String,
      required: false
    },
    fetchedClaimformData: {
      type: Object,
      required: false
    },
    claimSectionWarnings: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      openApi: new OpenAPIJSON(),
      sendInsurerFormModal: false,
      singleConsultantHospital: false,
      dropdownOpen: false,
      claim_consultant_id: null,
      consultantList: 0,
      appointmentRef: {
        patient: {
          mrn: this.appointment?.patient?.mrn,
          title: '',
          first_name: this.appointment?.patient?.first_name,
          last_name: this.appointment?.patient?.last_name,
          full_name: this.appointment?.patient?.full_name,
          dob: this.appointment?.patient?.dob,
          gender: this.appointment?.patient?.gender,
          contact: {
            postal_address: [],
            telephone_landline: this.appointment?.patient?.contact?.telephone_landline,
            telephone_mobile: this.patient?.contact?.telephone_mobile,
            telephone_fax: this.appointment?.patient?.contact?.telephone_fax,
            email: this.appointment?.patient?.contact?.email
          }
        },
        hospital_appointment_id: this.appointment?.hospital_appointment_id,
        insurance: [
          {
            insurer: {
              type: 'id',
              id: this.appointment?.insurance?.[0]?.insurer_id
            },
            type: 'id',
            insurer_id: this.appointment?.insurance?.[0]?.insurer_id,
            insurer_name: this.appointment?.insurance?.[0]?.insurer_name,

            policy_plan: {
              id: this.fetchedClaimformData?.insurance?.policy_plan?.id,
              type: 'id'
            },
            policy_number: this.fetchedClaimformData?.insurance?.membership_no1
          }
        ],
        //Change to Fiona's ID
        admitting_consultant: {
          consultant: {
            type: 'id',
            id: null
          },
          datetime: this.appointment?.scheduled_on
        },
        discharging_consultant: {
          consultant: {
            type: 'id',
            id: null
          },
          datetime: this.appointment?.scheduled_on
        }
      }
    };
  },
  watch: {
    'appointment.scheduled_on': {
      handler(newVal) {
        if (newVal) {
          const scheduledOn = new Date(newVal);
          scheduledOn.setHours(scheduledOn.getHours() + 3);

          const isoString = scheduledOn.toISOString();
          const formattedString = isoString.slice(0, 19);
          this.appointmentRef.discharging_consultant.datetime = formattedString;
        } else {
          this.appointmentRef.discharging_consultant.datetime = null;
        }
      },
      immediate: true
    }
  },
  computed: {
    showSendReminder() {
      if (
        this.appointment?.preadmit_state === PreAdmitState.RequirementsAreNotMet ||
        this.appointment?.preadmit_state === PreAdmitState.AwaitingReplayForPatient ||
        this.appointment?.preadmit_state === PreAdmitState.ReviewPatientResponse ||
        this.appointment?.preadmit_state === PreAdmitState.NeedsRevision
      ) {
        return true;
      } else {
        return false;
      }
    },

    showRateInsurerDropdown() {
      return (
        this.appointment?.preadmit_state === PreAdmitState.ReviewPatientResponse ||
        this.appointment?.preadmit_state === PreAdmitState.RequirementsAreNotMet ||
        this.appointment?.preadmit_state === PreAdmitState.AwaitingReplayForPatient ||
        this.appointment?.preadmit_state === PreAdmitState.NeedsRevision
      );
    },

    showResubmissionButton() {
      return this.appointment?.preadmit_state === PreAdmitState.ReadyForReview && this.appointment?.checks?.length;
    },
    showCloseFileButton() {
      return this.appointment?.preadmit_state === PreAdmitState.ReadyForAdmission;
    },
    disableOn() {
      return !(Array.isArray(this.appointment.checks) && this.appointment.checks.length === 0);
    }
  },
  mounted() {
    this.getMedics();
    this.singleConsultantHospital = hospitalConfigStore.getters.is_single_consultant_hospital;
  },
  methods: {
    async changeRegistrationState(value) {
      const oapi = new OpenAPIJSON();
      try {
        await oapi.registration_state_change(this.appointment.appointment_id, value, 'UI CHANGE');
        setTimeout(() => {
          this.$emit('refresh-data');
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    },

    async appointmentCreate() {
      const oapi = new OpenAPIJSON();
      try {
        const response = await oapi.appointment_create(this.appointmentRef);
        this.$refs.toast.showToast('Appointment and Patient created.', 'fas fa-check', 'success');
        await this.createClaim(response.claims[0].claim_id);
      } catch (error) {
        this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
      }
    },

    async getMedics() {
      const oapi = new OpenAPIJSON();
      const res = await oapi.searchDoctors(null, null, null, null, false);
      this.consultantList = res.length;
      if (res.length === 1) {
        this.appointmentRef.admitting_consultant.consultant.id = res[0].medic.id;
        this.appointmentRef.discharging_consultant.consultant.id = res[0].medic.id;
      }
    },

    async createClaim(appointment_claim_id) {
      const oapi = new OpenAPIJSON();

      try {
        const response = await oapi.claim_create(
          appointment_claim_id,
          this.appointmentRef.admitting_consultant.consultant.id,
          this.appointment?.insurance?.[0]?.insurer_id
        );
        this.$refs.toast.showToast('Claim Created', 'fas fa-check', 'success');
        this.claim_consultant_id = response.claim_consultant_id;
        await this.createClaimForm();
      } catch (error) {
        this.$refs.toast.showToast(error, 'fas fa-xmark', 'error');
      }
    },

    async changeRequirementUpdate(appointment_id, claim_form_id, value) {
      const oapi = new OpenAPIJSON();
      const payload = {
        locator: {
          appointment: {
            type: 'id',
            appointment_id: appointment_id
          },
          claim_form_id: claim_form_id,
          product: 'access',
          requirement: 'signature'
        },
        state: value
      };

      try {
        await oapi.requirement_update(payload);
        setTimeout(() => {
          this.$emit('refresh-data');
        }, 1000);
        if (this.consultantList === 1) {
          await this.appointmentCreate();
        }
      } catch (error) {
        console.log(error);
      }
    },
    toggleDropdown() {
      if (!this.disableOn) {
        this.dropdownOpen = !this.dropdownOpen;
      }
    },
    async handleDropdownAction(value) {
      if (value === 'approve') {
        this.$refs.toast.showToast('Insurer form approved.', 'fas fa-check', 'success');
        await this.changeRequirementUpdate(this.appointment.appointment_id, this.claimFormId, 'verified');
        await this.changeRegistrationState('all_requirements_met');
      } else if (value === 'resend') {
        await this.changeRegistrationState('needs_revision');
        this.$refs.toast.showToast('Insurer form Follow up with patient.', 'fas fa-check', 'error');
        this.$emit('refresh-data');
      }
      this.dropdownOpen = false;
    },

    updateNumber(value) {
      this.$emit('update-patient-claimform', value);
    },

    async createClaimForm() {
      try {
        const oapi = new OpenAPIJSON();

        const response = await oapi.claimform_consultant_create(
          this.claim_consultant_id,
          this.appointment?.insurance?.[0]?.insurer_id,
          this.fetchedClaimformData?.claim_form_type
        );
        console.log(response);
      } catch (err) {
        console.log(err);
      }
    },

    closeDropdown() {
      this.dropdownOpen = false;
    },
    async sendPatientRegistrationForm(value) {
      this.sendInsurerFormModal = false;
      if (value.success) {
        this.$refs.toast.showToast(`${value.type} was sent successfully.`, 'fas fa-check', 'success');
        this.$emit('refresh-data');
      } else {
        this.$refs.toast.showToast(
          'An error occurred. Please contact the support.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
      }

      try {
        await this.openApi.registration_state_change(
          this.appointment.appointment_id,
          PreAdmitState.AwaitingReplayForPatient,
          'UI CHANGE'
        );
        this.$emit('change-pre-admit-state', PreAdmitState.AwaitingReplayForPatient);
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown:hover {
  button {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .dropdown-menu {
    display: block;
  }
}

h4 {
  font-size: 18px;
}
.name-wrap {
  word-break: break-all;
}
.header-title-height {
  height: 40px;
}
.header-height {
  height: 250px;
}
.appointment-status {
  border: 1px solid #000000;
  border-radius: 10px;
  padding: 0.5rem 1rem;
}
.btn-claim {
  display: flex;
  align-items: center;
  background-color: #00a7e1;
  border-radius: 50px;
  padding: 25px 20px;
  height: 40px;
  width: 230px;
}
input[type='text'] {
  border: 1px solid #000000;
  border-radius: 5px;
  padding: 0 0.4rem;
}
select {
  border: 1px solid #000000;
  border-radius: 5px;
  padding: 0 0.4rem;
  width: auto;
}
.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
</style>
