export const validateEmail = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const iconMap = {
  pdf: 'fa-regular fa-file-pdf',
  csv: 'fa-solid fa-file-csv',
  jpeg: 'fa-regular fa-image',
  jpg: 'fa-regular fa-image',
  png: 'fa-regular fa-image',
  xls: 'fa-regular fa-file-excel',
  xlsx: 'fa-regular fa-file-excel',
  xlsm: 'fa-regular fa-file-excel',
  xlt: 'fa-regular fa-file-excel',
  xltx: 'fa-regular fa-file-excel',
  xltm: 'fa-regular fa-file-excel',
  xlsb: 'fa-regular fa-file-excel',
  xla: 'fa-regular fa-file-excel',
  xlam: 'fa-regular fa-file-excel',
  xll: 'fa-regular fa-file-excel',
  xlw: 'fa-regular fa-file-excel',
  doc: 'fa-regular fa-file-word',
  docx: 'fa-regular fa-file-word',
  docm: 'fa-regular fa-file-word',
  dot: 'fa-regular fa-file-word',
  dotx: 'fa-regular fa-file-word',
  dotm: 'fa-regular fa-file-word'
};

export const convertHtmlToSms = htmlContent => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlContent;

  // Convert <p> tags and <br> tags to new lines
  tempElement.querySelectorAll('p, br').forEach(function (tag) {
    tag.insertAdjacentHTML('afterend', '\n');
  });

  // Process each anchor tag
  tempElement.querySelectorAll('a').forEach(function (anchorTag) {
    const linkUrl = anchorTag.getAttribute('href');
    const linkText = anchorTag.textContent || anchorTag.innerText;
    const smsLink = `${linkText} (${linkUrl})`;

    // Replace the anchor tag with the SMS link
    anchorTag.insertAdjacentText('beforebegin', smsLink);
    anchorTag.remove();
  });

  const plainText = tempElement.textContent || tempElement.innerText;
  return plainText;
};

export const convertSmsToHtml = smsContent => {
  const textWithBr = smsContent.replace(/\n/g, '<br>');

  // Regular expression to match URLs in the text
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const htmlText = textWithBr.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');

  return htmlText;
};

export const COMPOSITE_HOSTS = [
  'dev.hospital.medosync.com',
  'uat.hospital.medosync.com',
  'uat.beacon.medosync.com',
  'beacon.medosync.com'
];

export const dashboardRedirectPage = () => {
  const hostname = window.location.hostname;

  if (COMPOSITE_HOSTS.includes(hostname)) {
    return '/navigator/compositeclaims';
  } else {
    return '/navigator/claims';
  }
};
