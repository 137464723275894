<script setup lang="ts">
import { ref, watch } from 'vue';
import type { Ref } from 'vue';

import { userStore } from '@/store/user';
import { AssocType } from 'enums';
import { OpenAPIJSON } from 'api';
import { toBase64 } from 'utils';

import FullscreenOverlay from '@/components/modal/FullscreenOverlay.vue';
import DownloadPatientDataTemplate from '@/components/admission/DownloadPatientDataTemplate.vue';
import UploadFiles from '@/components/misc/UploadFiles.vue';
import { ToastComponent } from 'ui';

const emits = defineEmits(['close']);

const handleModalClose = (newFileName: string | null = null) => {
  file.value = null;
  emits('close', newFileName ? newFileName : null);
};

const file = ref<File | null>(null);
const fileName = ref<string | null>(null);
const isFileUploadSuccess: Ref<boolean> = ref(false);
const isFileProcessed: Ref<boolean> = ref(false);
const isLoading = ref(false);
const isProcessing = ref(false);
const openApi = new OpenAPIJSON();
const toast = ref<any>(null);
const errorMessage = ref<string | null>(null);
const errorDetailedByLine: Ref<any> = ref([]);

watch(file, newFile => {
  if (newFile) {
    resetErrorMessage();
  }
});

const uploadFile = async () => {
  resetErrorMessage();
  if (!file.value) return;

  try {
    isLoading.value = true;

    const base64 = await toBase64(file.value);
    const currentTime = new Date().getTime().toString();
    fileName.value = `${currentTime.slice(-5)}-${file.value.name}`;
    if (file.value.type !== 'text/csv') {
      errorMessage.value = 'File type not supported. Please use template file.';
      resetFile();
      isFileUploadSuccess.value = false;
      isLoading.value = false;
      return;
    }

    const assoc = {
      assoc_type: AssocType.Hospital,
      type: 'id',
      id: userStore.getters.user?.hospital_id
    };
    await openApi.file_add_by_id(assoc, [
      {
        file_name: fileName.value,
        data: base64,
        mime_type: file.value.type,
        file_category: 'feed_appointments'
      }
    ]);

    isLoading.value = false;
    isFileUploadSuccess.value = true;
    processUploadedData();
  } catch (error) {
    errorMessage.value = 'An error occurred while uploading the file. Please try again.';
    resetFile();
    isLoading.value = false;
    isFileUploadSuccess.value = false;
  }
};
const processUploadedData = async () => {
  try {
    isProcessing.value = true;
    await openApi.appointment_file_process(fileName.value as string, userStore.getters.user?.hospital_id);
    isFileProcessed.value = true;
  } catch (error) {
    if (error?.response?.data?.reason.includes('APP-019')) {
      errorMessage.value =
        'This file was already processed and cannot be processed again. Appointments have already been created.';
    } else if (error?.response?.data?.reason.includes('APP-020')) {
      errorMessage.value = 'This file does not contain any data records. Please add appointment data records.';
    } else if (error?.response?.data?.reason.includes('APP-022')) {
      errorMessage.value = 'This file contains invalid data. Please check the file and try again.';
      const errorData = JSON.parse(error?.response?.data?.reason.split(':').slice(1).join(':').trim());
      errorData.forEach(error => {
        errorDetailedByLine.value.push({
          row: error.row,
          error: error.error,
          description: setErrorDescription(error.error)
        });
      });
    } else {
      errorMessage.value =
        'An error occurred while processing the file. Please check the file format,data records and try again.';
    }
    isFileProcessed.value = false;
  } finally {
    isProcessing.value = false;
  }
};
const removeFile = () => {
  file.value = null;
};
const resetFile = () => {
  file.value = null;
  fileName.value = null;
};
const resetErrorMessage = () => {
  isFileProcessed.value = false;
  isFileUploadSuccess.value = false;
  errorMessage.value = null;
  errorDetailedByLine.value = [];
};
const uploadNewFile = () => {
  resetErrorMessage();
  resetFile();
};
const setErrorDescription = errorValue => {
  const fieldName = errorValue.split(':')[1].trim();
  if (errorValue.includes('Date Parse error')) {
    return `The system was unable to process the date you entered in the "${fieldName}" field because it doesn't match the expected format. Please ensure that you are using the correct date format, which should be for example in the form of YYYY-MM-DD (2023-09-13). Double-check the day, month, and year values to make sure they are correct, and try again.`;
  } else if (errorValue.includes('Empty value for')) {
    return `The required field: "${fieldName}" is empty. Please review the form, and fill in all required fields before submitting again.`;
  } else {
    return `An unexpected issue has occurred with the value you entered in the ${fieldName} field. Please review the form, and ensure that all values are correct before submitting again.`;
  }
};
const downloadErrorList = () => {
  const rows = [
    ['Table row', 'Error name', 'Description of error'],
    ...errorDetailedByLine.value.map(error => [error.row, error.error, `"${error.description.replace(/"/g, '""')}"`])
  ];

  let csvContent = 'data:text/csv;charset=utf-8,' + rows.map(e => e.join(',')).join('\n');

  var encodedUri = encodeURI(csvContent);
  var link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  const date = new Date().toISOString().split('T')[0].replace(/-/g, '');
  link.setAttribute('download', `${date}_ErrorList-${file.value?.name}`);
  document.body.appendChild(link);

  link.click();
};
</script>

<template>
  <FullscreenOverlay @close-modal="handleModalClose">
    <template #header>
      <h2 class="text-2xl font-semibold text-medosync-violet-highlight mb-5">Upload patient data</h2>
    </template>
    <template #body>
      <DownloadPatientDataTemplate />

      <div class="border-2 border-black p-4 my-5">
        <h3 class="text-xl font-medium mb-5" :class="{ 'text-medosync-red': errorMessage }">
          {{ errorMessage ? 'Upload patient data - Error(s) occurred. Data was not uploaded.' : 'Upload patient data' }}
        </h3>
        <template v-if="errorMessage">
          <div class="pt-2 pb-12">
            <h4 class="text-medosync-red font-bold">{{ errorMessage }}</h4>
          </div>
        </template>
        <UploadFiles
          v-if="!file"
          v-model="file"
          :multiple="false"
          :showButtons="true"
          :no-close="true"
          @cancel="handleModalClose"
        />
        <template v-if="file">
          <div :class="[errorMessage && errorDetailedByLine.length ? 'mb-6' : 'h-[170px]']">
            <div v-if="!isFileUploadSuccess && !isLoading" class="flex flex-col">
              <div class="flex justify-between items-center bg-medosync-grey px-4 py-2">
                <h5>
                  {{ file.name }}
                </h5>
                <i class="fa-regular fa-trash-can cursor-pointer text-xl" @click="removeFile"></i>
              </div>
            </div>

            <div
              v-else-if="!isFileUploadSuccess && isLoading"
              class="px-5 bg-[#F9F9F9] flex flex-col justify-around align-center h-full"
            >
              <h4>Your files are being uploaded. Please wait... this can take up to 5 minutes.</h4>
              <div class="flex justify-start items-center">
                <i class="fa-solid fa-spinner fa-spin-pulse text-medosync-blue fa-3x me-6" />
                <p>{{ file.name }}</p>
              </div>
            </div>
            <div
              v-else-if="isFileUploadSuccess && !isLoading && isProcessing"
              class="px-5 bg-[#F9F9F9] flex flex-col justify-around align-center h-full"
            >
              <h4>Your files are being processed. Please wait... this can take up to 5 minutes.</h4>
              <div class="flex justify-start items-center">
                <i class="fa-solid fa-spinner fa-spin-pulse text-medosync-blue fa-3x me-6" />
                <p>{{ file.name }}</p>
              </div>
            </div>

            <div
              v-if="isFileUploadSuccess && !isLoading && !isProcessing"
              class="flex items-center border-2 bg-[#F9F9F9] p-4"
              :class="errorMessage ? 'border-medosync-red' : 'border-medosync-dark-green'"
            >
              <i v-if="!errorMessage" class="fa-solid fa-check text-medosync-dark-green fa-xl me-4" />
              <i v-else class="fa-solid fa-x text-medosync-red fa-xl me-4" />
              <p data-test-id="uploaded-file-name-text">{{ file.name }}</p>
            </div>
          </div>
        </template>
        <section v-if="errorMessage && errorDetailedByLine.length" class="pt-8">
          <div class="flex justify-between">
            <h3 class="text-xl font-medium text-medosync-red">List of error(s)</h3>
            <div class="flex items-center gap-3.5 cursor-pointer" @click="downloadErrorList">
              <i class="fa-solid fa-download fa-xl" />
              <span class="underline">Download error list</span>
            </div>
          </div>
          <div class="pt-6">
            <table class="min-w-full table-auto border-collapse border border-gray-200">
              <thead class="bg-[#C4C4D3] text-left h-12">
                <tr>
                  <td class="text-center" style="width: 15%">Table row</td>
                  <td class="pl-2" style="width: 25%">Error name</td>
                  <td class="pl-2" style="width: 60%">Description of error</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(error, rowIndex) in errorDetailedByLine"
                  :key="rowIndex"
                  :class="rowIndex % 2 === 0 ? 'bg-white' : 'bg-[#EEEFF3]'"
                  class="hover:bg-[#00a3f4]/50"
                >
                  <td class="text-center">{{ error.row }}</td>
                  <td class="p-2">{{ error.error }}</td>
                  <td class="p-2">{{ error.description }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <div v-if="file" class="flex justify-end items-center mt-6">
          <button
            v-if="!isFileUploadSuccess || !isFileUploadSuccess"
            :disabled="isLoading"
            type="button"
            class="flex items-center bg-medosync-blue px-6 py-2 text-sm rounded-full"
            @click="uploadFile"
          >
            Upload File
          </button>
          <div class="flex gap-8" v-if="isFileUploadSuccess && !isFileProcessed">
            <button
              :disabled="isLoading || isProcessing"
              data-test-id="close-modal-button"
              type="button"
              class="flex items-center bg-medosync-blue px-6 py-2 text-sm rounded-full"
              @click="uploadNewFile"
            >
              Upload a new file
            </button>
          </div>
          <button
            v-if="isFileProcessed"
            data-test-id="close-modal-button"
            type="button"
            class="flex items-center bg-medosync-blue px-6 py-2 text-sm rounded-full"
            @click="emits('close')"
          >
            Start working with uploaded data
          </button>
        </div>
      </div>
      <ToastComponent ref="toast"></ToastComponent>
    </template>
  </FullscreenOverlay>
</template>
