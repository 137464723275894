<template>
  <div>
    <transition name="notes">
      <div
        data-test-id="open-notes-btn"
        v-if="!notesOpened"
        class="notes-btn cursor-pointer"
        @click="toggleNotes(this.notes?.length ? false : true)"
      >
        <template v-if="this.notes?.length">
          <i class="fas fa-file-pen text-xl"></i>
          <span class="text-sm font-medium underline ml-1">Notes</span>
        </template>
        <template v-else>
          <i class="fas fa-plus text-xl"></i>
          <span class="text-sm font-medium underline ml-1">Add note</span>
        </template>
        <template v-if="notesCount">
          <div class="orange-bubble ml-2">
            <span>{{ notesCount }}</span>
          </div>
        </template>
      </div>
    </transition>
    <transition name="notes">
      <div class="notes-menu shadow-xl popup-scrollbar" v-if="notesOpened">
        <div class="flex flex-row justify-between align-center pl-6 pr-2 pt-6">
          <h2 class="font-medium">Notes</h2>
          <i id="close-notes-btn" class="fas fa-close text-xl cursor-pointer" @click="toggleNotes()"></i>
        </div>

        <div class="my-5 border-b-2">
          <div class="flex flex-row items-center my-3 pl-6 pr-2">
            <div id="open-add-note-btn" v-show="!noteAdding" class="cursor-pointer" @click="openNotes()">
              <i class="fas fa-circle-plus text-xl mr-2"></i>
              <span class="text-sm font-medium">Add Note</span>
            </div>
            <template v-if="noteAdding">
              <textarea
                ref="inputField"
                v-model="newNoteText"
                class="textarea-height w-full"
                name=""
                id=""
                v-on:keyup.enter="addNoteOnClaim()"
              ></textarea>
              <div class="flex flex-col items-center justify-between textarea-height textarea-icons">
                <i id="close-add-note-btn" class="fas fa-close text-xl cursor-pointer" @click="noteAdding = false"></i>
                <i id="send-note-btn" class="far fa-paper-plane text-xl cursor-pointer" @click="addNoteOnClaim()"></i>
              </div>
            </template>
          </div>
          <NotesList :notes="notes" :claim="claim" />
        </div>
      </div>
    </transition>
    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { notes_store } from '@/store/notes';
import NotesList from './NotesList.vue';
import { OpenAPIJSON } from 'api';
import { ToastComponent } from 'ui';

export default {
  components: { NotesList, ToastComponent },
  name: 'NotesClaims',
  data() {
    return {
      notes: [],
      noteAdding: false,
      newNoteText: null,
      oapi: new OpenAPIJSON()
    };
  },
  props: {
    claim: { required: true, type: Object }
  },
  computed: {
    notesOpened() {
      return notes_store.state.notesOpened;
    },
    notesCount() {
      return this.notes?.length;
    }
  },
  unmounted() {
    notes_store.dispatch('closeNotes');
  },
  created() {
    this.initNotes();
  },
  methods: {
    toggleNotes(startNewNote = false) {
      notes_store.dispatch('toggleNotes');

      if (startNewNote) this.openNotes();

      if (this.notesOpened) this.initNotes();
    },
    async initNotes() {
      let fut = null;
      if (Object.hasOwn(this.claim, 'claim_composite_id')) {
        fut = this.oapi.claim_composite_message_list(this.claim.claim_composite_id, true);
      } else if (Object.hasOwn(this.claim, 'claim_consultant_id')) {
        fut = this.oapi.claim_consultant_message_list(this.claim.claim_consultant_id, true);
      }
      if (fut !== null) {
        await fut
          .then(res => {
            this.notes = res.items;
          })
          .catch(error => {
            console.log('error', error);
          });
      }
    },
    async addNoteOnClaim() {
      this.noteAdding = false;
      if (this.newNoteText === '' || this.newNoteText == null) return;
      let fut = null;
      if (Object.hasOwn(this.claim, 'claim_composite_id')) {
        fut = this.oapi.claim_composite_message_add(this.claim.claim_composite_id, this.newNoteText);
      } else if (Object.hasOwn(this.claim, 'claim_consultant_id')) {
        fut = this.oapi.claim_consultant_message_add(this.claim.claim_consultant_id, this.newNoteText);
      }
      if (fut !== null) {
        await fut
          .then(() => {
            this.initNotes();
            this.noteAdding = false;
            this.newNoteText = '';
            this.$refs.toast.showToast('Note Added!', 'fas fa-check', 'success');
          })
          .catch(error => {
            this.noteAdding = false;
            this.$refs.toast.showToast('Can not create a note.', 'fa-solid fa-triangle-exclamation', 'error');
            console.log('error', error);
          });
      }
    },
    openNotes() {
      this.noteAdding = true;
      nextTick(() => {
        this.$refs.inputField.focus();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.icon {
  font-size: 25px;
}
.notes-menu {
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  width: 400px;
  background-color: #fff;
}
.notes-btn {
  display: flex;
  align-items: center;
  background-color: #cad4e0;
  border-radius: 50px;
  padding: 10px 15px;
  max-width: 130px;
}
.orange-bubble {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ef7934;
  border-radius: 100%;
  height: 18px;
  width: 18px;
  font-size: 12px;
}
textarea {
  resize: none;
  background-color: #d8d9d8;
  outline: 2px solid #505050;
  padding: 0.2rem;
}
textarea::-webkit-bar {
  border-radius: 8px;
  width: 10px;
  background-color: #172033;
}
textarea::-webkit-bar-track {
  border-radius: 8px;
}
textarea::-webkit-bar-thumb {
  border-radius: 8px;
  background-color: #bababa;
}
.textarea-height {
  height: 150px;
}
.textarea-icons {
  padding: 0.4rem 0.3rem;
  background-color: #00a6e0;
  outline: 2px solid #505050;
}
h2 {
  font-size: 20px;
  color: #0c276c;
}
.notes-enter-active,
.notes-leave-active {
  transition: all 1s;
}

.notes-enter-from,
.notes-leave-to {
  opacity: 0;
  width: 200px;
  right: 0;
}
.notes-leave-active div {
  opacity: 0;
}
.notes-enter-active div {
  opacity: 0.1;
}
</style>
