<template>
  <div :id="inputId" class="bed-type-section">
    <div class="custom-sub-title mt-3">
      {{ title }}
    </div>

    <div class="col-12">
      <div class="flex flex-col">
        <div class="my-2 sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      Room type
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      Ward Name
                    </th>
                    <th
                      scope="col"
                      class="px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      Room Name / Bed No.
                    </th>
                    <th
                      v-if="enableDates"
                      scope="col"
                      class="py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      Dates - From / To
                    </th>
                    <th
                      scope="col"
                      class="width-10 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      Days
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    ></th>
                  </tr>
                </thead>

                <tbody>
                  <!-- Grey/white alternate rows -->
                  <tr
                    id="bed-row"
                    :class="{ 'bg-white': idx % 2 === 0, 'bg-gray-50': idx % 2 !== 0 }"
                    v-for="(bed, idx) in beds"
                    :key="bed.bedId"
                  >
                    <td class="px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <select
                        :id="inputId + '_bed_type' + idx"
                        class="custom-input"
                        v-model="bed.bed_type"
                        :disabled="isTextBoxDisabled"
                      >
                        <option v-for="bedtype in bedTypes" :key="bedtype.bed_type_id" :value="bedtype.oapi_name">
                          {{ bedtype.display_name }}
                        </option>
                      </select>
                    </td>
                    <td class="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
                      <input
                        :id="inputId + '_room_name' + idx"
                        type="text"
                        class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder=""
                        v-model="bed.room_name"
                        :disabled="isTextBoxDisabled"
                      />
                    </td>
                    <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                      <input
                        :id="inputId + '_bed_name' + idx"
                        type="text"
                        class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder=""
                        v-model="bed.bed_name"
                        :disabled="isTextBoxDisabled"
                      />
                    </td>
                    <td v-if="enableDates" class="flex flex-col py-4 whitespace-nowrap text-sm text-gray-500">
                      <date-picker
                        class="!w-[128px] mb-1"
                        :id="inputId + '_bed_start_time' + idx"
                        v-model:value="bed.start_time"
                        value-type="YYYY-MM-DDThh:mm:ss"
                        format="DD-MM-YYYY"
                        :disabled="isTextBoxDisabled"
                        :append-to-body="false"
                      ></date-picker>
                      <date-picker
                        class="!w-[128px]"
                        :id="inputId + '_bed_end_time' + idx"
                        v-model:value="bed.end_time"
                        value-type="YYYY-MM-DDThh:mm:ss"
                        format="DD-MM-YYYY"
                        :disabled="isTextBoxDisabled"
                        :append-to-body="false"
                      ></date-picker>
                    </td>
                    <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                      <input
                        :id="inputId + '_bed_days' + idx"
                        type="number"
                        class="w-[60px] custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder=""
                        v-model="bed.days"
                        :disabled="isTextBoxDisabled"
                      />
                    </td>
                    <td
                      v-if="!isTextBoxDisabled"
                      :id="inputId + '_remove_bed' + idx"
                      class="px-2 py-4 whitespace-nowrap text-sm text-gray-500 font-semibold hover-tooltip"
                      @click="removeBed(idx, beds)"
                    >
                      <i class="fas fa-minus-circle text-red-600 cursor-pointer"></i>
                      <div
                        class="tooltip absolute bg-medosync-inactive-grey font-sans text-white text-xs w-[120px] right-[20px] mt-2 p-1 font-normal"
                      >
                        Remove Item
                      </div>
                    </td>
                    <td
                      v-else
                      :id="inputId + '_remove_bed' + idx"
                      class="px-2 py-4 whitespace-nowrap text-sm text-gray-500 font-semibold"
                    >
                      <i class="fas fa-minus-circle text-grey-600"></i>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="col-6 mt-4 pl-5">
                <div
                  v-if="!isTextBoxDisabled"
                  :id="inputId + '_add_bed'"
                  class="my-3 custom-click cursor-pointer font-semibold"
                  @click="addBed()"
                >
                  <i class="fas fa-plus-circle text-green-600"></i> Add another bed
                </div>
                <div
                  v-else
                  :id="inputId + '_add_bed'"
                  class="my-3 custom-click cursor-pointer font-semibold text-gray-200"
                >
                  <i class="fas fa-plus-circle text-gray-200"></i> Add another bed
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import { OpenAPIJSON } from 'api/openapi';
// components
//import CustomDatePicker from '@/components/claim-forms/components/Datepicker'
import StringUtility from 'utils/stringUtility';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import { mapState, mapMutations } from 'vuex';
import { ToastComponent } from 'ui';

/* Params 
  modelValue // objects with array of bed fields: 
  title // title of section String
*/

export default {
  name: 'Bed-type',
  props: {
    modelValue: {},
    title: { default: '' },
    id: {},
    defaultDate: { required: true },
    enableDates: { required: false, type: Boolean }
  },
  components: {
    DatePicker,
    ToastComponent
    // CustomDatePicker
  },
  data() {
    return {
      bedTypes: null,
      bedIdx: -1,
      inputId: this.id || `beds_${(Math.random() * 1000).toFixed()}`,
      api: new OpenAPIJSON()
    };
  },
  computed: {
    StringUtility() {
      return StringUtility;
    },
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },
    beds: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  },
  methods: {
    ...mapMutations(['setTextBoxDisabled']),
    async getBedTypes() {
      await this.api
        .getBedTypes()
        .then(res => {
          this.bedTypes = res;
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });
    },
    addBed() {
      // Fake, negative bed ids created here to allow new entries to be removed.
      // Submission to server ignores this id and creates its own.
      let dos = this.beds.length > 0 ? this.beds[this.beds.length - 1].start_time : this.defaultDate;

      this.beds.push({
        bedId: this.bedIdx,
        bed_name: null,
        room_name: null,
        bed_type: null,
        rate: null,
        rate_unit: null,
        start_time: dos,
        end_time: null,
        days: null
      });
      this.bedIdx -= 1;
    },
    removeBed(index, beds) {
      const bedIndex = beds[index];
      if (bedIndex) {
        this.beds.splice(index, 1);
      }
    }
  },
  created() {
    this.getBedTypes();
  }
};
</script>

<style lang="scss" scoped>
.custom-input {
  border-radius: 5px;
  line-height: 24px;
}

.required {
  color: red;
}

.custom-sub-title {
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
  text-decoration: underline;
  color: #8b8b8b;
}

.hover-tooltip {
  position: relative;

  .tooltip {
    visibility: hidden;
  }
}

.hover-tooltip:hover {
  .tooltip {
    visibility: visible;
  }
}
</style>
