<template>
  <div>
    <div style="clear: both"></div>

    <div class="s-report-sub-title">
      <span class="cursor-pointer" @click="handleModeChange()">
        <i class="fas fa-chevron-left mr-3"></i>
      </span>
      <template v-if="filterOption === 'all'"
        ><span>All Payments - {{ fileName }}</span></template
      >
      <template v-if="filterOption === 'matched'"
        ><span>Reconciled Payments - {{ fileName }}</span></template
      >
      <template v-else-if="filterOption === 'not_matched'"
        ><span>Unmatched Payments - {{ fileName }}</span></template
      >
      <template v-else-if="filterOption === 'external'"
        ><span>External Invoice Payments - {{ fileName }}</span></template
      >
    </div>

    <div class="col-12" v-if="selectedConsultant !== null">
      <!--          <div class="size-icons-section">
            <i class="fas fa-desktop size-icon" @click="smallScreen = false" :class="{'size-icon-active': !smallScreen}"></i>
            <i class="fas fa-tablet-alt size-icon" @click="smallScreen = true" :class="{'size-icon-active': smallScreen}"></i>
          </div> -->

      <div class="download-section">
        <button
          @click="unparse"
          type="button"
          class="inline-flex items-center mt-4 px-3.5 py-2.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Download CSV
        </button>
      </div>
    </div>

    <!-- <div class="search-section mb-3">
              <CustomDatePicker v-model="filterDate" />
          </div> -->

    <SpinnerLoader v-if="isLoading" />

    <div v-if="payments" class="col-12 flex flex-col mt-5">
      <div>Payments from insurer file</div>
      <div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    SRC PAGE / LINE
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 dropdown-width text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    INVOICE NO
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    INSURER CLAIM REF
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    INVOICED
                  </th>
                  <th
                    v-if="filterOption !== 'pended' && filterOption !== 'rejected'"
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    PAID
                  </th>
                  <th
                    v-if="filterOption !== 'pended' && filterOption !== 'rejected'"
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    ADJUSTMENT
                  </th>
                  <th
                    v-if="filterOption !== 'pended' && filterOption !== 'rejected'"
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    TAX
                  </th>
                  <template v-if="filterOption === 'pended'">
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      DATE
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      PENDED REASON
                    </th>
                  </template>
                  <template v-else-if="filterOption === 'rejected'">
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      DATE
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      REJECTED REASON
                    </th>
                  </template>
                  <template v-else>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      COMMENTS
                    </th>
                  </template>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    ACTION
                  </th>
                </tr>
              </thead>

              <!-- <tr class="bg-gray-50"> -->

              <tbody>
                <template v-for="(item, index) in payments" :key="index">
                  <tr
                    :class="{
                      'bg-red-500':
                        checkValue(item.pricing.amount_invoiced, item.pricing.amount_payable) ||
                        checkTax(item.pricing.amount_payable, item.pricing.withholding_tax)
                    }"
                  >
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {{ item.page_number }} / {{ item.page_line }}
                    </td>
                    <td
                      :class="
                        'px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900' +
                        map_confidence(item.ocr_confidence.invoice_ref)
                      "
                    >
                      <span
                        class="px-2"
                        v-if="editablePayment == null || editablePayment.remittance_line_id !== item.remittance_line_id"
                        >{{ item.invoice_ref }}</span
                      >
                      <v-select
                        v-else
                        placeholder="Select New Invoice No"
                        :options="newInvoicesRefs"
                        v-model="editablePayment.invoice_ref"
                      >
                        <template v-slot:no-options>No matching invoice</template>
                      </v-select>
                    </td>
                    <td
                      :class="
                        'px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900' +
                        map_confidence(item.ocr_confidence.insurer_claim_id)
                      "
                    >
                      <span
                        class="px-2"
                        v-if="editablePayment == null || editablePayment.remittance_line_id !== item.remittance_line_id"
                        >{{ item.insurer_claim_id }}</span
                      >
                      <input
                        v-else
                        v-model="editablePayment.insurer_claim_id"
                        class="custom-input wide-btn-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </td>
                    <td
                      :class="
                        'px-4 py-4 whitespace-nowrap text-sm text-gray-500' +
                        map_confidence(item.ocr_confidence.amount_invoiced)
                      "
                    >
                      <span
                        class="px-2"
                        v-if="editablePayment == null || editablePayment.remittance_line_id !== item.remittance_line_id"
                        >{{ item.pricing.amount_invoiced }}</span
                      >
                      <input
                        v-else
                        v-model="editablePayment.pricing.amount_invoiced"
                        class="custom-input custom-table-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </td>
                    <td
                      v-if="filterOption !== 'pended' && filterOption !== 'rejected'"
                      :class="
                        'insurer-logo px-4 py-4 whitespace-nowrap text-sm text-gray-500' +
                        map_confidence(item.ocr_confidence.amount_payable)
                      "
                    >
                      <span
                        class="px-2"
                        v-if="editablePayment == null || editablePayment.remittance_line_id !== item.remittance_line_id"
                        >{{ item.pricing.amount_payable }}</span
                      >
                      <input
                        v-else
                        v-model="editablePayment.pricing.amount_payable"
                        class="custom-input custom-table-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </td>
                    <td
                      v-if="filterOption !== 'pended' && filterOption !== 'rejected'"
                      :class="
                        'px-4 py-4 whitespace-nowrap text-sm text-gray-500' +
                        map_confidence(item.ocr_confidence.adjustment_amount)
                      "
                    >
                      <span
                        class="px-2"
                        v-if="editablePayment == null || editablePayment.remittance_line_id !== item.remittance_line_id"
                        >{{ item.pricing.adjustment_amount }}</span
                      >
                      <input
                        v-else
                        v-model="editablePayment.pricing.adjustment_amount"
                        class="custom-input custom-table-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </td>
                    <td
                      v-if="filterOption !== 'pended' && filterOption !== 'rejected'"
                      :class="
                        'px-4 py-4 whitespace-nowrap text-sm text-gray-500' +
                        map_confidence(item.ocr_confidence.withholding_tax)
                      "
                    >
                      <span
                        class="px-2"
                        v-if="editablePayment == null || editablePayment.remittance_line_id !== item.remittance_line_id"
                        >{{ item.pricing.withholding_tax }}</span
                      >
                      <input
                        v-else
                        v-model="editablePayment.pricing.withholding_tax"
                        class="custom-input custom-table-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </td>

                    <template v-if="filterOption === 'pended'">
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white">
                        <span>{{ item.pended?.date }}</span>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white">
                        <span>{{ item.pended?.reason }}</span>
                      </td>
                    </template>
                    <template v-else-if="filterOption === 'rejected'">
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white">
                        <span>{{ item.rejected?.date }}</span>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white">
                        <span>{{ item.rejected?.reason }}</span>
                      </td>
                    </template>
                    <template v-else>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white">
                        <span>{{ item.comments }}</span>
                      </td>
                    </template>

                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <button
                        v-if="editablePayment == null"
                        @click="openEditMode(item)"
                        type="button"
                        class="inline-flex items-center px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        EDIT
                      </button>
                      <button
                        v-else
                        @click="updateInsurerPayment(item)"
                        type="button"
                        class="inline-flex items-center px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        SAVE
                      </button>
                      <!-- ALLOCATE BUTTON SHOULDN'T BE USED ANYMORE -->
                      <button
                        v-if="
                          parseFloat(item.pricing.amount_payable) < parseFloat(item.pricing.amount_invoiced) &&
                          filterOption !== 'external'
                        "
                        :disabled="true"
                        @click="openAllocateModal(item)"
                        type="button"
                        class="inline-flex items-center px-2.5 py-1.5 ml-2 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        ALLOCATE
                      </button>
                      <button
                        v-if="filterOption == 'not_matched' && item.state?.state_id == null"
                        @click="markAsExternal(item)"
                        type="button"
                        class="inline-flex items-center px-2.5 py-1.5 ml-2 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        MARK AS EXTERNAL
                      </button>
                      <button
                        v-if="filterOption == 'not_matched' && item.state?.state_id"
                        @click="markAsNonExternal(item)"
                        type="button"
                        class="inline-flex items-center px-2.5 py-1.5 ml-2 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        MARK AS NON-EXTERNAL
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <AllocateModal
      v-if="allocateModal"
      :preselected-invoice-id="selectedPaymentId"
      :external="external"
      :selected-consultant="selectedConsultant"
      :file-name="fileName"
      @close-modal="closeAllocateModal()"
    />
    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import { OpenAPIJSON } from 'api';
import { userStore } from '@/store/user';
import AllocateModal from '@/components/modal/AllocateModal.vue';
import SpinnerLoader from '../../components/misc/SpinnerLoader.vue';
import vSelect from 'vue-select';
import BigNumber from 'bignumber.js';
import { ToastComponent } from 'ui';

export default {
  name: 'PaymentsFinance',
  components: {
    AllocateModal,
    SpinnerLoader,
    vSelect,
    ToastComponent
  },
  props: {
    selectedConsultant: Object,
    // The consultant file-id
    fileIdProp: { type: Number, required: true },
    fileNameProp: String,
    filterTypeProp: String,
    insurers: Array
  },
  emits: ['change-mode'],
  data() {
    return {
      openApi: new OpenAPIJSON(),
      smallScreen: false,
      filterDate: Date.now(),
      collapsed: false,
      medics: null,
      isMedic: false,
      username: '',
      hospitalId: null,
      payments: [],
      openedPayments: [],
      invoices: [],
      filterOption: 'ALL',
      newPaymentAdd: false,
      newPayment: {
        name: null,
        mrn: null,
        appointmentId: null,
        invoiceId: null,
        invoiceRef: '',
        invoiceDate: null,
        amountPayable: null,
        withholdingTax: null,
        adjustmentAmount: null,
        amountInvoiced: null,
        amountOutstanding: null,
        paidOn: null,
        amountPaid: null,
        insurerName: null,
        consultantName: null,
        fileId: null
      },
      editablePayment: null,
      paymentFor: null,
      invoiceIdFilter: null,
      fileName: null,
      unparsedResults: null,
      fileId: null,
      allocateModal: false,
      external: false,
      selectedPaymentId: null,
      newInvoicesRefs: [],
      isLoading: false
    };
  },
  watch: {
    'editablePayment.pricing.amount_payable': {
      handler: function (newValue, oldValue) {
        if (oldValue === null || isNaN(newValue)) return;
        if (newValue < 0) return (this.editablePayment.pricing.amount_payable = 0);

        newValue = parseFloat(newValue);
        if (this.editablePayment?.pricing?.withholding_tax) {
          this.editablePayment.pricing.withholding_tax = parseFloat(
            new BigNumber(newValue).times(0.2).toPrecision(4)
          ).toFixed(2);
        }
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    getInsurerName(insurerId) {
      let insurer = this.insurers.find(item => item.insurer_id === insurerId);
      return insurer ? insurer.insurer_name : '';
    },
    checkValue(item, item2) {
      if (parseFloat(item) < parseFloat(item2)) {
        return true;
      } else {
        return false;
      }
    },
    checkTax(item, item2) {
      let firstValue = new BigNumber(item).times(0.2).toPrecision(5);
      let secondValue = new BigNumber(item2).toPrecision(5);

      if (parseFloat(firstValue).toFixed(2) !== parseFloat(secondValue).toFixed(2)) {
        return true;
      } else {
        return false;
      }
    },
    map_confidence(clevel) {
      switch (clevel) {
        case 'high':
          return ' confidence-high';
        case 'medium':
          return ' confidence-medium';
        case 'low':
          return ' confidence-low';
        case 'unknown':
          return '';
        case 'undefined':
          return '';
        case 'manually_overridden':
          return ' confidence-manual';
      }
      return '';
    },
    handleModeChange() {
      this.$emit('change-mode', 'paymentAndTaxFiles');
    },

    async list_remittances() {
      if (!this.selectedConsultant?.consultant_id && !this.selectedConsultant?.person_id)
        return this.$toast.error('No Consultant selected!');

      try {
        const state = this.filterOption === 'all' ? null : [this.filterOption];
        const { items } = await this.openApi.claim_statement_file_search(
          this.selectedConsultant?.consultant_id ?? null,
          null,
          this.selectedConsultant?.person_id ?? null,
          this.fileName,
          null,
          null,
          null,
          null,
          null,
          state
        );
        this.payments = items?.sort((a, b) => a.page_number - b.page_number) ?? [];
      } catch (error) {
        this.$refs.toast.showToast(
          'Something went wrong getting data. Please try again later.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
      }
    },
    async init() {
      this.filterOption = this.filterTypeProp;
      this.fileName = this.fileNameProp;
      this.isMedic = userStore.getters.user?.is_medic;
      this.username = userStore.getters.user?.username;
      this.hospitalId = userStore.getters.user?.hospital_id;
      this.list_remittances();
    },
    toogleOpenPayments(index) {
      this.openedPayments.some(item => item == index)
        ? (this.openedPayments = this.openedPayments.filter(item => item != index))
        : this.openedPayments.push(index);
    },
    async list_invoices_and_remittances_by_invoiceRef() {
      const consultant = this.getConsultant();
      if (consultant && !this.newInvoicesRefs?.length) {
        try {
          const { items } = await this.openApi.invoices_remittances_search({
            consultant: consultant
          });
          this.newInvoicesRefs = items.map(item => item.invoice_ref);
        } catch (error) {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        }
      }
    },
    async openEditMode(item) {
      this.isLoading = true;
      await this.list_invoices_and_remittances_by_invoiceRef();
      this.editablePayment = JSON.parse(JSON.stringify(item));

      this.isLoading = false;
    },
    async updateInsurerPayment(item) {
      let fileChanged = false;
      const edited = JSON.parse(JSON.stringify(item));

      if (edited) {
        if (edited.pricing?.amount_invoiced !== this.editablePayment.pricing?.amount_invoiced) {
          edited.pricing = {
            amount_invoiced: this.editablePayment.pricing.amount_invoiced,
            amount_payable: this.editablePayment.pricing.amount_payable,
            adjustment_amount: this.editablePayment.pricing.adjustment_amount,
            withholding_tax: this.editablePayment.pricing.withholding_tax
          };
          fileChanged = true;
        }
        if (edited.pricing?.amount_payable !== this.editablePayment.pricing?.amount_payable) {
          edited.pricing = {
            amount_invoiced: this.editablePayment.pricing.amount_invoiced,
            amount_payable: this.editablePayment.pricing.amount_payable,
            adjustment_amount: this.editablePayment.pricing.adjustment_amount,
            withholding_tax: this.editablePayment.pricing.withholding_tax
          };
          fileChanged = true;
        }
        if (edited.pricing?.withholding_tax !== this.editablePayment.pricing?.withholding_tax) {
          edited.pricing = {
            amount_invoiced: this.editablePayment.pricing.amount_invoiced,
            amount_payable: this.editablePayment.pricing.amount_payable,
            adjustment_amount: this.editablePayment.pricing.adjustment_amount,
            withholding_tax: this.editablePayment.pricing.withholding_tax
          };
          fileChanged = true;
        }
        if (edited.insurer_claim_id !== this.editablePayment?.insurer_claim_id) {
          edited.insurer_claim_id = this.editablePayment.insurer_claim_id;
          fileChanged = true;
        }
        if (edited.invoice_ref !== this.editablePayment?.invoice_ref) {
          edited.invoice_ref = this.editablePayment.invoice_ref;
          fileChanged = true;
        }

        if (fileChanged) {
          try {
            await this.openApi.claim_statement_update(edited);
            const payment = this.payments.find(item => item.remittance_line_id === edited.remittance_line_id);
            if (payment) {
              payment.pricing = edited.pricing;
              payment.insurer_claim_id = edited.insurer_claim_id;
              payment.invoice_ref = edited.invoice_ref;
            }
          } catch (error) {
            this.$refs.toast.showToast(
              'Something went wrong updating the file. Please try again later.',
              'fa-solid fa-triangle-exclamation',
              'error'
            );
          }
        }
      }
      this.editablePayment = null;
    },
    unparse() {
      let cvsData = [];

      this.payments.forEach(item => {
        let parseItem = {
          'Invoice No': item.invoice_ref,
          'Insurer Claim Ref': item.insurer_claim_id,
          'Insurer Name': this.getInsurerName(item.insurer.id),
          'Amount Invoiced': item.pricing.amount_invoiced,
          'Amount Payable': item.pricing.amount_payable,
          'Adjustment Amount': item.pricing.adjustment_amount,
          'Withholding Tax': item.pricing.withholding_tax
        };

        cvsData.push(parseItem);
      });

      this.unparsedResults = this.$papa.unparse(cvsData, {
        delimiter: ','
      });
      this.$papa.download(this.unparsedResults, 'CSV-Payments-' + Math.floor(Date.now() / 1000));
    },

    formatDate(data) {
      let date = new Date(data);
      return date.toLocaleDateString('es-CL');
    },

    openAllocateModal(item) {
      this.selectedPaymentId = item.invoice_ref;
      this.external = false;
      this.allocateModal = true;
    },
    openExternalModal() {
      this.external = true;
      this.allocateModal = true;
      this.selectedPaymentId = null;
    },
    closeAllocateModal() {
      this.external = false;
      this.allocateModal = false;
      this.selectedPaymentId = null;
    },
    async markAsExternal(item) {
      const edited = JSON.parse(JSON.stringify(item));
      edited.state = {
        state_id: 'external_invoice',
        state_name: 'External Invoice'
      };

      try {
        await this.openApi.claim_statement_update(edited);
        const payment = this.payments.find(item => item.remittance_line_id === edited.remittance_line_id);
        if (payment) payment.state = edited.state;
      } catch (error) {
        this.$refs.toast.showToast(
          'Something went wrong updating the file. Please try again later.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
      }
    },
    async markAsNonExternal(item) {
      const edited = JSON.parse(JSON.stringify(item));
      edited.state = null;

      try {
        await this.openApi.claim_statement_update(edited);
        const payment = this.payments.find(item => item.remittance_line_id === edited.remittance_line_id);
        if (payment) payment.state = edited.state;
      } catch (error) {
        this.$refs.toast.showToast(
          'Something went wrong updating the file. Please try again later.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
      }
    },
    getConsultant() {
      if (this.selectedConsultant?.consultant_id) {
        return {
          id: this.selectedConsultant?.consultant_id,
          type: 'id'
        };
      } else if (this.selectedConsultant?.person_id) {
        return {
          id: this.selectedConsultant?.person_id,
          type: 'personid'
        };
      } else {
        return null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 30px;
  font-size: 36px;
  font-weight: 600;
}

.notes {
  float: left;
  width: 250px;
}

.custom-table-input {
  width: 80px;
}

.add-claim-section {
  float: right;
  margin-left: 15px;
}

.search-section {
  float: right;
  width: 370px;
  margin-right: 30px;
}

.download-section {
  float: right;
}
.size-icons-section {
  float: left;
}

.size-icon {
  margin-right: 10px;
  font-size: 24px;
  color: #6c757d;
  cursor: pointer;
  transition: all 0.25s;

  &:hover {
    opacity: 0.5;
  }
}

.size-icon-active {
  color: #0081cd;
}

.subtitle {
  font-size: 28px;
  font-weight: 600;
}
.invoice-input {
  overflow: unset;
}

.confidence-high {
  background-color: white;
}

.confidence-medium {
  background-color: yellow;
}

.confidence-low {
  background-color: lightcoral;
}

.confidence-manual {
  background-color: lightgrey;
}
.dropdown-width {
  min-width: 200px;
}
.wide-btn-input {
  min-width: 100px;
}
</style>
