<template>
  <SecondMenuProfile v-model="collapsed" />

  <div class="main-content-full">
    <div class="claim-title medo-secondary">Edit Profile</div>

    <div class="col-12 mt-2">
      <div class="col-4 pr-5">
        <div class="flex justify-between">
          <label for="email" class="block text-sm font-medium text-gray-700">Password:</label>
        </div>
        <div class="mt-1 pb-2">
          <button
            @click="resetPasswordStart = !resetPasswordStart"
            type="button"
            class="medo-bg-secondary inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Reset Password
          </button>
        </div>
        <div class="mt-2" v-if="resetPasswordStart">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700">Old Password:</label>
          </div>
          <input
            v-model="oldPassword"
            type="text"
            class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder=""
            aria-describedby="email-optional"
          />

          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700 mt-2">New Password:</label>
          </div>
          <input
            v-model="newPassword"
            type="text"
            class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder=""
            aria-describedby="email-optional"
          />
          <h5 @click="generateNewPassword" class="text-sm font-medium medo-secondary cursor-pointer">
            Generate Random Password
          </h5>

          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700 mt-2">Repeat New Password:</label>
          </div>
          <input
            v-model="repeatNewPassword"
            type="text"
            class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder=""
            aria-describedby="email-optional"
          />
          <h5 v-if="passwordsNotMatch" class="text-sm font-medium text-red-700 cursor-pointer">
            Passwords don't match!
          </h5>

          <button
            :disabled="passwordsNotMatch"
            @click="changePassword"
            type="button"
            class="medo-bg-secondary mt-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>

  <div style="clear: both"></div>

  <ToastComponent ref="toast"></ToastComponent>
</template>

<script>
import SecondMenuProfile from '@/components/navigation/SecondMenuProfile.vue';
import { ToastComponent } from 'ui';

import api from 'api/api';
import axios from 'axios';

export default {
  name: 'EditProfile',
  components: { SecondMenuProfile, ToastComponent },
  data() {
    return {
      collapsed: null,
      resetPasswordStart: false,
      oldPassword: '',
      newPassword: '',
      repeatNewPassword: '',
      me: null
    };
  },
  computed: {
    passwordsNotMatch() {
      if (this.repeatNewPassword == '' && this.newPassword == '') return false;
      return this.repeatNewPassword != this.newPassword;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      let response = await axios.get(import.meta.env.BASE_SERVER + 'me', {
        headers: {
          Authorization: localStorage.getItem('_token')
        }
      });

      this.me = response.data;
    },
    generateNewPassword() {
      var chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      var passwordLength = 12;
      var password = '';

      for (var i = 0; i <= passwordLength; i++) {
        var randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber + 1);
      }

      this.newPassword = password;
    },
    async changePassword() {
      const newPasswordObject = {
        hospital: { hospitalId: parseInt(this.me.hospital_id) },
        account: { loginId: parseInt(this.me.login_id) },
        password: this.newPassword,
        oldPassword: this.oldPassword
      };
      await api
        .setUserPasswordWithOldPassword(newPasswordObject)
        .then(res => {
          if (res.data.errors)
            this.$refs.toast.showToast(res.data.errors[0].message, 'fa-solid fa-triangle-exclamation', 'error');
          else {
            this.$refs.toast.showToast('Updated password succesfully!', 'fas fa-check', 'success');
            this.resetData();
          }
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });
    },
    resetData() {
      this.oldPassword = '';
      this.newPassword = '';
      this.repeatNewPassword = '';
      this.resetPasswordStart = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.claim-title {
  margin-bottom: 30px;
  font-size: 36px;
  font-weight: 600;
}
button:disabled {
  opacity: 0.5;
}
</style>
