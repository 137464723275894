<template>
  <div class="me-[65px]">
    <!-- Testing message box -->
    <div
      v-if="showTestingMessage"
      class="flex justify-center items-top bg-medosync-dark-red text-white w-3/4 mx-auto p-5 gap-4"
    >
      <div><i class="fas fa-warning text-3xl"></i></div>
      <div>
        <h5 class="font-bold">Important Notice: You're Trying to Access a Test Environment</h5>
        <p>
          It looks like you're trying to connect to our UAT (User Acceptance Testing) environment, which is used for
          testing and not for regular use. To continue with the live system, please use the correct link: Live
          Environment URL: {{ productionSiteUrl }}
        </p>
      </div>
      <div><i class="fas fa-close text-xl"></i></div>
    </div>

    <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 relative">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 class="mt-5 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
      </div>

      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div class="space-y-6">
            <div>
              <label for="email" class="block text-sm font-medium text-gray-700"> Email address </label>
              <div class="mt-1">
                <input
                  v-model="user.email"
                  type="email"
                  autocomplete="email"
                  data-test-id="username-input"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label for="password" class="block text-sm font-medium text-gray-700"> Password </label>
              <div class="mt-1 relative">
                <input
                  v-model="user.password"
                  :type="showPassword ? 'text' : 'password'"
                  autocomplete="current-password"
                  data-test-id="password-input"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <div
                  class="absolute flex justify-center items-center right-0 top-0 cursor-pointer w-5 mt-2.5 mr-2"
                  @mousedown="showPassword = true"
                  @mouseup="showPassword = false"
                  @mouseleave="showPassword = false"
                >
                  <i class="fas" :class="showPassword ? 'fa-eye' : 'fa-eye-slash'"></i>
                </div>
              </div>
            </div>

            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  v-model="rememberMe"
                  class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label for="remember-me" class="ml-2 block text-sm text-gray-900"> Remember me </label>
              </div>
            </div>

            <div>
              <button
                @click="login"
                type="submit"
                data-test-id="loginButton"
                class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                :disabled="isLoading"
              >
                <span v-if="isLoading">Signing in...</span>
                <span v-else>Sign in</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import axios from 'axios';
import { OpenAPIJSON } from 'api/openapi';
import { userStore } from '@/store/user';
import { hospitalConfigStore } from '@/store/config';
import { ToastComponent } from 'ui';

export default {
  name: 'AuthLogin',
  components: {
    ToastComponent
  },
  data() {
    return {
      openApi: new OpenAPIJSON(),
      user: {
        email: '',
        password: ''
      },
      rememberMe: false,
      isLoading: false,
      pingInterval: null,
      showTestingMessage: false,
      showPassword: false,
      COMPOSITE_HOSTS: [
        'localhost',
        'dev.hospital.medosync.com',
        'uat.hospital.medosync.com',
        'uat.beacon.medosync.com',
        'beacon.medosync.com'
      ]
    };
  },
  computed: {
    productionSiteUrl() {
      if (window.location.hostname === 'localhost') {
        return 'localhost';
      }
      const urlSubs = window.location.hostname.split('.');
      return urlSubs.slice(1).join('.');
    }
  },
  created() {
    const savedEmail = localStorage.getItem('rememberedEmail');
    if (savedEmail) {
      this.user.email = savedEmail;
      this.rememberMe = true;
    }
  },
  mounted() {
    window.addEventListener('keyup', this.handleKeyup);
    this.startPingInterval();
    this.checkTestEnvironment();
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.handleKeyup);
    this.stopPingInterval();
  },
  methods: {
    checkTestEnvironment() {
      const testingHosts = [
        'localhost',
        'uat.beacon.medosync.com',
        'dev.hospital.medosync.com',
        'uat.bshs.medosync.com',
        'dev.bshs.medosync.intern'
      ];
      this.showTestingMessage = testingHosts.includes(window.location.hostname);
    },
    startPingInterval() {
      this.pingInterval = setInterval(() => {
        this.ping();
      }, 5000);
    },
    stopPingInterval() {
      if (this.pingInterval) {
        clearInterval(this.pingInterval);
        this.pingInterval = null;
      }
    },
    async ping() {
      try {
        await this.openApi.ping();
      } catch (error) {
        this.handlePingError(error);
      }
    },
    handlePingError(error) {
      const message = axios.isAxiosError(error)
        ? 'Service connection failed cannot reach backend service'
        : 'Service connection failed';

      this.$refs.toast.showToast(message, 'fa-solid fa-triangle-exclamation', 'error');
      console.error(
        `SERVICE CONNECTION ERROR: OpenAPI service is not responding for user: ${
          this.user?.email
        }, with the following error: ${JSON.stringify(error)}`
      );
    },
    handleKeyup(e) {
      if (e.key === 'Enter') {
        this.login();
      }
    },
    validateForm() {
      this.user.email = this.user.email.trim();
      this.user.password = this.user.password.trim();

      if (this.user.email.length < 4) {
        this.showError('User name is too short');
        return false;
      }

      if (this.user.password.length < 4) {
        this.showError('Password is too short');
        return false;
      }

      return true;
    },
    showError(message) {
      localStorage.removeItem('_token');
      localStorage.removeItem('tokenExpiration');
      this.$refs.toast.showToast(message, 'fa-solid fa-triangle-exclamation', 'error');
      console.error(`LOGIN ERROR: ${message} for user: ${this.user?.email}`);
    },
    async login() {
      if (!this.validateForm() || this.isLoading) return;

      this.isLoading = true;

      try {
        console.info(`Logging in user: ${this.user?.email}`);

        const response = await this.openApi.login(this.user.email, this.user.password);
        localStorage._token = response.access_token;

        await Promise.all([userStore.dispatch('getUserInfo'), hospitalConfigStore.dispatch('getConfig')]);

        this.setTokenExpiration();
        await this.setPreferences(JSON.parse(userStore.getters.user?.preferences));

        this.handleRememberMe();

        const redirectUrl = this.getRedirectUrl();
        localStorage.setItem('configsLoaded', 'true');

        await this.$router.push(redirectUrl);
      } catch (error) {
        this.handleLoginError(error);
      } finally {
        this.isLoading = false;
      }
    },
    setTokenExpiration() {
      const expiration = new Date();
      expiration.setHours(24, 0, 0, 0);
      localStorage.setItem('tokenExpiration', expiration.toISOString());
      localStorage.setItem('selectedConsultantReport', '');
    },
    handleRememberMe() {
      if (this.rememberMe) {
        localStorage.setItem('rememberedEmail', this.user.email);
      } else {
        localStorage.removeItem('rememberedEmail');
      }
    },
    getRedirectUrl() {
      const hasAccess = hospitalConfigStore.getters.has_product_access;
      const hasNavigator = hospitalConfigStore.getters.has_product_navigator;
      const hostname = window.location.hostname;

      if (hasAccess && !hasNavigator) {
        return '/access/admission';
      }
      if (hasNavigator) {
        // if (hospitalConfigStore.state.config.hl7dialect === 'bon_secours_cork') {
        //   return '/navigatorccp';
        // }
        // Check if current hostname is in the COMPOSITE_HOSTS list
        if (this.COMPOSITE_HOSTS.includes(hostname)) {
          return '/navigator/compositeclaims';
        } else {
          return '/navigator/claims';
        }
      }
      return '/not-authorized';
    },
    handleLoginError(error) {
      if (Object.hasOwn(error, 'response') && Object.hasOwn(error.response, 'data')) {
        if (error.response?.data?.reason) {
          if (error.response.data.reason.startsWith('LOGIN-002')) {
            this.showError('Username is not known');
            return;
          }
          if (error.response.data.reason.startsWith('LOGIN-001')) {
            this.showError('Username or password is not known');
            return;
          }
        }
      }

      this.$refs.toast.showToast('Service connection error', 'fa-solid fa-triangle-exclamation', 'error');
      sessionStorage.clear();
      localStorage.removeItem('_token');
      localStorage.removeItem('tokenExpiration');
      console.error(
        `LOGIN ERROR: Login failed for user: ${this.user?.email}, with the following error: ${JSON.stringify(error)}`
      );
    },
    async setPreferences(data) {
      let preferences = {
        tableLayout: null,
        tagsLayout: null,
        menuLayout: null
      };

      if (Object.keys(data || {}).length > 0) {
        preferences = data;
      }

      if (!data?.tableLayout) {
        preferences.tableLayout = this.getDefaultTableLayout();
      }

      if (!data?.tagsLayout) {
        preferences.tagsLayout = await this.getTagList();
      }

      localStorage.preferences = JSON.stringify(preferences);
    },
    getDefaultTableLayout() {
      return [
        { name: 'Index', active: true, width: 35 },
        { name: 'Patient', active: true, width: 130 },
        { name: 'MRN', active: true, width: 100 },
        { name: 'AppID', active: true, width: 120 },
        { name: 'LOS', active: true, width: 60 },
        { name: 'Admitted', active: true, width: 100 },
        { name: 'Discharged', active: true, width: 100 },
        { name: 'Insurer', active: true, width: 130 },
        { name: 'Invoice Consultant', active: true, width: 140 },
        { name: 'Status', active: true, width: 130 },
        { name: 'Note', active: true, width: 90 },
        { name: 'Tag', active: true, width: 100 },
        { name: 'Menage', active: true, width: 120 },
        { name: 'Action', active: true, width: 100 }
      ];
    },
    async getTagList() {
      try {
        let response = await this.openApi.tagList();
        return response.hospital.map(item => ({
          ...item,
          active: true,
          color: '#00A7E1'
        }));
      } catch (error) {
        console.error('Error getting tags: ', JSON.stringify(error));
        return [];
      }
    }
  }
};
</script>
