<template>
  <div class="min-h-screen bg-white flex flex-col">
    <!-- Header -->
    <header class="h-20 w-full bg-[#181328] flex items-center px-10">
      <img src="@/assets/img/new-medosync-logo-white.svg" alt="Logo" class="w-10 h-[22px]" />
    </header>

    <!-- Main Content -->
    <main class="flex-grow flex mt-16 justify-center px-6">
      <div class="max-w-4xl w-full">
        <h1 class="font-['Instrument_Sans'] text-[38px] mb-6">Access Restricted</h1>

        <p class="font-['Instrument_Sans'] text-2xl mb-4">It looks like you don't have access to this service yet.</p>

        <p class="font-['Instrument_Sans'] text-2xl mt-12">
          Please
          <a href="/" class="underline cursor-pointer">login with another ID</a>
          or
          <a href="http://www.medosync.com" class="underline">visit our website</a>
          (www.medosync.com) to learn more.
        </p>
      </div>
    </main>

    <!-- Footer -->
    <footer class="h-20 w-full bg-[#181328] flex items-center">
      <p class="font-['Instrument_Sans'] text-white text-base px-10">Copyright © 2024 MedoSync | All Rights Reserved</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'AccessRestricted'
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@400;500;600;700&display=swap');
</style>
