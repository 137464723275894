<template>
  <div>
    <div class="row">
      <div class="col-4 s-view-title">Manage Staff</div>
      <div class="col-4 px-2 mt-1">
        <input
          v-model="searchString"
          data-test-id="search-staff-input"
          class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="Search by name or medical number"
        />
      </div>
      <div class="col-4 pl-2 mt-1">
        <div class="flex flex-row">
          <select
            v-model="filteredGroup"
            data-test-id="filter-group-list"
            class="custom-select block w-full mr-2 text-base border border-gray-300 shadow-sm focus:outline-none focus:ring-blue-300 focus:border-blue-300 sm:text-sm rounded-md"
          >
            <option :value="null">Filter Group</option>
            <option v-for="group in groups" :key="group.aclId" :value="group.aclGroupId">{{ group.name }}</option>
          </select>

          <button
            @click="
              filteredGroup = null;
              searchString = '';
            "
            type="button"
            class="medo-bg-secondary inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Reset
          </button>
        </div>
      </div>
    </div>

    <div class="col-8 mt-5 text-right">
      <router-link to="create-user">
        <button
          type="button"
          data-test-id="create-user-button"
          class="medo-bg-secondary inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Create User +
        </button>
      </router-link>
    </div>

    <div style="clear: both"></div>

    <div v-if="logins && groups && hospitals" class="flex flex-col pl-6 pr-6 mt-5 ml-2">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table v-if="logins" class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Group
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Hospital
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                <!-- Odd row -->
                <tr v-for="(login, index) in filteredMembers" :key="index" class="bg-white">
                  <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {{ login.userName }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ getAclGroup(login.aclGroupId) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ getHospitalName(login.hospitalId) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      v-if="login.confirmed == null"
                      @click="activateUser(login)"
                      type="button"
                      class="medo-bg-secondary btn-width inline-flex items-center justify-center py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Activate
                    </button>
                    <button
                      v-else
                      @click="deactivateUser(login)"
                      type="button"
                      class="medo-bg-secondary btn-width inline-flex items-center justify-center py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Deactivate
                    </button>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <router-link
                      :to="'/settings/edit-doctor/' + login.loginAccountId"
                      class="text-indigo-600 hover:text-indigo-900 medo-secondary"
                      >Edit</router-link
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ToastComponent ref="toast"></ToastComponent>
</template>

<script>
import api from 'api/api';
import { api_store } from '@/store';
//import SecondMenuUserManagement from '@/components/navigation/SecondMenuUserManagement'
import { ToastComponent } from 'ui';

export default {
  name: 'Doctors-dashboard',
  components: {
    //SecondMenuUserManagement,
    ToastComponent
  },
  data() {
    return {
      logins: null,
      searchString: '',
      groups: null,
      hospitals: null,
      filteredGroup: null
    };
  },
  computed: {
    filteredMembers() {
      if (this.searchString.toLowerCase() == '') {
        return this.filteredGroup == null
          ? this.logins
          : this.logins.filter(item => item.aclGroupId == this.filteredGroup);
      } else {
        return this.logins.filter(item => {
          if (item.userName.toLowerCase().includes(this.searchString.toLowerCase())) {
            if (this.filteredGroup == null) return item;
            if (item.aclGroupId == this.filteredGroup) return item;
          }
        });
      }
    }
  },
  methods: {
    async listMedics() {
      await api
        .listAclGroups()
        .then(res => {
          this.groups = res.data.data.listAclGroup.groups;
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });

      //parameter should be different for different logins
      await api
        .listHospitals(1)
        .then(res => {
          this.hospitals = res.data.data.listHospitals.hospital;
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });

      await api
        .listLogins()
        .then(res => {
          this.logins = res.data.data.listLogins.logins;
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });

      await api_store.cache
        .dispatch('LIST_MY_CONSULTANTS', {})
        .then(() => {})
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });
    },

    fetchDoctor(doctor) {
      this.doctor = doctor;
    },

    getAclGroup(aclGroupId) {
      let g = this.groups.filter(group => group.aclGroupId == aclGroupId);
      return g.length > 0 ? g[0].name : '';
    },

    getHospitalName(hospitalId) {
      let h = this.hospitals.filter(hospital => hospital.hospitalId == hospitalId);
      return h.length > 0 ? h[0].name : '';
    },

    async deactivateUser(login) {
      await api
        .deactivateUser(
          login.hospitalId,
          this.getHospitalGroupId(login.hospitalId),
          login.emailAddress,
          login.loginAccountId
        )
        .then(() => {})
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });

      this.listMedics();
    },

    async activateUser(login) {
      await api
        .activateUser(
          login.hospitalId,
          this.getHospitalGroupId(login.hospitalId),
          login.emailAddress,
          login.loginAccountId
        )
        .then(() => {})
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });

      this.listMedics();
    },

    getHospitalGroupId(hospitalId) {
      return this.hospitals.find(hospital => hospital.hospitalId == hospitalId).hospitalGroupId;
    }
  },
  created() {},
  mounted() {
    this.listMedics();
  }
};
</script>

<style lang="scss" scoped>
.optional-nav {
  position: fixed;
  top: 0;
  left: 65px;
  height: 100vh;
  overflow: auto;
  background: #ededed;
}

.claim-title {
  font-size: 26px;
  font-weight: 600;
}

.custom-input {
  height: 30px;
  padding-left: 8px;
  line-height: 40px;
  border: 1px solid #dedede;
}

.btn-width {
  width: 84px;
}

.custom-select {
  background: #fff;
}
</style>
