// stores/filterStore.js
import { ref } from 'vue';

export const useFilterStore = () => {
  const filterState = ref({
    consultantId: null,
    claimStatus: null,
    claimTag: null,
    searchTerm: '',
    dateFilterType: null,
    dateFilterStart: null,
    dateFilterEnd: null,
    currentPage: 1,
    activeFiltersCount: 0
  });

  const setFilters = filters => {
    filterState.value = { ...filterState.value, ...filters };
  };

  const resetFilters = () => {
    filterState.value = {
      consultantId: null,
      claimStatus: null,
      claimTag: null,
      searchTerm: '',
      dateFilterType: null,
      dateFilterStart: null,
      dateFilterEnd: null,
      currentPage: 1,
      activeFiltersCount: 0
    };
  };

  return {
    filterState,
    setFilters,
    resetFilters
  };
};

// Create a single instance to share across components
export const filterStore = useFilterStore();
