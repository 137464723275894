<template>
  <Modal v-if="showManageModalClaims" @close-modal="closeModal()">
    <template v-slot:header>
      <div class="flex row">
        <div v-if="warningIcon" class="text-center mr-6 mt-1">
          <i class="fa-solid fa-circle-exclamation text-5xl"></i>
        </div>
        <div class="col-span-3">
          <h3 class="text-lg text-left leading-6 font-medium medo-grey-600 my-2 mr-2">
            {{ title }}
          </h3>
          <p class="modal-paragraph mt-4">
            <span>{{ text }}</span>
            <span class="block pt-4">{{ subtext }}</span>
          </p>
        </div>
      </div>
      <div class="modalDelete mt-4">
        <div class="ml-auto flex items-center">
          <button
            v-html="secondaryButtonTextSanitized"
            class="modal-button-canceled-reverse py-2 px-4 bg-transparent"
            @click="secondaryButton()"
          ></button>
          <button
            v-html="primaryButtonTextSanitized"
            class="modal-button py-2 px-18 whitespace-nowrap bg-medosync-blue"
            @click="primaryButton()"
          ></button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import DOMPurify from 'dompurify';
import Modal from '@/components/modal/Modal';

export default {
  name: 'DefaultModal',
  props: {
    showManageModal: {
      type: Boolean,
      default: false
    },
    warningIcon: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    subtext: {
      type: String,
      default: ''
    },
    secondaryButtonText: {
      type: String,
      default: 'Cancel'
    },
    primaryButtonText: {
      type: String,
      default: 'Save Changes'
    }
  },
  emits: ['close-modal', 'secondary-changes', 'primary-changes'],
  components: {
    Modal
  },
  data() {
    return {
      showManageModalClaims: false
    };
  },
  computed: {
    primaryButtonTextSanitized() {
      return DOMPurify.sanitize(this.primaryButtonText);
    },
    secondaryButtonTextSanitized() {
      return DOMPurify.sanitize(this.secondaryButtonText);
    }
  },
  watch: {
    showManageModal(val) {
      this.showManageModalClaims = val;
    }
  },
  mounted() {
    this.showManageModalClaims = this.showManageModal;
  },
  methods: {
    closeModal() {
      this.showManageModalClaims = false;
      this.$emit('close-modal', false);
    },
    secondaryButton() {
      this.$emit('secondary-changes');
    },
    primaryButton() {
      this.$emit('primary-changes');
    }
  }
};
</script>

<style scoped>
.modal-button {
  border: 2px solid #00a7e1;
  border-radius: 50px;
  width: 230px;
  height: 48px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
  white-space: nowrap;
  display: inline-block;
  padding: 0 20px;
}
.modal-button-canceled-reverse {
  border: 2px solid #00a7e1;
  border-radius: 50px;
  width: 230px;
  height: 48px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
  margin: 10px;
}
.modal-paragraph {
  font-size: 14px;
}
</style>
