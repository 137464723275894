<template>
  <div v-click-outside="closeDropdown">
    <button
      class="inline-flex items-center action-dropdown-width rounded-lg text-xs font-medium shadow-sm px-3 py-2"
      :class="[!isEnabled ? 'cursor-default justify-center' : 'justify-between', btnColorClass]"
      @click="toggleDropdown"
    >
      {{ stateFormattedText }}
      <i v-if="isEnabled" class="fas fa-chevron-down text-sm ml-2"></i>
    </button>
    <div :class="{ hidden: !dropdownOpen }" class="absolute flex flex-column w-full justify-end bg-white">
      <ul class="rounded border border-black py-2 text-sm text-gray-700">
        <li
          v-if="claim.claim_state != 'draft'"
          @click="showManageModalRevert = true"
          class="block cursor-pointer px-2 py-1 hover:bg-gray-100"
        >
          <i class="fas fa-clock-rotate-left text-sm mr-1"></i>
          Revert to Draft
        </li>
        <li
          v-if="claim.claim_state != 'manual_rejected'"
          @click="showRejectClaimModal = true"
          class="block cursor-pointer px-2 py-1 hover:bg-gray-100"
        >
          <i class="fas fa-ban text-sm mr-1"></i>
          Reject Claim
        </li>
      </ul>
    </div>
  </div>
  <DefaultModal
    v-if="showRejectClaimModal"
    :showManageModal="true"
    :warningIcon="true"
    title="Reject Claim?"
    text="Are you sure you want to reject this claim?"
    subtext="Please choose the button 'cancel' to return to the claim."
    secondaryButtonText="Reject Claim"
    primaryButtonText="Cancel"
    @secondary-changes="manuallyRejectClaim"
    @primary-changes="showRejectClaimModal = false"
    @close-modal="showRejectClaimModal = false"
  ></DefaultModal>
  <DefaultModal
    v-if="showManageModalRevert"
    :showManageModal="true"
    :warningIcon="true"
    title="Revert Claim to Draft?"
    text="Are you sure want to revert this claim to Draft?"
    subtext='Please choose the button "Cancel" to return to the claim.'
    secondaryButtonText="Revert To Draft"
    primaryButtonText="Cancel"
    @secondary-changes="revertToDraft"
    @primary-changes="showManageModalRevert = false"
    @close-modal="showManageModalRevert = false"
  ></DefaultModal>
  <ToastComponent ref="toast"></ToastComponent>
</template>

<script>
import vClickOutside from 'click-outside-vue3';
import { ToastComponent } from 'ui';
import DefaultModal from '@/components/claim-forms/components/DefaultModal.vue';
import { OpenAPIJSON } from 'api/openapi';

export default {
  components: { ToastComponent, DefaultModal },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    claim: { required: true }
  },
  emits: ['init-claim'],
  data() {
    return {
      dropdownOpen: false,
      showManageModalRevert: false,
      showRejectClaimModal: false
    };
  },
  computed: {
    stateFormattedText() {
      switch (this.claim.claim_state) {
        case 'pended_with_hospital':
          return 'Pended';
        case 'rejected':
          return 'Rejected';
        case 'part_paid':
          return 'Part-Paid';
        case 'paid_in_full':
          return 'Paid in full';
        default:
          return this.claim.claim_state_str;
      }
    },
    isEnabled() {
      return (
        this.stateFormattedText != 'Pended' &&
        this.stateFormattedText != 'Rejected' &&
        this.stateFormattedText != 'Part-Paid' &&
        this.stateFormattedText != 'Paid in full'
      );
    },
    btnColorClass() {
      switch (this.stateFormattedText) {
        case 'Pended':
          return 'bg-yellow text-black';
        case 'Part-Paid':
          return 'bg-yellow text-black';
        case 'Rejected':
          return 'bg-red text-white';
        case 'Paid in full':
          return 'bg-green text-white';
        default:
          return 'medo-bg-third text-white';
      }
    }
  },
  methods: {
    toggleDropdown() {
      if (!this.isEnabled) return;
      this.dropdownOpen = !this.dropdownOpen;
    },
    closeDropdown() {
      this.dropdownOpen = false;
    },
    async revertToDraft() {
      const oapi = new OpenAPIJSON();

      const isComposite = this.claim.claim_assoc.assoc_type === 'composite';

      const req = {
        claim_ref: {
          type: 'id',
          id: isComposite ? this.claim.claim_composite_id : this.claim.claim_consultant_id
        },
        consultant: {
          type: 'id',
          id: this.claim.claim_consultant.consultant_id
        },
        reason: 'Request through UI',
        target_state: 'revert_to_draft'
      };

      try {
        const apiMethod = isComposite ? 'composite_state_change' : 'consultant_state_change';
        const res = await oapi[apiMethod](req);

        if (res) {
          this.$refs.toast.showToast(
            `Claim ${this.claim.hospital_appointment_id} marked as Draft`,
            'fas fa-check',
            'success'
          );

          setTimeout(() => {
            this.$emit('init-claim');
          }, 1000);
        } else {
          this.$refs.toast.showToast(res.data.errors[0].message, 'fa-solid fa-triangle-exclamation', 'error');
        }
      } catch (error) {
        this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
      } finally {
        this.dropdownOpen = false;
        this.showManageModalRevert = false;
      }
    },

    async manuallyRejectClaim() {
      const oapi = new OpenAPIJSON();

      const isComposite = this.claim.claim_assoc.assoc_type === 'composite';

      const req = {
        claim_ref: {
          type: 'id',
          id: isComposite ? this.claim.claim_composite_id : this.claim.claim_consultant_id
        },
        consultant: {
          type: 'id',
          id: this.claim.claim_consultant.consultant_id
        },
        reason: 'Request through UI',
        target_state: isComposite ? 'needs_more_work' : 'admin_rejected'
      };

      try {
        const apiMethod = isComposite ? 'composite_state_change' : 'consultant_state_change';
        const res = await oapi[apiMethod](req);

        if (res) {
          this.$refs.toast.showToast(
            `Claim ${this.claim.hospital_appointment_id} marked as rejected`,
            'fas fa-check',
            'success'
          );

          setTimeout(() => {
            this.$emit('init-claim');
          }, 1000);
        } else {
          this.$refs.toast.showToast(res.data.errors[0].message, 'fa-solid fa-triangle-exclamation', 'error');
        }
      } catch (error) {
        this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
      } finally {
        this.showRejectClaimModal = false;
        this.dropdownOpen = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.action-dropdown-width {
  width: 150px;
}
li:hover {
  background-color: #7dcff6;
}
.bg-yellow {
  background-color: #ffa701;
}
.bg-red {
  background-color: #ae0101;
}
.bg-green {
  background-color: #006600;
}
</style>
