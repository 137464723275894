<script setup lang="ts">
import { ref } from 'vue';
import { OpenAPIJSON } from 'api/openapi';
import Modal from '@/components/modal/Modal.vue';
import { ToastComponent } from 'ui';

const props = defineProps({
  procedureCode: {
    type: Object,
    default: null
  },
  insurerId: {
    type: Number,
    default: null
  }
});

const emit = defineEmits(['close', 'updateProcedureCode']); // Added 'updateProcedureCode' emit

const toast = ref(null);
const newConsultantCost = ref<Number | null>(null);

const set_contract_procedure_code = async () => {
  try {
    if (!newConsultantCost.value) throw new Error('Please enter a new consultant cost');

    const price_list = [
      {
        proc_code: props.procedureCode.procedure_code,
        price: {
          description: props.procedureCode.procedure_code_description,
          participating_consultant_cost: newConsultantCost.value,
          participating_anaesthetist_cost: props.procedureCode.participating_anaesthetist_cost
        }
      }
    ];

    const oapi = new OpenAPIJSON();
    await oapi.setContractInsuranceProcedureCode(
      props.insurerId,
      null,
      props.procedureCode.insurer_contract_id,
      price_list
    );

    toast.value?.showToast('Consultant Cost updated successfully', 'fa-solid fa-check-circle', 'success');

    // Emit the updated procedure code with the new consultant cost
    emit('updateProcedureCode', {
      ...props.procedureCode,
      price: {
        ...props.procedureCode.price,
        participating_consultant_cost: newConsultantCost.value
      }
    });

    emit('close');
  } catch (error) {
    toast.value?.showToast(error.message, 'fa-solid fa-triangle-exclamation', 'error');
  }
};

const closeModal = () => {
  newConsultantCost.value = null;
  emit('close');
};
</script>

<template>
  <Modal @close-modal="closeModal">
    <template #header>
      <h3 class="text-2xl text-medosync-violet-highlight font-semibold">Edit Consultant Cost</h3>
    </template>
    <template #body>
      <div class="grid grid-cols-[1fr,_3fr] mt-6 gap-10 mb-10">
        <div>
          <h5 class="font-bold mb-1">Procedure Code:</h5>
          <p>{{ procedureCode.procedure_code }}</p>
        </div>
        <div>
          <h5 class="font-bold mb-1">Procedure Description:</h5>
          <p>{{ procedureCode.procedure_code_description }}</p>
        </div>
        <div>
          <h5 class="font-bold mb-1">Current Consultant Cost</h5>
          <p>{{ procedureCode.price.participating_consultant_cost.toLocaleString() }} €</p>
        </div>
        <div>
          <h5 class="font-bold mb-1">New Consultant Cost in € *</h5>
          <input
            type="number"
            data-test-id="consultant-cost-input"
            v-model="newConsultantCost"
            min="0"
            class="rounded mb-2"
          />
          <p class="text-xs">
            * Changes on the Consultant Cost will affect all Claims to which this insurance contract applies.
          </p>
        </div>
      </div>

      <div class="flex justify-between items-center">
        <button
          type="button"
          data-test-id="cancel-consultant-cost-edit-btn"
          class="flex justify-center items-center bg-[#CBD5E0] shadow rounded-full py-3 px-8"
          @click="closeModal"
        >
          <span class="block">Cancel</span>
        </button>

        <button
          type="button"
          data-test-id="save-cost-for-app-id-btn"
          class="flex justify-center items-center bg-medosync-blue shadow rounded-full py-3 px-8"
          @click="set_contract_procedure_code"
        >
          <span class="block">Save Cost</span>
        </button>
      </div>
    </template>
  </Modal>
  <ToastComponent ref="toast"></ToastComponent>
</template>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
