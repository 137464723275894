<template>
  <div class="file-uploader">
    <br />
    <div
      v-if="dropZone"
      class="dropzone cursor-pointer"
      @dragover.prevent
      @drop="handleDrop"
      @click="triggerFileUpload"
    >
      <span class="block text-center mt-7 text-base">Drag and drop a file here or click</span>
      <div class="flex w-full justify-center mt-5 mb-7">
        <i class="fas fa-cloud-upload-alt fa-4x" style="color: #00a7e1"></i>
      </div>
    </div>
    <br />

    <div class="dropUpload" v-if="files.length > 0">
      <ul class="w-full text-base">
        <h1 v-if="isLoading" class="mb-10">
          <span>Your files are being uploaded.</span>
          <span class="block">Please wait..</span>
        </h1>
        <h1 v-if="isFinished && isError" class="mb-10">
          <span>Some files failed to upload.</span>
          <span class="block">Please try again.</span>
        </h1>
        <h1 v-else-if="isFinished && !isError" class="mb-10">Your files are uploaded successfully.</h1>
        <h1 v-else-if="isFinished && files.every(file => !file.status)" class="mb-10">
          <span>Files failed to upload.</span>
          <span class="block">Please try again.</span>
        </h1>
        <h1 v-else-if="isFinished && files.some(file => file.status)" class="mb-10">
          <span>Some files failed to upload.</span>
          <span class="block">Please try again.</span>
        </h1>

        <template v-if="isFinished">
          <li class="flex items-center gap-1 text-base mb-2" v-for="(file, index) in files" :key="index">
            <i v-if="file.status" class="fa-solid fa-check text-lg" style="color: #006600"></i>
            <i v-if="!file.status" class="fa-solid fa-xmark text-lg" style="color: #ad0000"></i>
            {{ file.name }}
          </li>
        </template>
        <template v-else-if="isLoading">
          <div class="flex gap-7 items-center">
            <div class="spinner"></div>
            <div>
              <li class="flex items-center gap-1 text-base mb-2" v-for="(file, index) in files" :key="index">
                {{ file.name }}
              </li>
            </div>
          </div>
        </template>
        <template v-else>
          <li
            class="flex items-center justify-between gap-1 text-base mb-2 py-1 pl-2 pr-4 list-files"
            v-for="(file, index) in files"
            :key="index"
          >
            <span>{{ file.name }}</span>
            <i
              class="fa-regular fa-trash-can text-lg cursor-pointer"
              style="color: #000000"
              @click="deleteFile(index)"
            ></i>
          </li>
        </template>
        <br />
      </ul>
    </div>
    <div class="ml-auto flex items-center">
      <button v-if="!isFinished" class="file-upload-button-canceled py-2 px-4" @click="closeFunction">Cancel</button>
      <button
        v-if="!isFinished"
        class="file-upload-button py-2 px-4"
        :class="{ hidden: upload }"
        @click="() => $refs.fileInput.click()"
      >
        Select File
        <input type="file" multiple ref="fileInput" style="display: none" @change="handleFiles($event.target.files)" />
      </button>
      <button v-if="upload && !isFinished" class="file-upload-button py-2 px-4" @click="this.uploadFile()">
        Upload
      </button>
      <button v-if="isFinished" class="file-upload-button-canceled py-2 px-4" @click="this.addAnotherFile()">
        Add another file
      </button>
      <button v-if="isFinished" class="file-upload-button py-2 px-4" @click="this.closeFunction()">Close</button>
    </div>
    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import { OpenAPIJSON } from 'api';
import { AssocType } from 'enums';
import { ToastComponent } from 'ui';

export default {
  components: { ToastComponent },
  data() {
    return {
      files: [],
      upload: false,
      isLoading: false,
      dropZone: true,
      isFinished: false,
      isError: false,
      openApi: new OpenAPIJSON()
    };
  },
  props: {
    claimDetails: null,
    appointmentId: null
  },
  methods: {
    addAnotherFile() {
      this.$emit('refetch-files');
      this.resetData();
    },
    closeFunction() {
      this.$emit('close-upload');
      this.$emit('refetch-files');
    },
    resetData() {
      this.files = [];
      this.status = [];
      this.isError = false;
      this.upload = false;
      this.isLoading = false;
      this.dropZone = true;
      this.isFinished = false;
    },
    handleDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;

      if (this.files.length > 0) {
        this.files = [...this.files, ...files];
      } else {
        this.files = files;
        this.upload = true;
      }
    },
    triggerFileUpload() {
      this.$refs.fileInput.click();
    },
    handleFiles(files) {
      this.files = [...this.files, ...files];
      this.upload = true;
    },
    deleteFile(index) {
      this.files = Array.from(this.files).filter((file, i) => i !== index);
    },
    async uploadFile() {
      if (this.files.length === 0) {
        this.upload = false;
        return;
      }
      this.dropZone = false;
      this.isLoading = true;
      const filesToUpload = [];

      this.files.forEach(file => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result;
          filesToUpload.push({
            file_name: file.name,
            data: base64,
            mime_type: file.type,
            file_category: 'supporting_document'
          });
        };
        reader.onloadend = () => {
          if (this.files.length === filesToUpload.length) {
            this.submitFile(filesToUpload);
          }
        };
      });
    },
    async submitFile(files) {
      try {
        if (files.length) {
          const uploadAssocInfo = this.uploadAssocInfo();
          const response = await this.openApi.file_add_by_id(uploadAssocInfo, files);
          this.files.forEach(file => {
            const fileExists = response.files.find(f => f.file_name === file.name);
            if (fileExists) {
              file.status = true;
            } else {
              file.status = false;
            }
          });
          this.isLoading = false;
          this.isFinished = true;
        }
      } catch (err) {
        this.files.forEach(file => {
          file.status = false;
        });
        this.isError = true;
        this.isLoading = false;
        this.isFinished = true;
        console.log('ERROR UPLOADING FILES: ', err);
        this.$refs.toast.showToast(
          'Error uploading files. Please try again.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
      }
    },
    uploadAssocInfo() {
      if (this.claimDetails) {
        if (this.claimDetails?.claim_consultant_id) {
          return {
            assoc_type: AssocType.ConsultantClaim,
            type: 'id',
            id: this.claimDetails?.claim_consultant_id
          };
        } else {
          return {
            assoc_type: AssocType.CompositeClaim,
            type: 'id',
            id: this.claimDetails?.claim_composite_id
          };
        }
      } else if (this.appointmentId) {
        return {
          assoc_type: AssocType.Appointment,
          type: 'id',
          appointment_id: this.appointmentId
        };
      }
    }
  }
};
</script>

<style scoped>
.file-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropzone {
  border: 2px dashed #ccc;
  width: 100%;
  background-color: #f9f9f9;
}

.dropzone i {
  color: #ccc;
}

.file-icon {
  margin-right: 8px;
}

.list-files {
  background-color: #d9d9d9;
}

.file-upload-button {
  background: #00a7e1;
  border-radius: 50px;
  width: 120px;
  height: 45px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
  margin: 10px;
}

.file-upload-button-canceled {
  border: 2px solid #00a7e1;
  border-radius: 50px;
  height: 45px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
}

.file-upload-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;

  color: #000000;
}

.dropUpload {
  width: 100%;
}

.spinner {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top-color: #00a7e1;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
