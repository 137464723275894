<template>
  <div>
    <SecondMenuReporting v-model="collapsed" />

    <div class="main-content" :class="[collapsed ? 'full-width' : 'part-width']">
      <div class="title medo-secondary">Reporting</div>

      <div class="col-12 flex flex-row justify-end px-2">
        <div>
          <button
            @click="fetch_json_report_data"
            type="button"
            class="inline-flex items-center mx-2 px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            RUN REPORT
          </button>
          <button
            @click="fetch_pdf_report_data"
            type="button"
            class="inline-flex items-center mx-2 px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            DOWNLOAD PDF
          </button>
          <button
            @click="fetch_csv_report_data"
            type="button"
            class="inline-flex items-center mx-2 px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            DOWNLOAD CSV
          </button>
        </div>
      </div>

      <div class="col-12 mb-5">
        <div class="mt-3 flex justify-start">
          <div v-if="medics" class="col-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900 mr-5 mb-9">Filter by Consultant:</h3>
            <div class="px-2">
              <v-select
                label="username"
                placeholder="Select Consultant"
                :options="medics"
                v-model="selectedConsultant"
              ></v-select>
            </div>
          </div>

          <div class="col-6 flex flex-col px-2">
            <h3 class="text-lg leading-6 font-medium text-gray-900 mr-5 mb-9">Filter by Insurer:</h3>
            <div class="px-2">
              <v-select
                label="name"
                placeholder="Select Insurer"
                :options="insurers"
                v-model="selectedInsurer"
              ></v-select>
            </div>
          </div>

          <div class="col-4 flex flex-col px-2">
            <h3 class="text-lg leading-6 font-medium text-gray-900 mr-5">Submission Date Range:</h3>
            <div class="year mt-8">
              <span class="mr-2">From</span>
              <date-picker v-model:value="selectedDateFrom" value-type="YYYY-MM-DD" format="DD-MM-YYYY" />

              <span class="mx-2">To</span>
              <date-picker v-model:value="selectedDateTo" value-type="YYYY-MM-DD" format="DD-MM-YYYY" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 mb-5">
        <div class="flex justify-start">
          <div v-if="medics" class="col-2">
            <h3 class="text-lg leading-6 font-medium text-gray-900 mr-5 mb-3">Claim Status:</h3>
            <div class="px-2">
              <select
                v-model="selectedClaimStatus"
                class="custom-select block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option value="" selected disabled hidden>Select Claim Status</option>
                <option value="Paid">Paid</option>
                <option value="Unpaid">Unpaid</option>
                <option value="PartPaid">Part Paid</option>
                <option value="OverPaid">Over Paid</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <h3 class="text-lg leading-6 font-medium text-gray-900">Aged Claims</h3>
        <div class="flex flex-col">
          <div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Consultant
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Insurer
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Claim ID
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Claim Status
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Patient Name
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Admission Date
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Discharge Date
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Procedure
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Submission Date
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Invoiced Amount
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>

                  <tbody v-if="reportData">
                    <tr v-for="(item, index) in reportData" :key="index" class="bg-white">
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ item.consultant }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ item.insurer }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ item.hospital_claim_ref }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ item.claim_payment_status }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ item.patient_name }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ to_nicedate(item.admission_date) }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ to_nicedate(item.discharge_date) }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ item.procedure }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ to_nicedate(item.claim_submission_date) }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ item.invoiced_amount }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import { OpenAPICSV, OpenAPIJSON, OpenAPIPDF } from 'api/openapi';
import { api_store } from '@/store';
import { userStore } from '@/store/user';
import StringUtility from 'utils/stringUtility';
import vSelect from 'vue-select';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';

import SecondMenuReporting from '@/components/navigation/SecondMenuReporting.vue';
import { ToastComponent } from 'ui';

export default {
  name: 'AgedReport',
  components: {
    SecondMenuReporting,
    vSelect,
    DatePicker,
    ToastComponent
  },

  data() {
    return {
      collapsed: false,
      me: null,
      reportData: null,
      medics: null,
      isMedic: null,
      insurers: [],
      selectedInsurer: null,
      rollup: null,
      selectedConsultant: null,
      selectedDateFrom: null,
      selectedDateTo: null,
      selectedClaimStatus: null
    };
  },
  methods: {
    to_nicedate(date_str) {
      return StringUtility.formatDate(date_str);
    },

    async init() {
      await this.listMedics();
      await this.listInsurerContracts();
    },

    _build_request() {
      let requestData = {};
      requestData.consultant_person_id =
        this.selectedConsultant != null ? parseInt(this.selectedConsultant.personId) : null;
      requestData.insurer_name = this.selectedInsurer != null ? this.selectedInsurer.name : null;
      requestData.start_date = this.selectedDateFrom != null ? this.selectedDateFrom.toLocaleDateString('en-CA') : null;
      requestData.end_date = this.selectedDateTo != null ? this.selectedDateTo.toLocaleDateString('en-CA') : null;
      return requestData;
    },

    async fetch_json_report_data() {
      const request_data = this._build_request();
      const oapi = new OpenAPIJSON();
      this.reportData = await oapi.reportConsulantClaimAged(
        null,
        null,
        request_data.consultant_person_id,
        null,
        request_data.insurer_name,
        null,
        request_data.start_date,
        request_data.end_date
      );
    },

    async fetch_pdf_report_data() {
      const request_data = this._build_request();
      const oapi = new OpenAPIPDF();
      await oapi.reportConsulantClaimAged(
        null,
        null,
        request_data.consultant_person_id,
        null,
        request_data.insurer_name,
        null,
        request_data.start_date,
        request_data.end_date
      );
    },

    async fetch_csv_report_data() {
      const request_data = this._build_request();
      const oapi = new OpenAPICSV();
      await oapi.reportConsulantClaimAged(
        null,
        null,
        request_data.consultant_person_id,
        null,
        request_data.insurer_name,
        null,
        request_data.start_date,
        request_data.end_date
      );
    },
    async listInsurerContracts() {
      await api_store.cache
        .dispatch('INSURERS', {})
        .then(res => {
          this.insurers = res;
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });
    },
    async listMedics() {
      this.isMedic = userStore.getters.user?.is_medic;

      if (this.isMedic) {
        this.medics = [
          {
            personId: userStore.getters.user?.person_id,
            username: userStore.getters.user?.username
          }
        ];
        this.selectedConsultant = {
          personId: userStore.getters.user?.person_id,
          username: userStore.getters.user?.username
        };
      } else {
        await api_store.cache
          .dispatch('LIST_MY_CONSULTANTS', {})
          .then(res => {
            this.medics = res;
            this.medics.forEach(item => (item.consultant_id = item.medic.id));
            this.medics.forEach(item => (item.username = item.medic.ro.full_name));
          })
          .catch(error => {
            this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
          });
      }
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.year {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.main-content {
  float: left;
  height: 100vh;
  padding: 35px;
  overflow: auto;
}
.full-width {
  width: calc(100% - 110px);
}
.part-width {
  width: calc(100% - 350px);
}
.title {
  margin-bottom: 30px;
  font-size: 36px;
  font-weight: 600;
}
.subtitle {
  font-size: 28px;
  font-weight: 600;
}

.notes {
  float: left;
  width: 250px;
}
.hover-tooltip {
  position: relative;
  .tooltip {
    padding: 5px;
    bottom: 30px;
    right: 20px;
    visibility: hidden;
    position: absolute;
    font-size: 14px;
    line-height: 30px;
    background: #0081cd;
    border-radius: 8px;
    color: #fff;
  }
}
.hover-tooltip:hover {
  .tooltip {
    visibility: visible;
  }
}
.table-width-date {
  min-width: 380px;
}
</style>
