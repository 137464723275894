<template>
  <div>
    <!-- Place these two filter inputs under the EXPORT CSV on the right hand side -->
    <div class="col-12 mt-3 flex justify-start">
      <!-- <div class="col-6 flex px-2">
            <select v-model="selectedConsultant" class="custom-select mt-5 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
              <option value="" selected disabled hidden>Select Consultant</option>
              <option v-for="medic in medics" :key="medic.medicId" :value="medic.medicId">{{medic.firstname}} {{medic.lastname}}</option>
            </select>
          </div> -->
      <div class="col-4 flex flex-col px-2">
        <h3 class="text-lg leading-6 font-medium text-gray-900 mr-5">Filter by Payment Date:</h3>
        <!-- Needs to present only the year -->
        <div class="year" v-if="years">
          <date-picker
            v-model:value="dateRange"
            type="date"
            range
            placeholder="Select date range"
            :disabled-date="disabledBeforeToday"
            format="DD-MM-YYYY"
            data-test-id="filter-by-year-datepicker"
          ></date-picker>
        </div>
      </div>

      <div class="col- flex flex-col px-2">
        <h3 class="text-lg leading-6 font-medium text-gray-900 mr-5">Filter by Insurer:</h3>
        <div>
          <v-select
            placeholder="Select Insurer"
            :options="insurerContracts"
            :getOptionLabel="selectedInsurer => selectedInsurer.ro.insurer_name"
            v-model="selectedInsurer"
            data-test-id="insurer-select"
          ></v-select>
        </div>
      </div>
    </div>

    <div class="col-12" v-if="selectedConsultant.consultant_id !== null">
      <div class="col-12 flex flex-row px-2 mt-6">
        <!-- Allows the addition of a new line item -->
        <button
          @click="openNewAddModal"
          type="button"
          class="inline-flex items-center rounded border border-transparent bg-blue-500 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mr-auto"
          data-test-id="add-insurer-payment-file-btn"
        >
          ADD INSURER PAYMENT FILE
        </button>
        <div>
          <button
            @click="downloadCurrentTable"
            type="button"
            :class="{ 'animate-pulse': loadingDownloadAll }"
            class="inline-flex items-center rounded border border-transparent bg-blue-500 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mx-2"
            data-test-id="download-all-btn"
          >
            {{ loadingDownloadAll ? 'DOWNLOADING...' : 'DOWNLOAD ALL' }}
          </button>
          <button
            @click="downloadCsv"
            type="button"
            class="inline-flex items-center rounded border border-transparent bg-blue-500 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mx-2"
            data-test-id="export-csv-btn"
          >
            EXPORT CSV
          </button>
        </div>
      </div>
    </div>

    <div class="col-12" v-if="selectedConsultant.consultant_id !== null">
      <SpinnerLoader v-if="isLoading" />
      <TableComponent
        ref="paymentsTable"
        :claimsProp="files"
        :tableNames="tableNames"
        :consultant="selectedConsultant"
        @unset-download-state="loadingDownloadAll = false"
        @sentValue="atualizarValor"
      />
    </div>

    <Modal v-if="showNewAddModal" @close-modal="closeNewAddModal">
      <template v-slot:header>
        <h3 class="text-lg text-center leading-6 font-medium medo-grey-600">
          Add new Insurer Payment File for Consultant {{ this.selectedConsultant.username }}
        </h3>
      </template>
      <template v-slot:body>
        <div>
          <div class="col-12 text-center my-5">
            <h3 class="mb-3 font-semibold">Select the insurance company that the file originated from:</h3>
            <InsuranceCompany
              class="custom-select block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              v-model="newInsurer"
            ></InsuranceCompany>
          </div>
          <div class="col-12 text-center my-5">
            <h3 class="font-semibold">Select the file for upload:</h3>
            <label
              class="mt-3 inline-flex items-center px-3.5 py-2.5 border border-transparent text-xs font-medium rounded shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <input type="file" name="filesArray" @change="uploadFile" />
              Select File
            </label>
          </div>
          <div class="col-12 mt-3">
            <div class="flex justify-between">
              <label for="email" class="block text-sm font-medium text-gray-700">Payment Date</label>
              <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
            </div>
            <div class="mt-1">
              <CustomDatePicker v-model="newPaymentDate" />
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="flex justify-between">
              <label for="email" class="block text-sm font-medium text-gray-700"
                >File name (leave blank for autogenerated name)</label
              >
              <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
            </div>
            <div class="mt-1">
              <input
                v-model="newFileName"
                type="text"
                class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div v-if="fileToUpload" class="col-12 text-center">
            <button
              type="button"
              @click="submitFile"
              class="mt-3 inline-flex items-center px-3.5 py-2.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Upload File
            </button>
          </div>
        </div>
      </template>
    </Modal>

    <Modal v-if="showModal" @close-modal="closeModal">
      <template v-slot:header>
        <h3 class="text-lg text-center leading-6 font-medium medo-grey-600">Please select a file for upload</h3>
      </template>
      <template v-slot:body>
        <div>
          <div class="col-12 text-center">
            <label
              class="mt-3 inline-flex items-center px-3.5 py-2.5 border border-transparent text-xs font-medium rounded shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <input type="file" name="filesArray" @change="uploadFile" />
              Select File
            </label>
          </div>
          <div v-if="fileToUpload" class="col-12 text-center">
            <button
              type="button"
              @click="submitFile"
              class="mt-3 inline-flex items-center px-3.5 py-2.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Upload File
            </button>
          </div>
        </div>
      </template>
    </Modal>

    <Modal
      v-if="showManageModal"
      @close-modal="closeManageModal"
      :hide-expand-button="false"
      :close-on-click-outside="false"
    >
      <template v-slot:header>
        <h3 class="text-lg text-center leading-6 font-medium medo-grey-600">Remittance Management</h3>
      </template>
      <template v-slot:body>
        <ManageModalRemittance
          :remittance="selectedRemittanceFile"
          :selected-consultant="selectedConsultant"
          :errorCalculation="messageError1"
          :errorCalculation1="messageError2"
          @open-add-modal="openAddModal"
          @change-mode="handleChangeMode"
          @openAddExtInvoiceModal="openAddExtInvoiceModal"
          @set-filters="handleSetFilters"
          @update-payment-date="updatePaymentDate"
        />
      </template>
    </Modal>
    <AddPayment
      v-if="showAddModal"
      :insurer-file-id="insurerFileId"
      :insurer-file-name="insurerFileName"
      :insurer-id="insurerId"
      :selected-consultant="selectedConsultant"
      @close-modal="closeAddPayment"
      @payment-added="fetch_payments_files"
    />
    <AddExternalPayment
      v-if="showAddExtInvoiceModal"
      :insurer-file-id="insurerFileId"
      :insurer-file-name="insurerFileName"
      :insurer-id="insurerId"
      :selected-consultant="selectedConsultant"
      @close-modal="closeAddPayment"
      @payment-added="fetch_payments_files"
    />
    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import { OpenAPIDateRange, OpenAPIJSON } from 'api';
import { userStore } from '@/store/user';
import { toBase64 } from 'utils';
import BigNumber from 'bignumber.js';
import CustomDatePicker from '@/components/claim-forms/components/Datepicker.vue';
import Modal from '@/components/modal/Modal.vue';
import ManageModalRemittance from '@/components/modal/ManageModalRemittance.vue';
import AddPayment from '@/components/modal/AddPayment.vue';
import AddExternalPayment from '@/components/modal/AddExternalPayment.vue';
import SpinnerLoader from '../../components/misc/SpinnerLoader.vue';
import vSelect from 'vue-select';

import TableComponent from '@/components/claim-forms/components/TableComponent.vue';
import { ToastComponent } from 'ui';
//import Datepicker from 'vue3-datepicker'

import '@vuepic/vue-datepicker/dist/main.css';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import StringUtility from 'utils/stringUtility';
import InsuranceCompany from '@/components/form-fields/InsuranceCompany.vue';

export default {
  name: 'PaymentAndTaxFiles',
  components: {
    InsuranceCompany,
    CustomDatePicker,
    Modal,
    ManageModalRemittance,
    AddPayment,
    AddExternalPayment,
    ToastComponent,
    SpinnerLoader,
    vSelect,
    TableComponent,
    DatePicker
  },
  props: {
    selectedConsultant: Object,
    mode: String,
    insurers: Array
  },
  emits: ['change-mode', 'set-filters', 'disableConsultantSelect'],
  data() {
    return {
      openApi: new OpenAPIJSON(),
      isLoading: false,
      collapsed: false,
      me: null,
      username: '',
      medics: null,
      isMedic: null,
      currentYear: new Date().getFullYear(),
      years: [],
      selectedYear: null,
      selectedDateFrom: null,
      selectedDateTo: null,
      initialDate: new Date('2000-01-01'),
      fileCategory: null,

      insurerContracts: [],
      selectedInsurer: { id: null, ro: { insurer_id: null, insurer_name: null } },

      //addingNewPayment: false,

      associateTo: null,
      files: [],
      showModal: false,
      showNewAddModal: false,
      showAddExtInvoiceModal: false,
      newDate: null,
      newInsurer: null,

      fileToEdit: null,
      fileToUpload: null,

      modelInvoiced: null,
      modelPayable: null,
      modelAdjustment: null,
      modelTax: null,

      selectedRemittanceFile: null,
      showManageModal: false,
      showAddModal: false,

      newFileName: null,
      newPaymentDate: null,

      insurerFileId: null,
      insurerFileName: null,
      insurerId: null,

      loadingDownloadAll: false,

      errorCalc: '',
      messageError: '',
      messageError1: false,
      messageError2: '',
      selected1: '',

      tableNames: [
        { TableTitle: 'File Name', firstIndex: 'file_name', secondIndex: '', width: '155', money: false },
        {
          TableTitle: 'Uploaded',
          firstIndex: 'creation_date',
          secondIndex: '',
          width: '155',
          money: false,
          dateF: true
        },
        {
          TableTitle: 'Payment Date',
          firstIndex: 'payment_date',
          secondIndex: '',
          width: '155',
          money: false,
          dateF: true
        },
        {
          TableTitle: 'Processed',
          firstIndex: 'processed_date',
          secondIndex: '',
          width: '155',
          money: false
        },
        {
          TableTitle: 'Insurer',
          firstIndex: 'insurer',
          secondIndex: 'insurer_name',
          width: '155',
          money: false
        },
        {
          TableTitle: 'Total Inv',
          firstIndex: 'total_amount_invoiced',
          secondIndex: '',
          width: '155',
          money: true
        },
        { TableTitle: 'Paid', firstIndex: 'total_amount_paid', secondIndex: '', width: '155', money: true },
        {
          TableTitle: 'Pended',
          firstIndex: 'total_amount_pended',
          secondIndex: '',
          width: '155',
          money: true
        },
        {
          TableTitle: 'Rejected',
          firstIndex: 'total_amount_rejected',
          secondIndex: '',
          width: '155',
          money: true
        },
        {
          TableTitle: 'Reconciled',
          firstIndex: 'reconciled',
          secondIndex: 'amount_payable',
          width: '155',
          money: true
        },
        {
          TableTitle: 'External Inv',
          firstIndex: 'external',
          secondIndex: 'amount_payable',
          width: '155',
          money: true
        },
        {
          TableTitle: 'Unmatched',
          firstIndex: 'unreconciled',
          secondIndex: 'amount_payable',
          width: '155',
          money: true
        },
        {
          TableTitle: 'Download',
          firstIndex: 'file_name',
          secondIndex: null,
          buttons: [
            {
              class:
                'hover-tooltip inline-flex items-center px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400 fa fa-download',
              command: 'downloadPdf',
              name: ''
            }
          ],
          width: '155'
        },
        {
          TableTitle: 'Re-Scan',
          firstIndex: 'scan_state',
          secondIndex: 'scan_state',
          buttons: [
            {
              class:
                'class="inline-flex items-center justify-center mx-2 px-2.5 py-1.5 scan-btn-width border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
              command: 'scan',
              name: 'Re-Scan'
            }
          ],
          width: '155',
          stateButton: true
        },
        {
          TableTitle: 'Manage',
          buttons: [
            {
              class:
                'hover-tooltip inline-flex items-center px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400',
              command: 'manage',
              name: 'MANAGE'
            }
          ],
          width: '155'
        },
        {
          TableTitle: 'Delete',
          firstIndex: 'file_name',
          secondIndex: null,
          buttons: [
            {
              class:
                'hover-tooltip inline-flex items-center mx-2 px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 fas fa-trash',
              command: 'delete',
              name: ''
            }
          ],
          width: '155'
        }
      ],

      dateRange: null
    };
  },
  watch: {
    dateRange(newVal) {
      this.selectedDateFrom = newVal[0];
      this.selectedDateTo = newVal[1];
      this.fetch_payments_files();
    },
    selectedConsultant() {
      if (this.selectedConsultant.consultant_id == null && !this.selectedConsultant?.person_id) return;
      this.fetch_payments_files();
    },
    selectedInsurer(newVal) {
      if (newVal == null) {
        this.selectedInsurer = {};
        this.selectedInsurer.insurer_id = null;
      }
      this.selectedInsurer = newVal;
      this.fetch_payments_files();
      localStorage.setItem('selectInsurer', newVal);
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    downloadCurrentTable() {
      if (this.files?.length) {
        this.$refs.paymentsTable.downloadCurrentTable();
        this.loadingDownloadAll = true;
      } else {
        this.$toast.error('No files to download');
      }
    },
    downloadCsv() {
      if (this.files) {
        this.$refs.paymentsTable.downloadCsvTable();
      } else {
        this.$toast.error('No files to export');
      }
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
    atualizarValor(newValue, newValueSecond) {
      if (newValueSecond === 'downloadPdf') {
        this.getConsultantFile(newValue);
      }

      if (newValueSecond === 'manage') {
        this.openManageModal(newValue);
      }

      if (newValueSecond === 'delete') {
        this.deleteFiles(newValue);
      }

      if (newValueSecond === 'scan') {
        this.taskRemittanceScan(newValue);
      }
    },
    dateFunction(value) {
      const year = value.getFullYear();
      const month = ('0' + (value.getMonth() + 1)).slice(-2);
      const day = ('0' + value.getDate()).slice(-2);
      value = `${year}-${month}-${day}`;
      value = new Date(value);
      return value;
    },
    //CHECK ERRORS ON OCR
    checkErrorCalculationModal(
      totalAmountInvoiced,
      totalAmountPaid,
      unreconciledAmountPayable,
      reconciledAmountPayable,
      externalAmountPayable,
      unreconciledWithholdingTax,
      reconciledWithholdingTax,
      externalWithholdingTax
    ) {
      this.messageError2 = '';

      let firstValue = new BigNumber(unreconciledAmountPayable).times(0.2).toPrecision(2, 1);
      let secondValue = new BigNumber(unreconciledWithholdingTax).toPrecision(2, 1);
      if (parseFloat(firstValue).toFixed(2) !== parseFloat(secondValue).toFixed(2)) {
        this.messageError = 'Unreconciled TAX Calculation Error';
        return true;
      }

      firstValue = new BigNumber(reconciledAmountPayable).times(0.2).toPrecision((2, 1));
      secondValue = new BigNumber(reconciledWithholdingTax).toPrecision((2, 1));

      if (parseFloat(firstValue).toFixed(2) !== parseFloat(secondValue).toFixed(2)) {
        this.messageError = 'Reconciled TAX Calculation Error';
        this.messageError2 = 'VIEW RECONCILIED';
        return true;
      }

      firstValue = new BigNumber(externalAmountPayable).times(0.2).toPrecision(2, 1);
      secondValue = new BigNumber(externalWithholdingTax).toPrecision(2, 1);
      if (parseFloat(firstValue).toFixed(2) !== parseFloat(secondValue).toFixed(2)) {
        this.messageError = 'External Invoice Tax Calculation Error';
        this.messageError2 = 'VIEW EXTERNAL INVOICES';

        return true;
      }
      this.messageError = '';
      this.errorCalc = '';
      this.$toast.clear();
    },
    checkErrorCalculation(
      totalAmountInvoiced,
      totalAmountPaid,
      unreconciledAmountPayable,
      reconciledAmountPayable,
      externalAmountPayable,
      unreconciledWithholdingTax,
      reconciledWithholdingTax,
      externalWithholdingTax
    ) {
      if (parseFloat(totalAmountInvoiced) < parseFloat(totalAmountPaid)) {
        return 'Total Amount Paid > Total Amount Invoiced';
      }

      if (parseFloat(unreconciledAmountPayable) >= parseFloat(totalAmountPaid)) {
        return 'Unreconciled Amount Payable >= Total Amount Paid';
      }

      if (parseFloat(reconciledAmountPayable) >= parseFloat(totalAmountPaid)) {
        return 'Reconciled Amount Payable >= Total Amount Paid';
      }

      let sumValue =
        parseFloat(reconciledAmountPayable) + parseFloat(externalAmountPayable) + parseFloat(unreconciledAmountPayable);
      if (parseFloat(sumValue).toFixed(2) !== parseFloat(totalAmountPaid).toFixed(2)) {
        return 'Reconciled Amount Payable + External Amount Payable + Unreconciled Amount Payable is different from the Total Amount Paid';
      }

      let firstValue = new BigNumber(unreconciledAmountPayable).times(0.2).toPrecision(2, 1);
      let secondValue = new BigNumber(unreconciledWithholdingTax).toPrecision(2, 1);
      if (parseFloat(firstValue).toFixed(2) !== parseFloat(secondValue).toFixed(2)) {
        return 'Unreconciled TAX Calculation Error';
      }

      firstValue = new BigNumber(reconciledAmountPayable).times(0.2).toPrecision((2, 1));
      secondValue = new BigNumber(reconciledWithholdingTax).toPrecision((2, 1));

      if (parseFloat(firstValue).toFixed(2) !== parseFloat(secondValue).toFixed(2)) {
        return 'Reconciled TAX Calculation Error';
      }

      firstValue = new BigNumber(externalAmountPayable).times(0.2).toPrecision(2, 1);
      secondValue = new BigNumber(externalWithholdingTax).toPrecision(2, 1);
      if (parseFloat(firstValue).toFixed(2) !== parseFloat(secondValue).toFixed(2)) {
        return 'External Invoice Tax Calculation Error';
      }
      return '';
    },
    handleChangeMode(mode) {
      this.$emit('change-mode', mode);
    },
    handleSetFilters(fileId, fileName, filterType) {
      this.$emit('set-filters', fileId, fileName, filterType);
    },

    async fetch_payments_files() {
      this.isLoading = true;
      const start_date = this.selectedDateFrom ? StringUtility.naiveDateYYYYMMDD(this.selectedDateFrom) : null;
      const end_date = this.selectedDateTo ? StringUtility.naiveDateYYYYMMDD(this.selectedDateTo) : null;

      this.openApi
        .claim_statements_search(
          this.selectedConsultant?.consultant_id,
          this.selectedInsurer?.id,
          new OpenAPIDateRange(start_date, end_date)
        )
        .then(res => {
          this.isLoading = false;
          if (!res.items.length) {
            this.files = [];
          } else {
            this.insurerContracts = res.insurers;
          }
          this.files = res.items;
        })
        .catch(error => {
          console.log(error);
          this.$toast.error('Error loading files.');
          this.files = [];
          this.insurerContracts = [];
          this.isLoading = false;
        });
    },
    async init() {
      for (let i = this.currentYear; i >= 1900; i--) {
        this.years.push(i);
      }

      const dateFromReport = localStorage.getItem('dateFromReport');
      const dateToReport = localStorage.getItem('dateToReport');

      if (dateFromReport && dateFromReport !== 'null') {
        this.selectedDateFrom = new Date(dateFromReport);
        if (this.selectedDateFrom < this.initialDate) {
          this.selectedDateFrom = null;
        }
      } else {
        this.selectedDateFrom = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
      }

      if (dateToReport !== 'null') {
        this.selectedDateTo = new Date(dateToReport);
        if (this.selectedDateTo < this.initialDate) {
          this.selectedDateTo = null;
        }
      }

      this.isMedic = userStore.getters.user?.is_medic;
      this.username = userStore.getters.user?.username;
    },
    openNewAddModal() {
      this.fileCategory = 'insurance_remittance';
      this.showNewAddModal = true;
    },
    closeNewAddModal() {
      this.showNewAddModal = false;
      this.fileToUpload = null;
      this.fileCategory = null;
      this.newDate = null;
      this.newInsurer = null;
      this.newFileName = null;
      this.newPaymentDate = null;
    },
    openModal(type, associatedFileName = null) {
      this.fileCategory = type;
      this.showModal = true;
      if (associatedFileName != null) this.associateTo = associatedFileName;
    },
    closeModal() {
      this.showModal = false;
      this.fileToUpload = null;
      this.fileCategory = null;
    },
    async getConsultantFile(file) {
      const assoc = this.getAssocType();
      if (file && assoc) {
        try {
          const result = await this.openApi.file_get(file.file_name, assoc);
          this.download(result.files[0].file_name, result.files[0].data, result.files[0].mime_type);
        } catch {
          this.$refs.toast.showToast(
            'Something went wrong downloading file. Please try again later.',
            'fa-solid fa-triangle-exclamation',
            'error'
          );
        }
      }
    },
    download(filename, fileStream, mimeType = 'application/octet-stream') {
      const b64 = fileStream;
      const link = document.createElement('a');
      link.innerHTML = '';
      link.download = filename;
      link.href = `data:${mimeType};base64,${b64}`;
      document.body.appendChild(link);
      link.click();
    },
    uploadFile(event) {
      this.fileToUpload = event.target.files[0];
    },
    async submitFile() {
      if (this.fileCategory === 'insurance_remittance' && !this.newInsurer) {
        return this.$refs.toast.showToast(
          'Please select the insurer before file upload',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
      }
      if (this.selectedConsultant === null || !this.selectedConsultant.consultant_id) {
        return this.$refs.toast.showToast(
          'Please select a consultant before file upload',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
      }

      const assoc = this.getAssocType();
      if (assoc) {
        try {
          const base64 = await toBase64(this.fileToUpload);
          const currentTime = new Date().getTime().toString();
          const uploadedFile = await this.openApi.file_add_by_id(assoc, [
            {
              file_name: this.newFileName ? this.newFileName : `${currentTime.slice(-5)} ${this.fileToUpload.name}`,
              data: base64,
              mime_type: this.fileToUpload.type,
              file_category: this.fileCategory
            }
          ]);
          const file = {
            file_name: uploadedFile.files[0].file_name,
            assoc: uploadedFile.files[0].assoc
          };
          const metadata = {
            type: 'remittance',
            insurer_id: this.newInsurer.insurer_id,
            amount_invoiced: '0',
            amount_payable: '0',
            withholding_tax: '0',
            adjustment_amount: '0',
            payment_date: this.newPaymentDate
          };
          await this.openApi.file_set_metadata(file, metadata);

          this.$refs.toast.showToast('Successfully added file!', 'fas fa-check', 'success');
          this.closeModal();
          this.closeNewAddModal();
          this.fetch_payments_files();
        } catch {
          this.$refs.toast.showToast(
            'Something went wrong uploading file, please try again later.',
            'fa-solid fa-triangle-exclamation',
            'error'
          );
        }
      }
    },
    async taskRemittanceScan(fileId) {
      const file = this.files.find(item => item.file_id === fileId);
      const assoc = this.getAssocType();
      if (file && assoc) {
        try {
          await this.openApi.remittance_scan(file.file_name, assoc);
          file.scan_state = 'InProgress';
        } catch {
          this.$refs.toast.showToast(
            'Something went wrong scanning file. Please try again later.',
            'fa-solid fa-triangle-exclamation',
            'error'
          );
        }
      }
    },
    async deleteFiles(file) {
      const assoc = this.getAssocType();
      if (file && assoc) {
        try {
          await this.openApi.file_delete(file.file_name, assoc);
          this.files = this.files.filter(item => item.file_id !== file.file_id);
          this.$toast.success('Successfully deleted file!');
        } catch {
          this.$refs.toast.showToast(
            'Something went wrong deleting file. Please try again later.',
            'fa-solid fa-triangle-exclamation',
            'error'
          );
        }
      }
    },
    unparse() {
      let cvsData = [];

      this.files.forEach(item => {
        let creationDate;
        let insurerFileName;

        if (item.insurerFile.creationDate) {
          creationDate = this.formatDate(item.insurerFile.creationDate);
        } else {
          creationDate = '';
        }

        if (item.insurerFile) {
          insurerFileName = item.insurerFile.fileName;
        } else {
          insurerFileName = '/';
        }

        let parseItem = {
          Insurer: item.insurerFile.insurerName,
          'Payment Filename': insurerFileName,
          'Uploaded Date': creationDate,
          'Payment Date': item.insurerFile.paymentDate ? this.humanDate(item.insurerFile.paymentDate) : '',
          Processed: item.insurerFile.processedDate ? this.humanDate(item.insurerFile.processedDate) : '',
          'Total Invoiced': item.insurerFile.totalAmountInvoiced,
          Paid: item.insurerFile.totalAmountPaid,
          Pended: item.insurerFile.totalAmountPended,
          Rejected: item.insurerFile.totalAmountRejected,
          Reconcilled: item.insurerFile.reconciledAmountPayable,
          'External Invoices Paid': item.insurerFile.externalAmountPayable,
          Unmatched: item.insurerFile.unreconciledAmountPayable
        };

        cvsData.push(parseItem);
      });

      this.unparsedResults = this.$papa.unparse(cvsData, {
        delimiter: ','
      });
      this.$papa.download(this.unparsedResults, 'CSV-PaymentAndTax-' + Math.floor(Date.now() / 1000));
    },
    openManageModal(file, file2) {
      if (file2 === undefined) {
        file2 = false;
      }
      this.showManageModal = true;
      this.selectedRemittanceFile = file;
      this.messageError1 = file2;
      this.$emit('disableConsultantSelect', true);
    },
    closeManageModal() {
      this.showManageModal = false;
      this.selectedRemittanceFile = null;
      this.$emit('disableConsultantSelect', false);
    },
    openAddModal(insurerFileId, insurerFileName, insurerId) {
      this.insurerFileId = insurerFileId;
      this.insurerFileName = insurerFileName;
      this.insurerId = insurerId;
      this.showManageModal = false;
      this.showAddModal = true;
    },
    closeAddPayment() {
      this.insurerFileId = null;
      this.insurerFileName = null;
      this.insurerId = null;
      this.showAddModal = false;
      this.showAddExtInvoiceModal = false;
      this.selectedRemittanceFile = null;
    },
    openAddExtInvoiceModal(insurerFileId, insurerFileName, insurerId) {
      this.insurerFileId = insurerFileId;
      this.insurerFileName = insurerFileName;
      this.insurerId = insurerId;
      this.showManageModal = false;
      this.showAddModal = false;
      this.showAddExtInvoiceModal = true;
    },
    updatePaymentDate(paymentDate) {
      this.selectedRemittanceFile.payment_date = paymentDate;
    },
    getAssocType() {
      if (this.selectedConsultant?.consultant_id) {
        return {
          id: this.selectedConsultant?.consultant_id,
          type: 'id',
          assoc_type: 'consultant'
        };
      } else if (this.selectedConsultant?.person_id) {
        return {
          id: this.selectedConsultant?.person_id,
          type: 'personid',
          assoc_type: 'consultant'
        };
      } else {
        return null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.year {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.main-content {
  float: left;
  height: 100vh;
  padding: 35px;
  overflow: auto;
}

.full-width {
  width: calc(100% - 110px);
}

.part-width {
  width: calc(100% - 350px);
}

.title {
  margin-bottom: 30px;
  font-size: 36px;
  font-weight: 600;
}

.subtitle {
  font-size: 28px;
  font-weight: 600;
}

.notes {
  float: left;
  width: 250px;
}

.hover-tooltip {
  position: relative;

  .tooltip {
    padding: 5px;
    bottom: 30px;
    right: 20px;
    visibility: hidden;
    position: absolute;
    font-size: 14px;
    line-height: 30px;
    background: #0081cd;
    border-radius: 8px;
    color: #fff;
  }
}

.hover-tooltip:hover {
  .tooltip {
    visibility: visible;
  }
}

.table-width-date {
  min-width: 380px;
}

.col-filename {
  max-width: 450px;
  word-break: break-all;
  word-wrap: break-word;
  overflow: hidden;
}

.scan-btn-width {
  width: 84px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
