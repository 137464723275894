<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { ToastComponent } from 'ui';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  signature: {
    type: String,
    required: false
  }
});

const emit = defineEmits(['signature-captured']);

const options = ref({
  firstName: '',
  lastName: '',
  eMail: '',
  location: '',
  imageFormat: 1,
  imageX: 700,
  imageY: 250,
  imageTransparency: false,
  imageScaling: false,
  maxUpScalePercent: 0.0,
  rawDataFormat: 'ENC',
  minSigPoints: 25,
  penThickness: 3,
  penColor: '#000000'
});

const sigCaptured = ref(null);
const toast = ref(null);

const imageSrc = computed(() => {
  return sigCaptured.value?.src;
});

// Watch for changes in the signature prop
watch(
  () => props.signature,
  newSignature => {
    if (newSignature) {
      setImage(newSignature);
    }
  },
  { immediate: true }
);

const captureSignature = () => {
  const optionsData = JSON.stringify(options.value);
  const element = document.createElement('SigCaptureWeb_ExtnDataElem');
  element.setAttribute('SigCaptureWeb_MsgAttribute', optionsData);
  document.documentElement.appendChild(element);
  const evt = document.createEvent('Events');
  evt.initEvent('SigCaptureWeb_SignStartEvent', true, false);
  element.dispatchEvent(evt);
};

const signResponse = event => {
  const str = event.target.getAttribute('SigCaptureWeb_msgAttri');
  const obj = JSON.parse(str);

  if (obj?.errorMsg) {
    toast.value?.showToast(
      'An error occurred. Please contact the support.',
      'fa-solid fa-triangle-exclamation',
      'error'
    );
    throw new Error(`Error signing ePadSignature: ${obj.errorMsg}`);
  } else if (obj?.isSigned) {
    emit('signature-captured', `data:image/png;base64,${obj.imageData}`);
    setImage(obj.imageData);
  }
};
const setImage = str => {
  const image = new Image();
  image.src = 'data:image/png;base64,' + str;
  sigCaptured.value = image;
};

onMounted(() => {
  document.addEventListener('SigCaptureWeb_SignResponse', signResponse, false);
  if (props.signature) {
    setImage(props.signature);
  }
});
onUnmounted(() => {
  document.removeEventListener('SigCaptureWeb_SignResponse', signResponse, false);
});
</script>

<template>
  <section class="flex">
    <div class="relative">
      <img v-if="imageSrc" :src="imageSrc" alt="Signature" />
      <div v-else class="signature-empty-container" />
      <span class="signature-text">Signature</span>
    </div>
    <div v-if="editMode" class="flex flex-col justify-end">
      <button class="ml-6 flex items-center" @click="captureSignature">
        <i class="fa-regular fa-file-signature text-2xl" />
        <span class="underline ml-1">{{ imageSrc ? 'Recapture' : 'Capture' }} signature</span>
      </button>
    </div>
    <ToastComponent ref="toast"></ToastComponent>
  </section>
</template>

<style scoped>
.signature-text {
  position: absolute;
  left: 15px;
  bottom: 20px;
}

.signature-empty-container {
  width: 713px;
  height: 250px;
  border-radius: 5px;
  border: 2px solid #000000;
  background-color: #d9d9d9;
}

img {
  border-radius: 5px;
  border: 2px solid #000000;
  background-color: #d9d9d9;
  width: 713px;
  height: 250px;
}
</style>
