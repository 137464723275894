<template>
  <nav class="my-4" aria-label="tab navigation">
    <div class="gray-bottom-border px-3">
      <i v-if="hasPrevious" class="fas fa-chevron-left text-sm cursor-pointer" @click="firstIndex--"></i>
      <span
        :data-test-id="item.dataIdTest"
        v-for="(item, index) in calculatedNav"
        :key="index"
        class="mx-3"
        :class="[
          { 'active font-medium cursor-default': item.type == selected },
          { 'cursor-pointer': item.type != selected }
        ]"
        @click="$emit('switch-tab', item.type)"
      >
        {{ item.name }}
        <span v-if="showFormWarning && (item.type === 'claimForm' || item.type === 'patientForm')">
          <i class="fal fa-circle-exclamation mx-1 text-medosync-dark-orange"></i>
        </span>
        <span v-if="item.icon === 'Red Triangle'">
          (<i class="fa-solid fa-triangle-exclamation mx-1" style="color: #ad0000"></i>)
        </span>
        <span v-if="item.icon === 'Yellow Triangle'">
          (<i class="fa-solid fa-triangle-exclamation mx-1" style="color: #ffa500"></i>)
        </span>
        <span v-if="item.icon === 'Green Triangle'">
          (<i class="fa-solid fa-triangle-exclamation mx-1" style="color: #006600"></i>)
        </span>
        <span v-if="item.icon === 'Add'">
          <i class="fa-solid fa-plus pl-1 cursor-pointer" @click="$emit('add-item')" />
        </span>
        <span v-if="item.icon == null && ((item.number > 0 && item.number != null) || item.number == 'N/A')">
          ({{ getNavNumber(item.number) }})
        </span>
        <span v-if="item.icon == null && ((item.number === 0 && item.number != null) || item.number == 'N/A')">
          (N/A)
        </span>
      </span>
      <i v-if="hasNext" class="fas fa-chevron-right text-sm cursor-pointer" @click="firstIndex++"></i>
    </div>
    <div
      v-if="selected === 'claimForm' && subMenuItems?.length > 1"
      class="flex bg-[#CBD5E0] gap-8 mt-1 pl-6 py-1 text-medosync-violet-highlight font-medium"
    >
      <div
        v-for="item in subMenuItems"
        :key="item.claim_form_id"
        :class="[item.claim_form_id === selectedClaimFormId ? 'text-medosync-violet-highlight' : 'text-medosync-black']"
      >
        <span
          v-if="item.display_name"
          class="cursor-pointer"
          @click="$emit('select-item', { claimConsultantId: claimId, claimFormId: item.claim_form_id })"
        >
          {{ item.display_name }}
        </span>
        <i v-if="item.display_name" class="fa-solid fa-xmark ml-3 cursor-pointer" @click="removeSelectedItem = item" />
      </div>
    </div>
    <DefaultModal
      v-if="removeSelectedItem"
      :showManageModal="true"
      :title="`Delete Claim Form ${removeSelectedItem.display_name}`"
      :text="`Are you sure you want to delete ${removeSelectedItem.display_name} from this Consultant Claim?`"
      secondaryButtonText="Yes, delete <span class='block'>Claim Form</span>"
      primaryButtonText="Cancel"
      @secondary-changes="removeItem(removeSelectedItem)"
      @primary-changes="removeSelectedItem = null"
      @close-modal="removeSelectedItem = null"
    ></DefaultModal>
    <ToastComponent ref="toast"></ToastComponent>
  </nav>
</template>

<script>
import { OpenAPIJSON } from 'api/openapi';
import DefaultModal from '@/components/claim-forms/components/DefaultModal.vue';
import { ToastComponent } from 'ui';

export default {
  emits: ['switch-tab', 'add-item', 'select-item', 'remove-item'],
  props: {
    navs: null,
    selected: null,
    maxItems: null,
    dataTestId: null,
    showFormWarning: null,
    subMenuItems: {
      type: Array,
      required: false
    },
    selectedClaimFormId: {
      type: Number,
      required: false
    },
    claimConsultantId: {
      type: Number,
      required: false,
      default: null
    },
    claimCompositeId: {
      type: Number,
      required: false,
      default: null
    },
    claimId: {
      type: Number,
      required: false,
      default: null
    }
  },
  components: {
    DefaultModal,
    ToastComponent
  },
  data() {
    return {
      openApi: new OpenAPIJSON(),
      firstIndex: 0,
      removeSelectedItem: null
    };
  },
  watch: {
    maxItems(newVal, prevVal) {
      if (newVal > prevVal && this.firstIndex > 0) this.firstIndex--;
      else if (newVal < prevVal && this.navs[this.firstIndex + this.maxItems].type == this.selected) this.firstIndex++;
    }
  },
  computed: {
    calculatedNav() {
      let n = this.navs.filter(item => item.visible);
      return n.slice(this.firstIndex, this.firstIndex + this.maxItems);
    },
    hasPrevious() {
      return this.firstIndex > 0;
    },
    hasNext() {
      return this.firstIndex + this.maxItems < this.navs.length;
    }
  },
  methods: {
    getNavNumber(number) {
      return number == 0 ? '0' : number;
    },
    async removeItem(item) {
      try {
        if (this.claimConsultantId !== null) {
          await this.openApi.claimform_consultant_delete(this.claimConsultantId, item.claim_form_id);
        } else if (this.claimCompositeId !== null) {
          await this.openApi.claimform_composite_delete(this.claimConsultantId, item.claim_form_id);
        }
        this.$emit('remove-item', item.claim_form_id);
        this.$refs.toast.showToast('The claim form was deleted successfully.', 'fas fa-check', 'success');
      } catch (err) {
        this.$refs.toast.showToast(
          'An error occurred. Please contact the support.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
      }
      this.removeSelectedItem = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.gray-bottom-border {
  border-bottom: 2px solid #d8d9d8;
}

.active {
  padding-bottom: 3px;
  border-bottom: 2px solid #0c276c;
  color: #0c276c;
}
</style>
