<script setup lang="ts">
import { nextTick, onMounted, defineComponent, computed } from 'vue';
import { useField } from 'vee-validate';
import { isNum } from '../../../helper/utils';
import InputErrorMessage from './InputErrorMessage.vue';

defineComponent({ InputErrorMessage });

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    default: ''
  },
  requiredLength: {
    type: Number,
    default: 0
  },
  helperText: {
    type: String,
    default: null
  },
  maxLength: {
    type: Number,
    required: true
  },
  format: {
    type: Boolean,
    default: false
  },
  textBold: {
    type: Boolean,
    default: true
  },
  editMode: {
    type: Boolean,
    default: true
  },
  isWarning: {
    type: Boolean,
    default: false
  },
  inputType: {
    type: String,
    default: 'text'
  },
  enableValidation: {
    type: Boolean,
    default: true
  }
});

const { value, errorMessage, meta } = useField(() => props.name);

const showError = computed<boolean>(() => {
  return !!(props.enableValidation && errorMessage.value);
});

const blur = () => {
  nextTick(() => {
    const activeElement = document.activeElement;
    if (activeElement?.parentElement?.id === props.id) return;

    value.value = valueAsString();
    if (props.format) formatOnBlur();
  });
};

const valueAsString = () => {
  const inputs = document.getElementById(props.id)?.querySelectorAll('input');
  if (!inputs) return '';
  const value = Array.from(inputs)
    .map(input => input.value)
    .join('');
  return props.format ? parseInt(value).toString() : value;
};

const setValue = (value: string) => {
  value = value.toString();
  const inputs = document.getElementById(props.id)?.querySelectorAll('input');
  if (!inputs) return;

  const values = value.split('');
  inputs.forEach((input, index) => {
    if (index >= values.length) return;
    input.value = values[index];
  });
};

const formatOnBlur = () => {
  const inputs = document.getElementById(props.id)?.querySelectorAll('input');
  if (!inputs) return;

  let values = Array.from(inputs).map(input => input.value);
  const zeros: Array<string> = [];
  values = values.filter(item => (item !== '' ? true : zeros.push('0') && false));
  values = zeros.concat(values);

  setValue(values.join(''));
};

const addInputEventListeners = () => {
  const inputs = document.getElementById(props.id)?.querySelectorAll('input');
  if (!inputs) return;

  inputs.forEach((input, index) => {
    input.addEventListener('keydown', e => {
      if (e.key === 'Backspace' && input.value.length === 0) {
        if (index !== 0) {
          inputs[index - 1].focus();
        }
      } else if (e.key === 'Backspace' && input.value.length === 1) {
        input.value = '';
      } else if (e.key === 'ArrowRight') {
        if (index !== inputs.length - 1) {
          inputs[index + 1].focus();
        }
      } else if (e.key === 'ArrowLeft') {
        if (index !== 0) {
          inputs[index - 1].focus();
        }
      } else if ((e.key.length === 1 || e.key == 'Unidentified') && input.value.length === 1) {
        if (index !== inputs.length - 1) {
          inputs[index + 1].focus();
        }
      }
    });
    input.addEventListener('input', () => {
      if (input.value.length > 1) {
        const newValue = input.value.slice(0, 1);
        input.value = isNum(newValue) ? newValue : '';
      }
    });
    input.addEventListener('paste', event => {
      event.preventDefault();
      const paste = event.clipboardData?.getData('text');
      if (!paste) return;
      const pasteArray = paste.split('');
      pasteArray.forEach((item, pasteIndex) => {
        if (isNum(item) && index + pasteIndex < inputs.length) {
          inputs[index + pasteIndex].value = item;
        }
      });
      if (index + pasteArray.length < inputs.length) {
        inputs[index + pasteArray.length].focus();
      } else {
        inputs[index].blur();
      }
    });
  });
};

const setInitialValue = () => {
  if (meta.initialValue) {
    setValue(meta.initialValue as string);
  }
};

onMounted(() => {
  addInputEventListeners();
  setInitialValue();
});
</script>

<template>
  <div :id="id + '-container'" :name="name">
    <label
      :for="id"
      class="text-sm sm:text-base block"
      :class="{
        'text-medosync-dark-orange': isWarning && editMode,
        'text-medosync-red': showError,
        'font-bold': textBold
      }"
    >
      {{ label }}{{ requiredLength > 0 ? '*' : '' }}
      <i v-if="isWarning && editMode" class="fal fa-circle-exclamation mx-1 text-medosync-dark-orange"></i>
    </label>
    <div
      v-show="editMode"
      :id="id"
      class="inline-block border border-medosync-black rounded-md p-1 py-2 mt-2"
      :class="{
        'border-medosync-dark-orange': isWarning && editMode,
        'border-medosync-red focus:ring-medosync-red focus:border-medosync-red': showError
      }"
    >
      <input
        v-for="(item, index) in maxLength"
        :id="`${id}-${index}`"
        :key="index"
        class="input-width number-font-size focus:ring-0 border-medosync-black focus:border-medosync-black border-r border-t-0 border-l-0 border-b-0 py-0"
        :class="{
          'border-medosync-dark-orange': isWarning && editMode,
          'border-medosync-red  focus:border-medosync-red': showError,
          'border-r-0': index === maxLength - 1
        }"
        :type="inputType"
        @blur="blur()"
      />
    </div>
    <div v-show="!editMode">
      <p :id="id + '-readonly'" class="text-lg text-gray-500 ml-4 mr-6">
        {{ value === '' || value === undefined || value === null ? 'N/A' : value }}
      </p>
    </div>
    <div
      v-if="helperText"
      class="block text-sm font-normal italic mt-1"
      :class="{ 'text-medosync-dark-orange': isWarning && editMode }"
    >
      {{ helperText }}
    </div>
    <InputErrorMessage v-if="(showError as boolean)" class="block">Please enter your {{ label }}</InputErrorMessage>
  </div>
</template>

<style lang="scss" scoped>
.input-width {
  width: 44px;
  height: 36px;
}
.number-font-size {
  font-size: 20px;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button,
input[type='number'] {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield !important;
}
@media only screen and (max-width: 640px) {
  .input-width {
    width: 26px;
    height: 26px;
  }
  .number-font-size {
    font-size: 16px;
    padding-left: 6px;
    padding-right: 0px;
  }
}
</style>
