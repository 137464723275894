<script setup lang="ts">
import { ref } from 'vue';

const items = [
  { name: 'Claims Table', icon: 'fas fa-table', link: '/settings/claims-table' },
  { name: 'Workflow', icon: 'fas fa-list-check', link: '/settings/menu-management' },
  { name: 'Tags', icon: 'fas fa-tag', link: '/settings/tags-management' },
  { name: 'Manage Users', icon: 'fas fa-users', link: '/settings/manage-users' },
  { name: 'Manage Groups', icon: 'fas fa-users-viewfinder', link: '/settings/groups' },
  { name: 'Manage Pricing', icon: 'fas fa-barcode', link: '/settings/contracts' }
];
const navActive = ref(true);
</script>

<template>
  <div class="bg-medosync-nav-grey transition-slow overflow-hidden" :class="navActive ? 'w-[270px]' : 'w-[50px]'">
    <div
      class="flex items-center h-[24px] mt-14 mb-2 px-5 py-5 cursor-pointer hover:opacity-75"
      :class="{ 'bg-medosync-blue': !navActive }"
      @click="navActive = !navActive"
    >
      <i class="fas" :class="navActive ? 'fa-chevron-left' : 'fa-chevron-right'"></i>
      <span v-show="navActive" class="ml-3 text-medosync-violet-highlight font-bold">Settings</span>
    </div>

    <RouterLink
      v-show="navActive"
      v-for="(item, index) in items"
      :key="index"
      :to="item.link"
      class="py-3 px-5 flex justify-between items-center text-medosync-violet-highlight hover:opacity-75"
      activeClass="bg-medosync-blue"
    >
      <div class="w-[160px] shrink-0 text-sm font-bold">
        {{ item.name }}
      </div>
      <div class="w-[24px] text-center">
        <i :class="item.icon" class="text-lg"></i>
      </div>
    </RouterLink>
  </div>
</template>

<style scoped>
.transition-slow {
  transition: all 0.5s;
}
</style>
