<script setup>
import { reactive, computed, ref } from 'vue';
import { OpenAPIJSON } from 'api/openapi';
import { ToastComponent } from 'ui';

// Define custom events for row click and selection
const emit = defineEmits(['row-click', 'row-select']);
const refToast = ref(null);
const sorted = ref(false);

// Define component props for headers and data
const props = defineProps({
  headers: { default: null }, // Headers to define columns in the table
  data: { default: null } // Data for populating table rows
});

const columnWidths = computed(() => {
  if (!props.headers) return [];
  const totalWidth = props.headers.reduce((sum, header) => sum + header.width, 0);
  return props.headers.map(header => ({
    ...header,
    widthPercentage: (header.width / totalWidth) * 100
  }));
});

// Helper function to format currency values with Euro symbol
const formatCurrency = value => {
  if (isNaN(value) || value === null || value === '') {
    // Validate that the value is numeric
    return '-'; // Return 'ERROR' if the value is not valid
  }
  const num = parseFloat(value).toFixed(2);
  return `€ ${num}`; // Format as Euro currency
};

// Computed to extract claims data from props
const claims = computed(() => props.data);

// Reactive state for sorting, row highlighting, and row selection
const state = reactive({
  highlightedRow: -1, // Tracks the highlighted row index
  sortedColumn: '', // Column being sorted
  sortDirection: 'asc', // Sorting direction (ascending or descending)
  selectedRow: null // Tracks the selected row index
});

// Computed for sorting claims based on the selected column and sort direction
const sortedClaims = computed(() => {
  if (!state.sortedColumn) return claims.value; // If no sorting column is selected, return the original data

  // Sort the data based on the current sort direction and column
  return [...claims.value].sort((a, b) => {
    const modifier = state.sortDirection === 'asc' ? 1 : -1;
    if (a[state.sortedColumn] < b[state.sortedColumn]) return -1 * modifier;
    if (a[state.sortedColumn] > b[state.sortedColumn]) return 1 * modifier;
    return 0;
  });
});

// Helper function to get nested values from objects (e.g., for accessing nested properties)
const getNestedValue = (obj, path) => {
  if (!path) return undefined; // If no path is provided, return undefined
  const parts = path.replace(/\[(\w+)\]/g, '.$1').split('.'); // Split the path into parts
  return parts.reduce((acc, part) => {
    if (acc && part in acc) {
      // Check if part exists in the object
      return acc[part];
    } else {
      return undefined; // Return undefined if the path is invalid
    }
  }, obj);
};

// Methods to handle sorting columns in ascending order
const sortUp = key => {
  state.sortedColumn = key; // Set the column to be sorted
  state.sortDirection = 'asc'; // Set sort direction to ascending
};

// Method to handle sorting columns in descending order
const sortDown = key => {
  state.sortedColumn = key;
  state.sortDirection = 'desc'; // Set sort direction to descending
};

// Method to handle row click events and emit the clicked row
const lineClick = (row, rowIndex) => {
  selectRow(rowIndex); // Select the row when clicked
  emit('row-click', row); // Emit the row click event with the row data
};

// Methods to handle row highlighting on mouse hover
const highlightRow = index => {
  state.highlightedRow = index; // Set the row as highlighted
};

// Method to reset row highlighting when mouse leaves
const resetRowColor = () => {
  state.highlightedRow = -1; // Reset highlighted row
};

// Helper function to format dates in DD-MM-YYYY format
const formatDate = date => {
  if (!date) {
    return '-'; // Return error if date is invalid
  }
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, '0');
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const year = d.getFullYear();
  return `${day}-${month}-${year}`; // Format date as DD-MM-YYYY
};

// Function to handle row selection and emit selection event
const selectRow = (rowIndex, event) => {
  if (event) {
    event.stopPropagation(); // Prevent event bubbling when clicking select button
  }
  if (state.selectedRow === rowIndex) {
    state.selectedRow = null; // Deselect if already selected
  } else {
    state.selectedRow = rowIndex; // Select new row
  }
  // Emit selected row data or null if deselected
  emit('row-select', state.selectedRow !== null ? sortedClaims.value[state.selectedRow] : null);
};

const formatText = (text, upercase) => {
  let formattedText = text.replace(/_/g, ' ');
  return upercase ? formattedText.toUpperCase() : formattedText;
};

const handleSign = async rowIndex => {
  const item = sortedClaims.value[rowIndex];
  const oapi = new OpenAPIJSON();
  const req = {
    claim_ref: {
      type: 'id',
      id: item.claim_consultant_id
    },
    consultant: {
      type: 'id',
      id: item.claim_consultant.consultant_id
    },
    reason: 'Request through UI',
    target_state: 'consultant_approved'
  };

  try {
    const res = await oapi.consultant_state_change(req);
    if (res) {
      refToast.value.showToast('Consultant Approved', 'fas fa-check', 'success');
      sortedClaims.value[rowIndex].claim_state_str = 'CONSULTANT APPROVED';
      sortedClaims.value[rowIndex].claim_state = 'consultant_approved';
    } else {
      refToast.value.showToast('An error occurred', 'fa-solid fa-triangle-exclamation', 'error');
    }
  } catch (error) {
    refToast.value.showToast(error.message || error, 'fa-solid fa-triangle-exclamation', 'error');
  }
};

const handleReject = async rowIndex => {
  const item = sortedClaims.value[rowIndex];
  const oapi = new OpenAPIJSON();
  const req = {
    claim_ref: {
      type: 'id',
      id: item.claim_consultant_id
    },
    consultant: {
      type: 'id',
      id: item.claim_consultant.consultant_id
    },
    reason: 'Request through UI',
    target_state: 'consultant_rejected'
  };

  try {
    const res = await oapi.consultant_state_change(req);
    if (res) {
      sortedClaims.value[rowIndex].claim_state_str = 'Consultant Req. Amendments';
      sortedClaims.value[rowIndex].claim_state = 'consultant_rejected';

      refToast.value.showToast('Claim marked as rejected', 'fas fa-check', 'success');
    } else {
      refToast.value.showToast('An error occurred', 'fa-solid fa-triangle-exclamation', 'error');
    }
  } catch (error) {
    refToast.value.showToast(error.message || error, 'fa-solid fa-triangle-exclamation', 'error');
  }
};

const emitModal = () => {
  emit('open-modal');
};

const resetFilters = () => {
  emit('reset-filters');
};

// Check if the "SELECTED" column exists in headers
const hasSelectedColumn = computed(() => props.headers.some(header => header.label === 'SELECTED'));
</script>

<template>
  <div class="table-responsive">
    <div v-if="data.length === 0" class="border border-blue-300 p-8 rounded-md mb-4">
      <p class="text-gray-700 mb-4 ml-8">We are sorry. We were not able to find a claim with these filters.</p>
      <div class="flex items-center space-x-4 ml-8">
        <button @click="resetFilters()" class="underline focus:outline-none" type="button" data-test-id="apply-button">
          Reset All
        </button>
        <button
          @click="emitModal()"
          type="button"
          data-test-id="add-claim-button"
          class="flex items-center bg-medosync-blue rounded-full shadow-md px-6 py-3"
        >
          Add claim
        </button>
      </div>
    </div>
    <table v-if="data.length !== 0" class="w-full">
      <!-- Define column widths usando porcentagens -->
      <colgroup>
        <col v-for="header in columnWidths" :key="header.key" :style="{ width: header.widthPercentage + '%' }" />
      </colgroup>
      <thead>
        <tr>
          <!-- Render table headers dynamically based on the headers prop -->
          <th
            v-for="header in columnWidths"
            :key="header.key"
            class="header-cell-table font-roboto text-[12px]"
            :class="{ 'text-center': header.label === 'SELECTED' }"
          >
            {{ header.label }}
            <!-- Add sorting icons with click handlers for sortable columns -->
            <span v-if="header.label !== 'SELECTED'" class="sort-arrows">
              <i v-if="sorted" class="fas fa-caret-up sort-up" @click="sortUp(header.key)"></i>
              <i v-if="sorted" class="fas fa-caret-down sort-down" @click="sortDown(header.key)"></i>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <!-- Render table rows dynamically based on sorted claims -->
        <tr
          v-for="(row, rowIndex) in sortedClaims"
          :key="rowIndex"
          class="hover:bg-[#00A3F4] hover:bg-opacity-50"
          :class="[
            rowIndex % 2 === 0 ? 'bg-white' : 'bg-[#EEEFF3]',
            { 'selected-row': rowIndex === state.selectedRow }
          ]"
          @mouseover="highlightRow(rowIndex)"
          @mouseout="resetRowColor(rowIndex)"
          style="cursor: pointer"
          @click="lineClick(row, rowIndex)"
          :data-test-id="'ccp-dashboard-row-' + rowIndex"
        >
          <!-- Render cells for each column in the row -->
          <td
            v-for="(header, index) in columnWidths"
            :key="header.key"
            :class="{ 'table-cell-left': header.position, 'table-cell': !header.position }"
            :data-test-id="'ccp-dashboard-row-' + rowIndex + ' column ' + index"
          >
            <!-- Render selection button for SELECTED column -->
            <template v-if="header.label === 'SELECTED'">
              <div class="select-button-container">
                <button
                  @click="selectRow(rowIndex, $event)"
                  class="select-button"
                  :class="{ selected: rowIndex === state.selectedRow }"
                ></button>
              </div>
            </template>

            <div
              class="flex items-center space-x-2 justify-center"
              v-if="header.doublebutton && row[header.key] === 'ready_for_consultant_approval'"
            >
              <button
                class="px-4 py-1 rounded-full border-2 border-[#00A3F4] text-black bg-white hover:bg-gray-50 transition-colors text-sm font-medium"
                @click.stop="handleReject(rowIndex, $event)"
              >
                Reject
              </button>
              <button
                class="px-4 py-1 rounded-full bg-[#00A3F4] text-black hover:bg-[#0093e0] transition-colors text-sm font-medium"
                @click.stop="handleSign(rowIndex, $event)"
              >
                Sign
              </button>
            </div>

            <!-- Render regular text for standard columns -->
            <span
              :data-test-id="id + '-text-third-' + rowIndex"
              v-else-if="!header.type && !header.type2 && !header.date && !header.upercase && !header.doublebutton"
              class="font-roboto text-[12px] cell-content"
            >
              {{ row[header.key] }}
            </span>

            <!-- Render formatted currency for monetary values -->
            <span
              :data-test-id="id + '-text-first-' + rowIndex"
              v-else-if="!header.date && header.type2 && !header.type && !header.upercase && !header.doublebutton"
              class="font-roboto text-[12px] cell-content"
            >
              {{ getNestedValue(row, header.key) }}
            </span>

            <span
              :data-test-id="id + '-text-first-' + rowIndex"
              v-else-if="!header.date && header.type2 && header.type && !header.upercase && !header.doublebutton"
              class="font-roboto text-[12px] cell-content"
            >
              {{ formatCurrency(getNestedValue(row, header.key)) }}
            </span>

            <span
              :data-test-id="id + '-text-first-' + rowIndex"
              v-else-if="!header.date && !header.type2 && header.type && !header.upercase && !header.doublebutton"
              class="font-roboto text-[12px] cell-content"
            >
              {{ formatCurrency(getNestedValue(row, header.key)) }}
            </span>

            <!-- Render formatted dates for date columns -->
            <span
              :data-test-id="id + '-date-' + rowIndex"
              v-else-if="header.date && !header.upercase && !header.doublebutton"
              class="font-roboto text-[12px] cell-content"
            >
              {{ formatDate(row[header.key]) }}
            </span>
            <span
              :data-test-id="id + '-date-' + rowIndex"
              v-else-if="header.upercase && !header.doublebutton"
              class="font-roboto text-[12px] cell-content"
            >
              {{ formatText(row[header.key]).toUpperCase() }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <ToastComponent ref="refToast"></ToastComponent>
</template>

<style scoped lang="scss">
/* Responsive table container */
.table-responsive {
  width: 100%;
  min-width: 100%;
  margin: 0 auto;
  overflow-x: auto;

  /* Hide scrollbar in WebKit browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar in Firefox */
  scrollbar-width: none;
}

/* Forces table to use the full available width */
table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

/* Header cell styling */
.header-cell-table {
  background-color: #cacdd6;
  color: black;
  text-align: center; // Center-aligns the header text
  white-space: normal;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: normal;
  height: 40px;
  padding: 8px;
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Table cell styling */
.table-cell,
.table-cell-left {
  word-wrap: break-word;
  vertical-align: middle; // Vertically centers the cell content
  text-align: center; // Center-aligns the cell text horizontally
  border-bottom: 1px solid #d1d1d1;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  padding: 8px;
  height: 100%;
}

/* Cell content styling */
.cell-content {
  display: block;
  word-wrap: break-word;
  white-space: normal;
  width: 100%;
  min-height: 20px;
}

/* Sorting arrows styling */
.sort-arrows {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.sort-arrows i {
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

.sort-arrows i.active {
  opacity: 1;
}

/* Selection button container styling */
.select-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* Selection button styling */
.select-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid black;
  background-color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;

  &.selected {
    border-color: #00a3f4;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #00a3f4;
    }
  }
}

/* Selected row styling */
.selected-row {
  background-color: #e6f7ff !important;
}

/* Media queries for adjusting behavior on smaller screens */
@media screen and (max-width: 1024px) {
  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .cell-content {
    font-size: 11px;
  }
}

/* Button styling */
button {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:active {
    transform: translateY(1px);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
}
</style>
