<script setup>
import { ref } from 'vue';
import { OpenAPIJSON } from 'api';
import { useRoute } from 'vue-router';

import NotificationsQueryHandler from '@/components/notifications/NotificationsQueryHandler.vue';
import NotificationsList from '@/components/notifications/NotificationsList.vue';
import NotificationsMessage from '@/components/notifications/NotificationsMessage.vue';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';
import { ToastComponent } from 'ui';
import MessageToClaimModal from '@/views/notifications/MessageToClaimModal.vue';
import DefaultModal from '@/components/claim-forms/components/DefaultModal.vue';

const route = useRoute();
const notificationId = ref(route?.params?.id);
const notificationHospitalAppointmentId = ref(route?.params?.hospitalAppointmentId);
const openApi = new OpenAPIJSON();
const isLoading = ref(false);
const refToast = ref(null);
const shownMessages = ref([]);
const messagesIdx = ref(1);
const messagesPageSize = ref(19);
const stopLazyLoad = ref(false);
const selectedMessage = ref(null);
const messageParams = ref({ unread: null, assoc: null, sort_by: 'created', desc: true });
const openModal = ref(false);
const openDetachModal = ref(false);
const detachAssoc = ref(null);
const detachMessageId = ref(null);

const loadMessages = async params => {
  resetMessages();
  if (notificationId.value) {
    try {
      const res = await openApi.claim_consultant_message_list(parseInt(notificationId.value));
      shownMessages.value = res.items;
      messageParams.value = {
        unread: false,
        assoc: [{ assoc_type: 'consultantclaim', type: 'any' }],
        sort_by: 'created',
        desc: true,
        pagination: { start_idx: 1, end_idx: 20 },
        claimId: notificationHospitalAppointmentId.value
      };
    } catch (error) {
      return refToast.value.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
    }
    return;
  }
  try {
    isLoading.value = true;
    messageParams.value = { ...messageParams.value, ...params };
    messageParams.value.pagination = {
      start_idx: messagesIdx.value,
      end_idx: messagesIdx.value + messagesPageSize.value
    };
    const res = await openApi.message_list(messageParams.value);
    shownMessages.value = res.items;
    if (shownMessages.value.length >= res.total_items) stopLazyLoad.value = true;
    messagesIdx.value += messagesPageSize.value + 1;
  } catch (error) {
    return refToast.value.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
  } finally {
    isLoading.value = false;
  }
};

const loadMoreMessages = async () => {
  if (stopLazyLoad.value) return;
  if (notificationId.value) {
    return;
  }
  messageParams.value.pagination = {
    start_idx: messagesIdx.value,
    end_idx: messagesIdx.value + messagesPageSize.value
  };
  const res = await openApi.message_list(messageParams.value);
  shownMessages.value = [...shownMessages.value, ...res.items];
  if (shownMessages.value.length >= res.total_items) stopLazyLoad.value = true;
  messagesIdx.value += messagesPageSize.value + 1;
};

const resetMessages = () => {
  shownMessages.value = [];
  messagesIdx.value = 1;
  selectedMessage.value = null;
  stopLazyLoad.value = false;
};

const selectMessage = async (id, index, openAssignToClaimModal) => {
  try {
    isLoading.value = true;
    try {
      selectedMessage.value = await openApi.message_get({ message_id: id, include_attachment_data: true });
    } catch {
      selectedMessage.value = await openApi.message_get({ message_id: id, include_attachment_data: false });
    }
    selectedMessage.value.consultantId = shownMessages.value[index].consultant?.id || null;
    selectedMessage.value.author = shownMessages.value[index].author;
    shownMessages.value[index].read_time = new Date().toISOString();
    await openApi.message_mark_seen(id);
    if (openAssignToClaimModal) openModal.value = true;
  } catch (error) {
    return refToast.value.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
  } finally {
    isLoading.value = false;
  }
};

const closeMessage = () => {
  selectedMessage.value = null;
};

const detachClaim = async () => {
  try {
    isLoading.value = true;
    await openApi.message_remove_assoc(detachMessageId.value, detachAssoc.value);
    refToast.value.showToast('Message successfully detached.', 'fas fa-check', 'success');
    updateRemovedDetach(detachMessageId.value, detachAssoc.value);
  } catch (error) {
    return refToast.value.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
  } finally {
    isLoading.value = false;
    handleCloseDetachModal();
  }
};

const handleOpenDetachModal = (assocs, messageId) => {
  detachAssoc.value = assocs;
  detachMessageId.value = messageId;
  openDetachModal.value = true;
};

const handleCloseDetachModal = () => {
  openDetachModal.value = false;
  detachAssoc.value = null;
  detachMessageId.value = null;
};

const updateRemovedDetach = (messageID, targetIds) => {
  const messageIndex = shownMessages.value.findIndex(message => message.message.id === messageID);
  if (messageIndex === -1) {
    return;
  }
  const filteredAssoc = shownMessages.value[messageIndex].assoc.filter(assocItem => {
    return !targetIds.some(target => assocItem.id === target.id && assocItem.assoc_type === target.assoc_type);
  });
  shownMessages.value[messageIndex].assoc = filteredAssoc;
};

const showToastMessage = ({ message, icon, type }) => {
  refToast.value.showToast(message, icon, type);
};

loadMessages();
</script>

<template>
  <div class="h-screen flex flex-col py-8 px-6">
    <h2 class="text-4xl font-bold text-medosync-violet-highlight mb-10">Notification Centre</h2>
    <h3 class="text-3xl font-semibold text-medosync-violet-highlight mb-10">Messages from Consultants</h3>
    <NotificationsQueryHandler
      class="mb-4"
      :class="[selectedMessage ? 'w-1/2 pr-6' : 'w-full']"
      :messageParams="messageParams"
      @sort-by-date="loadMessages"
      @filter-messages="loadMessages"
    />
    <div class="flex grow min-h-0 justify-between items-center gap-x-4">
      <NotificationsList
        :class="[selectedMessage ? 'w-1/2' : 'w-full']"
        :messages="shownMessages"
        :selected-message="selectedMessage"
        @select-message="selectMessage"
        @load-more-messages="loadMoreMessages"
        @detach-claim="handleOpenDetachModal"
      />

      <NotificationsMessage
        v-if="selectedMessage"
        :message="selectedMessage"
        @close-message="closeMessage"
        class="w-1/2"
      />
    </div>
    <MessageToClaimModal
      v-if="openModal"
      @update-messages="shownMessages = $event"
      @modal-module-off="openModal = false"
      @show-toast-message="showToastMessage"
      :messages="selectedMessage"
      :allMessages="shownMessages"
    />
    <DefaultModal
      :showManageModal="openDetachModal"
      title="Detach this message from the assigned claim?"
      text="If you detach this message from the assigned claim you only delete the connection between them."
      subtext="All manually added data into the claim form will still be available."
      primaryButtonText="Detach message from claim"
      secondaryButtonText="Cancel"
      @primary-changes="detachClaim"
      @secondary-changes="handleCloseDetachModal"
      @close-modal="handleCloseDetachModal"
    />
    <SpinnerLoader v-if="isLoading" />
    <ToastComponent ref="refToast"></ToastComponent>
  </div>
</template>
