<template>
  <div class="col-12">
    <div class="col-12 px-5 my-5 text-lg font-medium grid grid-cols-1 flex flex-col">
      <h4>
        File Name: <span>{{ remittance.file_name }}</span>
      </h4>
      <h4>
        Total Paid: €<span>{{ remittance.total_amount_paid }}</span>
      </h4>
      <h4>
        Pended by Insurer: €<span>{{ remittance.total_amount_pended }}</span>
      </h4>
      <h4>
        Rejected by Insurer: €<span>{{ remittance.total_amount_rejected }}</span>
      </h4>
      <h4>
        Reconciled: €<span>{{ remittance.reconciled.amount_payable }}</span>
      </h4>
      <h4>
        External Invoice: €<span>{{ remittance.external.amount_payable }}</span>
      </h4>
      <h4>
        Unmatched: €<span>{{ remittance.unreconciled.amount_payable }}</span>
      </h4>
      <h4>
        Payment File Date:
        <span v-if="remittance.payment_date && !editMode">{{ formatDate(remittance.payment_date) }}</span>
        <CustomDatePicker v-if="editMode" v-model="newPaymentDate" />
      </h4>
      <h4>
        Uploaded: <span v-if="remittance.creation_date">{{ formatDate(remittance.creation_date) }}</span>
      </h4>
      <h4>
        Processed: <span v-if="remittance.processed_date">{{ formatDate(remittance.processed_date) }}</span>
      </h4>
    </div>

    <!-- List section -->
    <div class="col-12 item-list-section flex flex-col justify-center">
      <!-- Appointments without discharge information will not have a claim form, only an invoice -->
      <div>
        <button
          type="button"
          @click="editMode ? submitEdit() : startEdit()"
          class="w-full custom-list-btn py-1.5 mb-2 border border-indigo-500 text-s font-medium rounded shadow-sm text-indigo-500 text-center uppercase bg-white hover:text-indigo-600 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
        >
          <i class="fas fa-pencil custom-list-icon"></i> {{ editMode ? 'SAVE CHANGES' : 'EDIT FILE' }}
        </button>

        <div @click="goToPayments('all')" title="View payments of all states.">
          <button
            type="button"
            class="w-full custom-list-btn py-1.5 mb-2 border border-indigo-500 text-s font-medium rounded shadow-sm text-indigo-500 text-center uppercase bg-white hover:text-indigo-600 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
            :class="[{ 'bg-red-100 hover:bg-red-100': errorCalculation1 == 'VIEW ALL' }]"
          >
            <i class="fas fa-file-circle-check custom-list-icon"></i> View All
          </button>
        </div>

        <div
          @click="goToPayments('matched')"
          title="View payments that have been attributed to invoices that exist within the system."
        >
          <button
            type="button"
            class="w-full custom-list-btn py-1.5 mb-2 border border-indigo-500 text-s font-medium rounded shadow-sm text-indigo-500 text-center uppercase bg-white hover:text-indigo-600 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
            :class="[{ 'bg-red-100 hover:bg-red-100': errorCalculation1 == 'VIEW RECONCILIED' }]"
          >
            <i class="fas fa-file-circle-check custom-list-icon"></i> View Reconciled
          </button>
        </div>

        <div
          @click="goToPayments('not_matched')"
          title="View payments that need to be manually reconcilled or assigned as external invoices (work-to-do)"
        >
          <button
            type="button"
            class="w-full custom-list-btn py-1.5 mb-2 border border-indigo-500 text-s font-medium rounded shadow-sm text-indigo-500 text-center uppercase bg-white hover:text-indigo-600 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
            :class="[{ 'opacity-20': editMode }]"
          >
            <i class="fas fa-file-circle-question custom-list-icon"></i> View Unmatched
          </button>
        </div>

        <div
          @click="goToPayments('pended')"
          title="View payments that the insurer marked as pended for further information"
        >
          <button
            type="button"
            class="w-full custom-list-btn py-1.5 mb-2 border border-indigo-500 text-s font-medium rounded shadow-sm text-indigo-500 text-center uppercase bg-white hover:text-indigo-600 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
            :class="[{ 'opacity-20': editMode }]"
          >
            <i class="fas fa-file-circle-exclamation custom-list-icon"></i> View Pended
          </button>
        </div>

        <div @click="goToPayments('rejected')" title="View payments that the insurer marked as rejected for payment">
          <button
            type="button"
            class="w-full custom-list-btn py-1.5 mb-2 border border-indigo-500 text-s font-medium rounded shadow-sm text-indigo-500 text-center uppercase bg-white hover:text-indigo-600 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
            :class="[{ 'opacity-20': editMode }]"
          >
            <i class="fas fa-file-circle-minus custom-list-icon"></i> View Rejected
          </button>
        </div>

        <button
          @click="goToPayments('external')"
          title="View invoices that were marked as external because they were issued by another business entity."
          type="button"
          class="w-full custom-list-btn py-1.5 mb-2 border border-indigo-500 text-s font-medium rounded shadow-sm text-indigo-500 text-center uppercase bg-white hover:text-indigo-600 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
          :class="[{ 'bg-red-100 hover:bg-red-100': errorCalculation1 == 'VIEW EXTERNAL INVOICES' }]"
        >
          <i class="fas fa-file-import custom-list-icon"></i> View External Invoices
        </button>

        <button
          @click="addManualReconciliation(remittance)"
          type="button"
          class="w-full custom-list-btn py-1.5 mb-2 border border-indigo-500 text-s font-medium rounded shadow-sm text-indigo-500 text-center uppercase bg-white hover:text-indigo-600 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
          :class="[{ 'opacity-20': editMode }]"
        >
          <i class="fas fa-file-edit custom-list-icon"></i> Manual reconciliation
        </button>

        <button
          @click="addExternalInvoice(remittance)"
          type="button"
          class="w-full custom-list-btn py-1.5 mb-2 border border-indigo-500 text-s font-medium rounded shadow-sm text-indigo-500 text-center uppercase bg-white hover:text-indigo-600 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
          :class="[{ 'opacity-20': editMode }]"
        >
          <i class="fas fa-file-edit custom-list-icon"></i> Add External Invoice
        </button>

        <!--
          <button v-if="remittance.insurerFile.insurerName == 'Laya Healthcare' || remittance.insurerFile.insurerName == 'Irish Life Health'"
                  type="button"
                  class="w-full custom-list-btn py-1.5 mb-2 border border-indigo-500 text-s font-medium rounded shadow-sm text-indigo-500 text-center uppercase bg-white hover:text-indigo-600 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
                  :class="[{'opacity-20': editMode}]"
          >
            <i class="fas fa-file-search custom-list-icon"></i> Scan
          </button>
          -->
      </div>
    </div>

    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import { OpenAPIJSON } from 'api';
import CustomDatePicker from '@/components/claim-forms/components/Datepicker.vue';
import { ToastComponent } from 'ui';

export default {
  props: {
    remittance: { required: true },
    selectedConsultant: null,
    errorCalculation: null,
    errorCalculation1: null
  },
  emits: [
    'appointmentStateChange',
    'change-mode',
    'setFilters',
    'update-payment-date',
    'set-filters',
    'open-add-modal',
    'open-add-ext-invoice-modal'
  ],
  components: {
    CustomDatePicker,
    ToastComponent
  },
  data() {
    return {
      openApi: new OpenAPIJSON(),
      fileToUpload: null,
      deleteItem: null,
      editMode: false,

      fileToEdit: null,
      newPaymentDate: null
    };
  },
  methods: {
    goToPayments(filterType) {
      this.$emit('set-filters', this.remittance.file_id, this.remittance.file_name, filterType);
      this.$emit('change-mode', 'payments');
    },
    addManualReconciliation(remittance) {
      this.$emit('open-add-modal', remittance.file_id, remittance.file_name, remittance.insurer.insurer_id);
    },
    addExternalInvoice(insurerFile) {
      this.$emit(
        'open-add-ext-invoice-modal',
        insurerFile.file_id,
        insurerFile.file_name,
        insurerFile.insurer.insurer_id
      );
    },
    formatDate(data) {
      let date = new Date(data);
      return date.toLocaleDateString('es-CL');
    },
    startEdit() {
      const selectedItem = this.remittance;
      this.fileToEdit = selectedItem.file_name;
      this.editMode = true;
    },
    async submitEdit() {
      const metadata = {
        type: 'remittance',
        insurer_id: this.remittance.insurer.insurer_id,
        amount_invoiced: '0',
        amount_payable: '0',
        withholding_tax: '0',
        adjustment_amount: '0',
        payment_date: this.newPaymentDate
      };
      const assoc = this.getAssocType();
      const file = {
        file_name: this.remittance.file_name,
        assoc
      };
      try {
        await this.openApi.file_set_metadata(file, metadata);
        this.$emit('update-payment-date', this.newPaymentDate);
      } catch (error) {
        this.$refs.toast.showToast(
          'Error updating file. Please try again.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
      }

      this.fileToEdit = null;
      this.editMode = false;
    },

    getAssocType() {
      if (this.selectedConsultant?.consultant_id) {
        return {
          id: this.selectedConsultant?.consultant_id,
          type: 'id',
          assoc_type: 'consultant'
        };
      } else if (this.selectedConsultant?.person_id) {
        return {
          id: this.selectedConsultant?.person_id,
          type: 'personid',
          assoc_type: 'consultant'
        };
      } else {
        return null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.item-list-section {
  float: left;
  padding-left: 20px;
}

.custom-list-btn {
  position: relative;

  .custom-list-icon {
    position: absolute;
    left: 8px;
    top: 10px;
  }
}

.status-title {
  margin-top: -46px;
  margin-bottom: 21px;
  font-weight: 600;
  text-align: right;
}

.space {
  height: 15px;
}

.modal-notes {
  .notes {
    width: 100%;

    .note-sidebar-section {
      height: 67vh;
    }
  }
}

iframe {
  width: 100%;
  min-height: 300px;
  height: 70vh;
  resize: both;
  overflow: auto;
}
</style>
