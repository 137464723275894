<template>
  <SpinnerLoader v-if="isLoading" />
  <div
    ref="claimDetails"
    :class="[notesOpened ? 'third-width notes-transition-enter' : 'full-width notes-transition-exit']"
    class="py-8 px-6"
    v-if="claim"
  >
    <Breadcrumbs v-if="isTextBoxDisabled" :links="breadcrumbs" />
    <div v-else class="cursor-pointer w-fit" @click="backButton">
      <i class="fas fa-chevron-left mr-2.5"></i>
      back
    </div>

    <ClaimDetailsHeader
      :claim="claim"
      :filteredDetails="insurerPaymentFiles"
      :editMode="!isTextBoxDisabled"
      :allClaimFormsNum="allClaimForms?.length || 0"
      @init-claim="getClaim(true)"
      @refresh-files="refreshFiles"
      @scroll-to-field="scrollToField"
    />
    <TabNav
      v-if="isTextBoxDisabled"
      :navs="navs"
      :selected="selectedTab"
      :max-items="notesOpened ? navs.length - 1 : navs.length"
      :sub-menu-items="allClaimForms"
      :selected-claim-form-id="claimData?.claim_form_id"
      :claimId="claim?.claim_id"
      :claim-composite-id="claim?.claim_composite_id"
      @switch-tab="switchTab"
      @add-item="newClaimFormTabOpen = true"
      @select-item="selectClaimForm"
      @remove-item="removeClaimForm"
    />

    <div v-if="!newClaimFormTabOpen" class="flex flex-row justify-between items-center my-2">
      <div class="flex relative">
        <h2 :data-id-test="dataIdTest" class="text-xl font-medium cursor-pointer text-blue-900">
          {{ tabTitle }}<span v-if="currentClaimTypeName"> - {{ currentClaimTypeName }}</span>

          <i
            v-if="selectedTab == 'invoice' && isTextBoxDisabled && configRoute"
            class="fa-solid fa-pen ml-2"
            @click="disableTextBox()"
            data-test-id="edit-pen-icon-invoice"
          ></i>
          <i
            v-if="selectedTab == 'compositeClaimform' && isTextBoxDisabled"
            class="fa-solid fa-pen ml-2"
            @click="disableTextBox()"
            data-test-id="edit-pen-icon-invoice"
          ></i>
          <i
            v-if="
              claimData &&
              selectedTab == 'compositeClaimform' &&
              claimData.claim_form_type != 'self_pay' &&
              allClaimForms?.length === 1 &&
              isTextBoxDisabled
            "
            class="fa-regular fa-trash-can ml-4"
            @click="deleteFirstForm"
            data-test-id="delete-claim-icon"
          ></i>
        </h2>
      </div>
      <!-- Patient FORM  -->

      <div
        data-id-test="preview-claim-form-btn"
        v-if="selectedTab == 'compositeClaimform'"
        class="cursor-pointer"
        @click="generateCompositePdf()"
      >
        <i class="fa-regular fa-file-pdf fa-xl me-1"></i>
        <span class="underline text-sm pt-1">Preview form</span>
      </div>
      <div
        data-id-test="preview-claim-form-btn"
        v-if="selectedTab == 'patientForm'"
        class="cursor-pointer"
        @click="patientPdf()"
      >
        <i class="fa-regular fa-file-pdf fa-xl me-1"></i>
        <span class="underline text-sm pt-1">Preview form</span>
      </div>

      <div
        data-id-test="preview-claim-form-btn"
        v-if="claim && selectedTab == 'invoice' && claim.insurance.insurer_name != 'Self Pay'"
        class="cursor-pointer"
        @click="handleInvoicePreview"
      >
        <i class="fa-regular fa-file-pdf fa-xl me-1"></i>
        <span class="underline text-sm pt-1">Preview invoice</span>
      </div>
    </div>

    <AdmissionClaimForm
      ref="claimFormRef"
      v-if="selectedTab === 'patientForm'"
      :disableEditMode="false"
      :signaturePadActive="true"
      :locatorPatient="locatorPatient"
      :locatorPatientInfo="locatorPatientInfo"
      :admission="claimData?.admission.on"
      @edit-mode="value => (formEditMode = value)"
      @generate-pdf="generatePdf"
    />

    <CompositeClaimForm
      v-if="selectedTab == 'compositeClaimform' && !newClaimFormTabOpen && showForm"
      :claimInfo="claim"
      :claimDataPreviousState="claimDataPreviousState"
      @set-previous-form-state="setPreviousFormState"
      @update-header-info="updateHeaderInfo"
      ref="childComponentRefComposite"
    />
    <ClaiAttchments
      v-if="selectedTab == 'attachments'"
      :files="files"
      :claim="claim"
      :uploadVal="uploadVal"
      @remove-file-from-list="removeFileFromList"
      @refetch-files="listFiles"
    />
    <Invoice v-if="selectedTab == 'invoice'" :claim="claim" />
    <InsurerPaymentFiles
      v-if="selectedTab == 'paymentFiles'"
      :filteredData="insurerPaymentFiles"
      :appointmentId="claim?.app_id"
      :consultantId="claim?.admitted_by?.person_id"
      :claimId="claim?.claim_id"
      :claim="claim"
    />
  </div>

  <DefaultModal
    v-if="removeSelectedItem"
    :showManageModal="true"
    :title="`Delete Claim Form `"
    :text="`Are you sure you want to delete this Consultant Claim?`"
    secondaryButtonText="Yes, delete <span class='block'>Claim Form</span>"
    primaryButtonText="Cancel"
    @secondary-changes="removeItem(removeSelectedItem)"
    @primary-changes="deleteFirstForm(true)"
    @close-modal="deleteFirstForm(true)"
  ></DefaultModal>
  <ToastComponent ref="refToast"></ToastComponent>
</template>

<script setup>
import { ref, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router';
import _ from 'lodash';
import { AssocType, ClaimPhase } from 'enums';
import Breadcrumbs from './Breadcrumbs.vue';
import ClaimDetailsHeader from './ClaimDetailsHeader.vue';
import TabNav from './TabNav.vue';
import { OpenAPIJSON, OpenAPIPDF } from 'api/openapi';
import { notes_store } from '@/store/notes';
import Invoice from '@/views/claims/invoices/Invoice.vue';
import ClaiAttchments from './ClaimAdditionalAttachments.vue';
import InsurerPaymentFiles from './InsurerPaymentFiles.vue';
import { useStore } from 'vuex';
import { ToastComponent } from 'ui';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';
import { nextTick } from 'vue';
import DefaultModal from '@/components/claim-forms/components/DefaultModal.vue';
import AdmissionClaimForm from '../../admission/AdmissionClaimForm.vue';
import { hospitalConfigStore } from '@/store/config';
import CompositeClaimForm from './CompositeClaimForm.vue';

// Error handling utility
const handleApiError = (error, customMessage) => {
  console.error('[API Error]:', error);
  const defaultMessage = 'Something went wrong. Please try again later.';
  const errorMessage = customMessage || defaultMessage;

  refToast.value?.showToast(errorMessage, 'fa-solid fa-triangle-exclamation', 'error');
  return {
    error: true,
    message: errorMessage
  };
};

// Loading wrapper
const withLoading = async (apiCall, options = {}) => {
  const { loadingDelay = 300, errorMessage, retryCount = 0, timeout = 30000 } = options;

  let attempts = 0;
  const loadingTimeout = setTimeout(() => {
    isLoading.value = true;
  }, loadingDelay);

  try {
    while (attempts <= retryCount) {
      try {
        const timeoutPromise = new Promise((_, reject) => {
          setTimeout(() => reject(new Error('Operation timed out')), timeout);
        });

        const resultPromise = apiCall();
        const result = await Promise.race([resultPromise, timeoutPromise]);
        return { data: result, error: null };
      } catch (error) {
        attempts++;
        if (attempts > retryCount) throw error;
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
  } catch (error) {
    return handleApiError(error, errorMessage);
  } finally {
    clearTimeout(loadingTimeout);
    isLoading.value = false;
  }
};

// Refs
const claimFormRef = ref(null);
const locatorPatient = ref(null);
const locatorPatientInfo = ref(null);
const refToast = ref(null);
const MAX_CLAIM_FORMS = ref(2);
const claimFormNew = ref(null);
const route = useRoute();
const hostname = ref(window.location.hostname);
const openApi = new OpenAPIJSON();
const openApiPdf = new OpenAPIPDF();
const claim = ref(null);
const allClaimFetchedData = ref([]);
const claimData = ref(null);
const claimDataPreviousState = ref(null);
const claimTypes = ref(null);
const allClaimForms = ref([]);
const breadcrumbs = ref([]);
const claimformData = ref(null);
const selectedTab = ref('compositeClaimform');
const showManageModal = ref(false);
const files = ref([]);
const filesCount = ref(0);
const uploadVal = ref(false);
const paymentFiles = ref([]);
const insurerPaymentFiles = ref(null);
const loadingClaimToggle = ref(false);
const isLoading = ref(true);
const newClaimFormTabOpen = ref(false);
const newClaimFormOptions = ref([]);
const showForm = ref(true);
const removeSelectedItem = ref(null);
const store = useStore();
const childComponentRefComposite = ref(null);
const configRoute = ref(null);

// Navigation setup
const navs = ref([
  { type: 'patientForm', name: 'Patient Form', number: null, dataIdTest: 'patient-form-type', visible: false },
  { type: 'compositeClaimform', name: 'Composite Form', number: null, dataIdTest: 'composite-form-tab', visible: true },
  { type: 'invoice', name: 'Invoice', number: null, dataIdTest: 'invoice-tab', visible: true },
  {
    type: 'paymentFiles',
    name: 'Insurer Payment',
    number: 0,
    icon: null,
    dataIdTest: 'insurer-pymt-tab',
    visible: true
  },
  { type: 'attachments', name: 'Claim Documents', number: 0, dataIdTest: 'attachments-tab', visible: true }
]);

// Computed properties
const isTextBoxDisabled = computed(() => store.state.textBoxDisabled);

const tabTitle = computed(() => {
  return navs.value.find(nav => nav.type === selectedTab.value)?.name;
});

const dataIdTest = computed(() => {
  const dataIdTest = navs.value.find(nav => nav.type === selectedTab.value)?.dataIdTest;
  return `page-title-${dataIdTest}`;
});

const notesOpened = computed(() => notes_store.state.notesOpened);

const insurerPaymentFilesIcon = computed(() => {
  switch (claim.value?.claim_state) {
    case 'pended_with_hospital':
      return 'Yellow Triangle';
    case 'rejected':
      return 'Red Triangle';
    case 'part_paid':
      return 'Yellow Triangle';
    case 'paid_in_full':
      return 'Green Triangle';
    default:
      return null;
  }
});

const currentClaimTypeName = computed(() => {
  if (!claimTypes.value) return null;
  return claimTypes.value.find(type => type.claim_form_type === claimData.value?.claim_form_type)?.name;
});

// Watchers
watch(
  () => claim.value?.claim_state,
  newState => {
    if (newState) {
      navSetIcon('paymentFiles', insurerPaymentFilesIcon.value);
    }
  }
);

watch(
  () => selectedTab.value,
  (newTab, oldTab) => {
    if (newTab === 'compositeClaimform' && oldTab !== newTab) {
      nextTick(() => {
        if (childComponentRefComposite.value) {
          childComponentRefComposite.value.refreshData();
        }
      });
    }
  }
);

// Lifecycle hooks
onMounted(async () => {
  await init();
  const storedConfig = localStorage.getItem('configRoute');
  try {
    const config = JSON.parse(storedConfig);
    configRoute.value = config.edit_invoice_line_items;
  } catch (error) {
    console.error('Error parsing config', error);
  }
});

// Data validation
const validateClaimData = claim => {
  if (!claim) return false;
  if (!claim.app_id || !claim.claim_id) return false;
  if (!claim.claim_composite_id) return false;
  return true;
};

// Main initialization
const init = async () => {
  const { error } = await withLoading(
    async () => {
      await getClaim();

      const promises = [
        hospitalConfigStore.getters.has_product_access ? getAllClaims() : Promise.resolve(),
        getClaimTypes(),
        listFiles(),
        listInsurerPaymentFiles()
      ];

      await Promise.all(promises);

      getBreadcrumbs();
      enableTextBox();
      filteredNavs();
      selectedTab.value = 'compositeClaimform';

      return { success: true };
    },
    {
      errorMessage: 'Error initializing component',
      retryCount: 1
    }
  );

  if (error) {
    console.error('Failed to initialize component:', error);
  }
};

// API Calls
const getClaimTypes = async () => {
  const insurerId = claim.value?.insurance?.insurer_id;
  const claimCompositeId = claim.value?.claim_composite_id;

  if (!insurerId || !claimCompositeId) {
    return handleApiError(null, 'Missing required claim information');
  }

  const { data, error } = await withLoading(
    async () => await openApi.getInsurerClaimForms(insurerId, null, null, claimCompositeId),
    {
      errorMessage: 'Error getting claim form types',
      retryCount: 1
    }
  );

  if (error) return;

  claimTypes.value = data;
  setNewClaimFormOptions(claimTypes.value);
  setAddIcon();
};

const getClaim = async (setClaimFormOptions = false) => {
  const claimCompositeId = parseInt(route.params.id);

  const { data, error } = await withLoading(
    async () => {
      const response = await openApi.searchCompositeClaims(null, null, claimCompositeId);
      if (!response?.items?.length) {
        throw new Error('No claim found');
      }
      return response;
    },
    {
      errorMessage: 'Error fetching claim data',
      retryCount: 1
    }
  );

  if (error) return;

  claim.value = data.items[0];
  navSetIcon('paymentFiles', insurerPaymentFilesIcon.value);

  const formsResult = await getAllClaimForms(claim.value.claim_composite_id);
  if (formsResult.error) return;

  allClaimForms.value = formsResult.data;

  if (setClaimFormOptions) {
    setNewClaimFormOptions(claimTypes.value);
  }

  if (allClaimForms.value?.length) {
    const { data: claimFormData, error: claimFormError } = await withLoading(
      async () =>
        await openApi.claimform_get(claim.value.app_id, claim.value.claim_id, allClaimForms.value[0].claim_form_id),
      {
        errorMessage: 'Error fetching claim form data'
      }
    );

    if (claimFormError) return;

    claimData.value = claimFormData;
    addClaimToList(claimData.value);
  }
};

const getAllClaims = async () => {
  const { data, error } = await withLoading(
    async () => {
      const result = await openApi.claimform_patient_list(claim.value.app_id);
      return result;
    },
    {
      errorMessage: 'Error fetching all claims'
    }
  );

  if (error) return;

  const { claim_form_list } = data;

  if (claim_form_list.length > 0) {
    await getAppointmentSignature(claim_form_list[0].locator);
    await getPatientClaimform(claim_form_list[0].locator.claim_form_id);
    navSetVisible('patientForm');
  } else {
    navSetHidden('patientForm');
  }
  setAddIcon();
};

const getAppointmentSignature = async locator => {
  if (!locator) {
    refToast.value?.showToast('Missing required locator parameter', 'fa-solid fa-triangle-exclamation', 'error');
    return;
  }
  locatorPatient.value = locator;
};

const getAllClaimForms = async id => {
  const { data, error } = await withLoading(
    async () => {
      const response = await openApi.claim_form_composite_list({
        claim_ref: { id, type: 'id' }
      });
      return response.claim_forms;
    },
    {
      errorMessage: 'Error fetching claim forms'
    }
  );

  if (error) return { error };
  return { data };
};

const listFiles = async () => {
  const { data, error } = await withLoading(
    async () => {
      if (hospitalConfigStore.getters.is_single_consultant_hospital) {
        return await openApi.file_list_by_id(claim.value.claim_id, AssocType.ClaimNested);
      }
      return await openApi.file_list_by_id(claim.value.claim_composite_id, AssocType.CompositeClaim);
    },
    {
      errorMessage: 'Error fetching files'
    }
  );

  if (error) return;

  files.value = data.files;
  filesCount.value = data.files?.length || 0;
  navSetNumber('attachments', filesCount.value);
};

const listInsurerPaymentFiles = async () => {
  if (claim.value?.claim_phase === ClaimPhase.PRE_SUBMIT) {
    insurerPaymentFiles.value = [];
    return;
  }

  const { data, error } = await withLoading(
    async () => {
      const result = await openApi.claim_statement_file_search(
        claim.value.claim_consultant.consultant_id,
        null,
        null,
        null,
        claim.value.invoice_no,
        null,
        null,
        claim.value.insurance.insurer_id,
        null
      );

      return result.items.filter(item => item.hospital_appointment_id === claim.value.hospital_appointment_id);
    },
    {
      errorMessage: 'Error fetching insurer payment files'
    }
  );

  if (error) return;
  insurerPaymentFiles.value = data;
};

const getPatientClaimform = async locator => {
  if (!locator) {
    refToast.value?.showToast('Missing required locator parameter', 'fa-solid fa-triangle-exclamation', 'error');
    return;
  }
  locatorPatientInfo.value = {
    app_id: claim.value.app_id,
    claim_id: claim.value.claim_id,
    locator: locator
  };
};

const handleInvoicePreview = async () => {
  const { error } = await withLoading(
    async () => {
      await openApiPdf.invoicePdfPreview(claim.value);
      return { success: true };
    },
    {
      errorMessage: 'Error generating invoice preview',
      timeout: 60000 // PDF generation might take longer
    }
  );

  if (error) return;
};

const patientPdf = () => {
  claimFormRef.value.generatePdf();
};

const generatePdf = async fetchedClaimformData => {
  const { error } = await withLoading(
    async () => {
      await openApiPdf.claimformPdfPreview(fetchedClaimformData);
      return { success: true };
    },
    {
      errorMessage: 'Error generating PDF',
      timeout: 60000
    }
  );

  if (error) return;
};

const selectClaimForm = async ({ claimId, claimFormId }) => {
  if (claimData.value?.claim_form_id === claimFormId) return;

  const existingClaimForm = allClaimFetchedData.value.find(claim => claim.claim_form_id === claimFormId);

  if (existingClaimForm) {
    claimData.value = existingClaimForm;
    return;
  }

  const { data, error } = await withLoading(
    async () => {
      return await openApi.claimform_get(claim.value.app_id, claimId, claimFormId);
    },
    {
      errorMessage: 'Error selecting claim form'
    }
  );

  if (error) return;

  claimData.value = data;
  allClaimFetchedData.value.push(claimData.value);
};

const removeItem = async item => {
  const { error } = await withLoading(
    async () => {
      await openApi.claimform_composite_delete(item.claim_composite_id, item.claim_form_id);
      return { success: true };
    },
    {
      errorMessage: 'Error removing claim form'
    }
  );

  if (error) return;

  refToast.value.showToast('The claim form was deleted successfully.', 'fas fa-check', 'success');
  removeSelectedItem.value = null;
  await resetState();
  await init();
};

// Data manipulation methods
const addClaimToList = claimData => {
  const existingIndex = allClaimFetchedData.value.findIndex(claim => claim.claim_form_id === claimData.claim_form_id);

  if (existingIndex !== -1) {
    allClaimFetchedData.value.splice(existingIndex, 1, claimData);
  } else {
    allClaimFetchedData.value.push(claimData);
  }
};

// UI and State Management Methods
const removeClaimForm = async claimFormId => {
  const { error } = await withLoading(
    async () => {
      allClaimForms.value = allClaimForms.value.filter(claim => claim.claim_form_id !== claimFormId);

      if (claimData.value?.claim_form_id === claimFormId && allClaimForms.value.length) {
        await selectClaimForm({
          claimId: claim.value.claim_id,
          claimFormId: allClaimForms.value[0].claim_form_id,
          appointmentId: claim.value.app_id
        });
      } else if (!allClaimForms.value.length) {
        claimData.value = null;
      }

      allClaimFetchedData.value = allClaimFetchedData.value.filter(claim => claim.claim_form_id !== claimFormId);

      setNewClaimFormOptions(claimTypes.value);
      setAddIcon();
      return { success: true };
    },
    {
      errorMessage: 'Error removing claim form'
    }
  );

  if (error) return;
};

const getBreadcrumbs = () => {
  breadcrumbs.value = [
    { name: 'Home', to: '/navigator/compositeclaims' },
    { name: 'All Claims', to: '/navigator/compositeclaims' },
    { name: `Claim ${claim.value?.hospital_appointment_id}`, to: null }
  ];
};

const enableTextBox = () => {
  store.commit('setTextBoxDisabled', true);
};

const disableTextBox = () => {
  claimDataPreviousState.value = JSON.parse(JSON.stringify(claimData.value));
  store.commit('setTextBoxDisabled', false);
};

// Navigation Methods
const filteredNavs = () => {
  navs.value = navs.value.map(nav => ({
    ...nav,
    visible: nav.type === 'hospitalForm' ? WHITE_LIST_HOSPITAL_FORM.value.includes(hostname.value) : nav.visible
  }));
};

const navSetNumber = (field_type_reference, value) => {
  navs.value = navs.value.map(nav => (nav.type === field_type_reference ? { ...nav, number: value } : nav));
};

const navSetIcon = (field_type_reference, icon) => {
  navs.value = navs.value.map(nav => (nav.type === field_type_reference ? { ...nav, icon } : nav));
};

const navClearIcon = field_type_reference => {
  navs.value = navs.value.map(nav => (nav.type === field_type_reference ? { ...nav, icon: null } : nav));
};

const navSetVisible = field_type_reference => {
  navs.value = navs.value.map(nav => (nav.type === field_type_reference ? { ...nav, visible: true } : nav));
};

const navSetHidden = field_type_reference => {
  navs.value = navs.value.map(nav => (nav.type === field_type_reference ? { ...nav, visible: false } : nav));
};

const setNewClaimFormOptions = claimTypes => {
  if (!claimTypes) return;

  allClaimForms.value = allClaimForms.value.map(claim => {
    const currentClaim = claimTypes.find(claimType => claimType.claim_form_type === claim.claim_form_type);

    if (currentClaim) {
      return {
        ...claim,
        display_name: currentClaim.name,
        claim_id: claim.claim_id
      };
    }
    return claim;
  });

  newClaimFormOptions.value = claimTypes.filter(
    claimType => !allClaimForms.value.some(claimForm => claimForm.claim_form_type === claimType.claim_form_type)
  );
};

const setAddIcon = () => {
  if (newClaimFormOptions.value?.length && allClaimForms.value?.length < MAX_CLAIM_FORMS.value) {
    if (
      (newClaimFormOptions.value.length > 1 && allClaimForms.value[0]?.insurer_id === 6) ||
      newClaimFormOptions.value.length <= 1 ||
      allClaimForms.value.length === 0
    ) {
      if (!claimformData.value) {
        navSetIcon('claimForm', 'Add');
      }
    } else {
      navClearIcon('claimForm');
      navClearIcon('invoice');
    }
  } else {
    navClearIcon('claimForm');
    navClearIcon('invoice');
  }
};

const generateCompositePdf = async () => {
  if (!childComponentRefComposite.value) {
    return handleApiError(null, 'Composite form component not ready');
  }

  const { error } = await withLoading(
    async () => {
      await childComponentRefComposite.value.pdfDownloadCompositeForm();
      return { success: true };
    },
    {
      errorMessage: 'Error generating composite PDF',
      timeout: 60000
    }
  );

  if (error) return;
};

const switchTab = type => {
  if (type === selectedTab.value) return;
  if (type === 'claimForm' && newClaimFormTabOpen.value) {
    newClaimFormTabOpen.value = false;
  }
  selectedTab.value = type;
};

const removeFileFromList = fileName => {
  files.value = files.value.filter(file => file.file_name !== fileName);
  filesCount.value = files.value.length;
  navSetNumber('attachments', filesCount.value);
};

const refreshFiles = async () => {
  const { error } = await withLoading(
    async () => {
      await Promise.all([listFiles(), listInsurerPaymentFiles()]);
      return { success: true };
    },
    {
      errorMessage: 'Error refreshing files'
    }
  );

  if (error) return;
};

const deleteFirstForm = value => {
  if (value === true) {
    showForm.value = false;
  }
  showForm.value = !showForm.value;

  removeSelectedItem.value = null;
  if (!showForm.value) {
    removeSelectedItem.value = claimData.value;
  }
};

const backButton = () => {
  if (newClaimFormTabOpen.value) {
    closeAddingModal();
  } else {
    if (_.isEqual(claimData.value, claimDataPreviousState.value)) {
      store.commit('setTextBoxDisabled', true);
      claimDataPreviousState.value = null;
    } else {
      store.commit('setRevertModal', true);
    }
  }
};

const closeAddingModal = () => {
  if (claimFormNew.value) {
    claimFormNew.value.closeAddingModal();
  }
};

const updateHeaderInfo = ({ appId, mrn }) => {
  if (claim.value) {
    claim.value.hospital_appointment_id = appId;
    claim.value.patient.mrn = mrn;
  }
};

const scrollToField = async elementId => {
  const { error } = await withLoading(
    async () => {
      let claimTab = false;

      if (selectedTab.value !== 'claimForm') {
        claimTab = true;
        selectedTab.value = 'claimForm';
      }

      await nextTick();
      disableTextBox();

      await nextTick();
      setTimeout(
        () => {
          const element = document.getElementById(elementId);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            element.focus({ preventScroll: true, focusVisible: true });
          }
        },
        claimTab ? 400 : 0
      );

      return { success: true };
    },
    {
      errorMessage: 'Error scrolling to field'
    }
  );

  if (error) return;
};

const setPreviousFormState = async newValue => {
  const { error } = await withLoading(
    async () => {
      if (newValue) {
        claimDataPreviousState.value = JSON.parse(JSON.stringify(newValue));
      } else {
        if (!claimDataPreviousState.value) {
          throw new Error('No previous state available');
        }
        claimData.value = claimDataPreviousState.value;
        claimDataPreviousState.value = null;
      }
      return { success: true };
    },
    {
      errorMessage: 'Error setting previous form state'
    }
  );

  if (error) return;
};

const resetState = () => {
  allClaimFetchedData.value = [];
  claimData.value = null;
  claimDataPreviousState.value = null;
  claimTypes.value = null;
  allClaimForms.value = [];
  breadcrumbs.value = [];
  selectedTab.value = 'claimForm';
  showManageModal.value = false;
  files.value = [];
  filesCount.value = 0;
  uploadVal.value = false;
  paymentFiles.value = [];
  insurerPaymentFiles.value = null;
  loadingClaimToggle.value = false;
  isLoading.value = false;
  newClaimFormTabOpen.value = false;
  newClaimFormOptions.value = [];
  showForm.value = true;
  removeSelectedItem.value = null;
};

onBeforeUnmount(() => {
  store.commit('setTextBoxDisabled', false);
});
</script>

<style lang="scss" scoped>
.third-width {
  width: calc(100% - 400px);
}

.full-width {
  width: 100%;
}

.addAttachments {
  background-color: #d9d9d9;
  border-width: 0.1px;
}

.modalDelete {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.switch-container {
  width: 50px;
}
</style>
