<script setup>
import { reactive, computed } from 'vue';

// Define custom events for row click and selection
const emit = defineEmits(['row-click', 'row-select']);

// Define component props for headers and data
const props = defineProps({
  headers: { default: null }, // Headers to define columns in the table
  data: { default: null } // Data for populating table rows
});

// Helper function to format currency values with Euro symbol
const formatCurrency = value => {
  if (isNaN(value) || value === null || value === '') {
    // Validate that the value is numeric
    return 'ERROR'; // Return 'ERROR' if the value is not valid
  }
  const num = parseFloat(value).toFixed(2);
  return `€ ${num}`; // Format as Euro currency
};

// Computed to extract claims data from props
const claims = computed(() => props.data);

// Reactive state for sorting, row highlighting, and row selection
const state = reactive({
  highlightedRow: -1, // Tracks the highlighted row index
  sortedColumn: '', // Column being sorted
  sortDirection: 'asc', // Sorting direction (ascending or descending)
  selectedRow: null // Tracks the selected row index
});

// Computed for sorting claims based on the selected column and sort direction
const sortedClaims = computed(() => {
  if (!state.sortedColumn) return claims.value; // If no sorting column is selected, return the original data

  // Sort the data based on the current sort direction and column
  return [...claims.value].sort((a, b) => {
    const modifier = state.sortDirection === 'asc' ? 1 : -1;
    if (a[state.sortedColumn] < b[state.sortedColumn]) return -1 * modifier;
    if (a[state.sortedColumn] > b[state.sortedColumn]) return 1 * modifier;
    return 0;
  });
});

// Helper function to get nested values from objects (e.g., for accessing nested properties)
const getNestedValue = (obj, path) => {
  if (!path) return undefined; // If no path is provided, return undefined
  const parts = path.replace(/\[(\w+)\]/g, '.$1').split('.'); // Split the path into parts
  return parts.reduce((acc, part) => {
    if (acc && part in acc) {
      // Check if part exists in the object
      return acc[part];
    } else {
      return undefined; // Return undefined if the path is invalid
    }
  }, obj);
};

// Methods to handle sorting columns in ascending order
const sortUp = key => {
  state.sortedColumn = key; // Set the column to be sorted
  state.sortDirection = 'asc'; // Set sort direction to ascending
};

// Method to handle sorting columns in descending order
const sortDown = key => {
  state.sortedColumn = key;
  state.sortDirection = 'desc'; // Set sort direction to descending
};

// Method to handle row click events and emit the clicked row
const lineClick = (row, rowIndex) => {
  selectRow(rowIndex); // Select the row when clicked
  emit('row-click', row); // Emit the row click event with the row data
};

// Methods to handle row highlighting on mouse hover
const highlightRow = index => {
  state.highlightedRow = index; // Set the row as highlighted
};

// Method to reset row highlighting when mouse leaves
const resetRowColor = () => {
  state.highlightedRow = -1; // Reset highlighted row
};

// Helper function to format dates in DD-MM-YYYY format
const formatDate = date => {
  if (!date) {
    return 'ERROR'; // Return error if date is invalid
  }
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, '0');
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const year = d.getFullYear();
  return `${day}-${month}-${year}`; // Format date as DD-MM-YYYY
};

// Function to handle row selection and emit selection event
const selectRow = (rowIndex, event) => {
  if (event) {
    event.stopPropagation(); // Prevent event bubbling when clicking select button
  }
  if (state.selectedRow === rowIndex) {
    state.selectedRow = null; // Deselect if already selected
  } else {
    state.selectedRow = rowIndex; // Select new row
  }
  // Emit selected row data or null if deselected
  emit('row-select', state.selectedRow !== null ? sortedClaims.value[state.selectedRow] : null);
};

// Check if the "SELECTED" column exists in headers
// const hasSelectedColumn = computed(() => props.headers.some(header => header.label === 'SELECTED'));
</script>

<template>
  <table class="w-full">
    <thead>
      <tr>
        <!-- Render table headers dynamically based on the headers prop -->
        <th
          v-for="header in headers"
          :key="header.key"
          class="header-cell-table font-roboto text-[16px]"
          :class="{ 'text-center': header.label === 'SELECTED' }"
          :style="{ width: header.width + 'px', position: 'relative' }"
        >
          {{ header.label }}
          <!-- Add sorting icons with click handlers for sortable columns -->
          <span v-if="header.label !== 'SELECTED'" class="sort-arrows">
            <i class="fas fa-caret-up sort-up" @click="sortUp(header.key)"></i>
            <i class="fas fa-caret-down sort-down" @click="sortDown(header.key)"></i>
          </span>
        </th>
      </tr>
    </thead>
    <tbody>
      <!-- Render table rows dynamically based on sorted claims -->
      <tr
        v-for="(row, rowIndex) in sortedClaims"
        :key="rowIndex"
        class="hover:bg-[#00A3F4] hover:bg-opacity-50"
        :class="[rowIndex % 2 === 0 ? 'bg-white' : 'bg-[#EEEFF3]', { 'selected-row': rowIndex === state.selectedRow }]"
        @mouseover="highlightRow(rowIndex)"
        @mouseout="resetRowColor(rowIndex)"
        style="cursor: pointer"
        @click="lineClick(row, rowIndex)"
        :data-test-id="'access-dashboard-row-' + rowIndex"
      >
        <!-- Render cells for each column in the row -->
        <td
          v-for="(header, index) in headers"
          :key="header.key"
          :class="{ 'table-cell-left': header.position, 'table-cell': !header.position }"
          :style="{ width: header.width + 'px' }"
          :data-test-id="'access-dashboard-row-' + rowIndex + ' column ' + index"
        >
          <!-- Render selection button for SELECTED column -->
          <template v-if="header.label === 'SELECTED'">
            <div class="select-button-container">
              <button
                @click="selectRow(rowIndex, $event)"
                class="select-button"
                :class="{ selected: rowIndex === state.selectedRow }"
              ></button>
            </div>
          </template>

          <!-- Render regular text for standard columns -->
          <span
            :data-test-id="id + '-text-third-' + rowIndex"
            v-else-if="!header.type && !header.type2 && !header.date"
            class="font-roboto text-[16px]"
          >
            {{ row[header.key] }}
          </span>

          <!-- Render formatted currency for monetary values -->
          <span
            :data-test-id="id + '-text-first-' + rowIndex"
            v-else-if="!header.date && header.type2"
            class="font-roboto text-[16px]"
          >
            {{ formatCurrency(getNestedValue(row, header.key)) }}
          </span>

          <!-- Render formatted dates for date columns -->
          <span :data-test-id="id + '-date-' + rowIndex" v-else-if="header.date" class="font-roboto text-[16px]">
            {{ formatDate(row[header.key]) }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style scoped lang="scss">
/* Header cell styling */
.header-cell-table {
  background-color: #cacdd6;
  color: black;
  font-weight: bold;
  text-align: left;
  white-space: normal;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: normal;
  margin: 5px;
  height: 40px;
  position: relative;
  padding-left: 10px;

  &.text-center {
    text-align: center;
    padding-left: 0;
  }
}

/* Table cell styling */
.table-cell,
.table-cell-left {
  word-wrap: break-word;
  vertical-align: top;
  text-align: left;
  border-bottom: 1px solid #d1d1d1;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* Sorting arrows styling */
.sort-arrows {
  position: absolute;
  top: 5px;
  width: 25px;
  padding-left: 5px;
}

.sort-arrows i {
  cursor: pointer;
  opacity: 0.5;
}

.sort-arrows i.active {
  opacity: 1;
}

/* Selection button container styling */
.select-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* Selection button styling */
.select-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid black;
  background-color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

/* Selected state styling */
.select-button.selected {
  border-color: #00a3f4;
}

/* Selected button inner circle */
.select-button.selected::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #00a3f4;
}

/* Selected row styling */
.selected-row {
  background-color: #e6f7ff !important;
}
</style>
