<template>
  <div>
    <div class="ml-[65px]">
      <div id="claims-dashboard-table" data-test-id="claims-dashboard-section" class="claims-section pr-4">
        <div class="flex items-center gap-5">
          <div class="grow">
            <h1
              data-test-id="claims-title"
              class="text-[2rem] leading-none font-bold text-medosync-violet-highlight mb-1"
            >
              Claims
            </h1>
          </div>

          <div>
            <button
              @click="newClaimModal = true"
              type="button"
              data-test-id="add-claim-button"
              class="flex items-center bg-medosync-blue rounded-full shadow-md px-6 py-3"
            >
              Add claim
            </button>
          </div>

          <div>
            <button
              @click="getClaimsCSV"
              type="button"
              data-test-id="download-csv-button"
              class="flex items-center bg-medosync-blue rounded-full shadow-md px-6 py-3"
            >
              Download CSV
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- New claim modal component -->
    <div class="new-claim-modal-section" data-test-id="new-claim-modal-container">
      <NewClaim v-model="newClaimModal" />
    </div>
  </div>

  <div class="grid grid-cols-5 ml-[65px] w-[calc(100%-65px)] py-5 pr-4 relative">
    <DatePickerDashboard
      ref="datePickerRef"
      class="mr-4"
      data-test-id="date-picker-dashboard"
      @update:dateFilter="handleDateFilterUpdate"
      @open="isFilterOveralyOpen = false"
      :initial-date-filter="{
        type: filterState.dateFilterType,
        start: filterState.dateFilterStart,
        end: filterState.dateFilterEnd
      }"
    />
    <div>
      <button
        type="button"
        data-test-id="more-filters-button"
        class="flex items-center border-2 border-medosync-blue rounded-full shadow-md px-6 py-3"
        @click="isFilterOveralyOpen = !isFilterOveralyOpen"
      >
        More filters<span v-if="filterState.activeFiltersCount > 0"> ({{ filterState.activeFiltersCount }})</span>
        <i class="fas fa-chevron-down ml-2"></i>
      </button>
    </div>
    <div class="col-span-2 flex flex-row-reverse">
      <button
        type="button"
        data-test-id="apply-filters-button"
        class="flex items-center bg-medosync-blue rounded-full shadow-md px-6 py-3 ml-4"
        @click="applyFilter"
      >
        Apply
      </button>
      <button
        class="underline focus:outline-none"
        type="button"
        data-test-id="reset-filters-button"
        @click="resetFilters"
      >
        Reset All
      </button>
    </div>

    <FilterOverlay
      ref="applyFilters"
      v-show="isFilterOveralyOpen"
      data-test-id="filter-overlay"
      @applied-filters="moreFilters"
      :key="filterOverlayKey"
      :initial-state="filterState"
      claim-types="claim"
    />
  </div>

  <div class="mt-5">
    <div class="ml-16 mb-6">
      <div class="relative flex items-center w-1/3">
        <input
          data-test-id="search-input"
          type="text"
          v-model="searchTerm"
          placeholder="Search table columns like e.g. App ID, patient name, MRN, insurer"
          class="search-input-field_"
          @keyup.enter="searchClaims(searchTerm)"
        />
        <i
          v-if="searchTerm"
          class="fas fa-times clear-search-icon"
          data-test-id="clear-search-button"
          @click="clearSearch"
        ></i>
        <div class="search-icon-wrapper search-icon" data-test-id="search-button" @click="searchClaims(searchTerm)">
          <i class="fas fa-search search-icon"></i>
        </div>
      </div>
    </div>

    <div class="ml-16">
      <ClaimDashboard
        :headers="headers"
        :data="claimData"
        data-test-id="claim-dashboard-table"
        @row-select="handleRowClick"
        @open-modal="newClaimModal = true"
        @reset-filters="resetFilters"
      ></ClaimDashboard>

      <PaginationButtons
        class="mb-8"
        data-test-id="pagination-controls"
        @update:page="onPageChange"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :currentPage="filterState.currentPage"
      ></PaginationButtons>
    </div>
  </div>

  <SpinnerLoader v-if="isLoading" data-test-id="spinner-loader" />
  <ToastComponent ref="toast" data-test-id="toast-component"></ToastComponent>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { OpenAPIJSON, OpenAPICSV } from 'api/openapi';
import NewClaim from '@/components/modal/NewClaim.vue';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';
import { ToastComponent } from 'ui';
import ClaimDashboard from './Claim-Dashboard.vue';
import PaginationButtons from '../../components/admission/PaginationButtons.vue';
import FilterOverlay from '../../components/dashboard/FilterOverlay.vue';
import DatePickerDashboard from '../../components/dashboard/DatePickerDashboard.vue';
import { filterStore } from '@/store/filterStore';

// Component references
const datePickerRef = ref(null);

// Get filter store methods
const { filterState, setFilters, resetFilters: resetStoreFilters } = filterStore;

// Table headers configuration
const headers = ref([
  { key: 'patient.full_name', label: 'PATIENT NAME', width: 120, type2: true },
  { key: 'patient.mrn', label: 'MRN', width: 70, type2: true },
  { key: 'hospital_appointment_id', label: 'APP ID', width: 70, date: false },
  { key: 'admitted_on', label: 'ADMITTED', width: 70, date: true },
  { key: 'discharged_on', label: 'DISCHARGED', width: 70, date: true },
  { key: 'insurance.insurer_name', label: 'INSURER', width: 120, date: false, type2: true },
  { key: 'claim_consultant.full_name', label: 'CONSULTANT', width: 70, date: false, type2: true },
  { key: 'claim_hospital_value', label: '€ HOS', width: 70, date: false, type: true },
  { key: 'claim_consultant_value', label: '€ INV', width: 70, date: false, type: true },
  { key: 'claim_paid_value', label: '€ PAID', width: 70, date: false, type: true },
  { key: 'invoice_no', label: 'INV N', width: 70, date: false },
  { key: 'claim_state_str', label: 'STATUS', width: 150, upercase: true },
  { key: 'latest_note', label: 'NOTE', width: 150, date: false },
  { key: 'claim_state', label: 'ACTION', width: 150, doublebutton: true }
]);

// State management
const newClaimModal = ref(false);
const totalItems = ref('');
const searchTerm = ref(filterState.value.searchString || '');
const claimData = ref([]);
const itemsPerPage = ref(20);
const router = useRouter();
const isLoading = ref(false);
const refToast = ref(null);
const oapi = new OpenAPIJSON();
const oapiCSV = new OpenAPICSV();
const isFilterOveralyOpen = ref(false);
const applyFilters = ref(null);
const filterOverlayKey = ref(0);

// Error handling utility function
const handleApiError = (error, customMessage) => {
  const defaultMessage = 'Something went wrong. Please try again later.';
  const errorMessage = customMessage || defaultMessage;
  refToast.value?.showToast(errorMessage, 'fa-solid fa-triangle-exclamation', 'error');
  return {
    error: true,
    message: errorMessage
  };
};

// Handle date filter updates
const handleDateFilterUpdate = ({ type, start, end }) => {
  setFilters({
    dateFilterType: type,
    dateFilterStart: start,
    dateFilterEnd: end
  });
};

// API call wrapper with loading state
const withLoading = async (apiCall, options = {}) => {
  const { loadingDelay = 300, errorMessage, retryCount = 0, timeout = 30000 } = options;
  let attempts = 0;
  const loadingTimeout = setTimeout(() => {
    isLoading.value = true;
  }, loadingDelay);

  try {
    while (attempts <= retryCount) {
      try {
        const timeoutPromise = new Promise((_, reject) => {
          setTimeout(() => reject(new Error('Operation timed out')), timeout);
        });
        const resultPromise = apiCall();
        const result = await Promise.race([resultPromise, timeoutPromise]);
        return { data: result, error: null };
      } catch (error) {
        attempts++;
        if (attempts > retryCount) throw error;
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
  } catch (error) {
    return handleApiError(error, errorMessage);
  } finally {
    clearTimeout(loadingTimeout);
    isLoading.value = false;
  }
};

// Filter management functions
const applyFilter = () => {
  if (applyFilters.value) {
    applyFilters.value.applyFilters();
  }
};

// Reset all filters to their default state
const resetFilters = () => {
  filterOverlayKey.value++;
  resetStoreFilters();

  if (datePickerRef.value) {
    datePickerRef.value.handleReset();
  }

  searchTerm.value = '';
  fetchClaims();
};

const fetchCsv = async () => {
  const { data, error } = await withLoading(
    () =>
      oapiCSV.searchClaims(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      ),
    { errorMessage: 'Failed to load claims data. Please try again.' }
  );

  if (!error) {
    return data;
  }
};

// Main claims fetching function
const fetchClaims = async () => {
  const start_idx = (filterState.value.currentPage - 1) * itemsPerPage.value + 1;
  const end_idx = start_idx + itemsPerPage.value - 1;
  const pagination = { start_idx, end_idx, page_size: null };

  let admitted = null;
  let discharged = null;

  if (
    filterState.value.dateFilterType === 'admitted' &&
    filterState.value.dateFilterStart &&
    filterState.value.dateFilterEnd
  ) {
    admitted = {
      start: filterState.value.dateFilterStart,
      end: filterState.value.dateFilterEnd
    };
  } else if (
    filterState.value.dateFilterType === 'discharged' &&
    filterState.value.dateFilterStart &&
    filterState.value.dateFilterEnd
  ) {
    discharged = {
      start: filterState.value.dateFilterStart,
      end: filterState.value.dateFilterEnd
    };
  }

  const sanitizedSearchString = filterState.value.searchString?.trim() || null;

  const currentPayload = {
    consultant:
      filterState.value.consultantId?.length > 0 ? { type: 'idlist', ids: filterState.value.consultantId } : null,
    claim_state: filterState.value.claimStatus,
    tags: filterState.value.claimTag,
    searchString: sanitizedSearchString,
    pagination,
    admitted,
    discharged
  };

  const { data, error } = await withLoading(
    () =>
      oapi.searchClaims(
        null,
        null,
        null,
        null,
        null,
        currentPayload.searchString,
        null,
        null,
        null,
        null,
        currentPayload.consultant,
        currentPayload.claim_state,
        currentPayload.admitted,
        currentPayload.discharged,
        currentPayload.tags,
        null,
        currentPayload.pagination
      ),
    { errorMessage: 'Failed to load claims data. Please try again.' }
  );

  if (!error) {
    claimData.value = data.items;
    totalItems.value = data.total_items;
  }
};

// Handle filter updates from the filter overlay
const moreFilters = async data => {
  const getActiveFilters = data => {
    const claimStatus = data.claimStatus.link === 'all' ? null : [data.claimStatus.link];
    const claimTag = data.claimTag.name === 'all' ? null : [data.claimTag.name];
    const consultants = Array.isArray(data.consultants) && data.consultants.length === 0 ? null : data.consultants;

    return {
      filters: { claimStatus, claimTag, consultants },
      count: [claimStatus, claimTag, consultants].filter(filter => filter !== null).length
    };
  };

  const { filters, count } = getActiveFilters(data);

  setFilters({
    currentPage: 1,
    consultantId: filters.consultants,
    claimStatus: filters.claimStatus,
    claimTag: filters.claimTag,
    activeFiltersCount: count
  });

  isFilterOveralyOpen.value = false;
  await fetchClaims();
};

// Search functionality
const searchClaims = async searchTerm => {
  setFilters({
    searchString: searchTerm,
    currentPage: 1
  });
  await fetchClaims();
};

// Pagination handler
const onPageChange = async newPage => {
  setFilters({
    currentPage: newPage
  });
  await fetchClaims();
};

// Clear search functionality
const clearSearch = () => {
  setFilters({
    currentPage: 1,
    searchString: ''
  });
  searchTerm.value = '';
  fetchClaims();
};

// Download claims data as CSV
const getClaimsCSV = async () => {
  fetchCsv();
};

// Handle row click navigation
const handleRowClick = item => {
  router.push({ name: 'ConsultantClaimsDetails', params: { id: item.claim_consultant_id } });
};

// Initialize component
onMounted(() => {
  fetchClaims();
});
</script>

<style scoped>
/* Search input field styles */
.search-input-field_ {
  width: 100%;
  height: 45px;
  outline: none;
  background: #f9f9f9;
  padding-right: 40px;
  padding-left: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
}

/* Search icon container styles */
.search-icon-wrapper {
  display: flex;
  align-items: center;
  background: #00a7e1;
  padding: 0 13px;
  border-radius: 0 4px 4px 0;
  height: 45px;
}

/* Search icon styles */
.search-icon {
  color: #fff;
  cursor: pointer;
}

/* Clear search icon styles */
.clear-search-icon {
  position: absolute;
  right: 55px;
  top: 50%;
  transform: translateY(-50%);
  color: #050505;
  cursor: pointer;
}
</style>
