export const FileCategoryEnum = Object.freeze({
  GeneralLetter: 'general_letter',
  GPLetter: 'g_p_letter',
  Receipt: 'receipt',
  DischargeLetter: 'discharge_letter',
  ReferralLetter: 'referral_letter',
  ClinicalNotes: 'clinical_notes',
  Claim: 'claim',
  Anaesthetist: 'anaesthetist',
  ObservationLaboratory: 'observation_laboratory',
  MinorConsultant: 'minor_consultant',
  PrimaryConsultant: 'primary_consultant',
  SupportingDocument: 'supporting_document',
  Hospital: 'hospital',
  Tax: 'tax',
  Other: 'other',
  Invoice: 'invoice',
  InsuranceRemittance: 'insurance_remittance',
  ConsultantNote: 'consultant_note',
  AppointmentData: 'appointment_data',
  ObservationUncategorised: 'observation_uncategorised',
  ExternalInvoiceData: 'external_invoice_data',
  FeedAppointments: 'feed_appointments',
  NewContractPricing: 'new_contract_pricing'
});
