<script setup>
import { ClaimPhase, ConsultantClaimStateEnum } from 'enums';
import { OpenAPIJSON } from 'api/openapi';
import { ref, onMounted, defineEmits, computed, defineProps, watch } from 'vue';

const props = defineProps({
  claimTypes: null,
  initialState: {
    type: Object,
    default: () => ({
      consultantId: null,
      claimStatus: null,
      claimTag: null,
      searchString: '',
      activeFiltersCount: 0
    })
  }
});

// Menu items configuration with their respective states and phases
const menuItems = ref([
  {
    name: 'All Claims',
    link: 'all',
    count: 0,
    active: true,
    info: 'Claims in any state'
  },
  {
    name: 'Draft Claims',
    link: ConsultantClaimStateEnum.DRAFT,
    count: 0,
    active: true,
    info: 'New claims',
    phase: ClaimPhase.PRE_SUBMIT
  },
  {
    name: 'Consultant approval requested',
    link: ConsultantClaimStateEnum.READY_FOR_CONSULTANT_APPROVAL,
    count: 0,
    active: true,
    info: 'Prepared claims awaiting sign-off from the consultant',
    phase: ClaimPhase.PRE_SUBMIT
  },
  {
    name: 'Rejected By Consultant',
    link: ConsultantClaimStateEnum.REJECTED_BY_CONSULTANT_FOR_AMENDMENT,
    count: 0,
    active: true,
    info: 'Claims that the consultant has rejected for signing and may require further changes.',
    phase: ClaimPhase.PRE_SUBMIT
  },
  {
    name: 'Consultant approved',
    link: ConsultantClaimStateEnum.CONSULTANT_APPROVED,
    count: 0,
    active: true,
    info: 'Claims that have been signed-off by the consultant. A PDF preview will now display their signature and signing-date.',
    phase: ClaimPhase.PRE_SUBMIT
  },
  {
    name: 'Ready for submission',
    link: ConsultantClaimStateEnum.READY_FOR_SEND_TO_INSURER,
    count: 0,
    active: true,
    info: 'Claims that have been signed-off by the consultant that have been marked as complete and as such now have a immutable invoice with real invoice number. The claim-pack can be downloaded',
    phase: ClaimPhase.PRE_SUBMIT
  },
  {
    name: 'Submitted',
    link: ConsultantClaimStateEnum.SENT_TO_INSURER,
    count: 0,
    active: true,
    info: 'Claims that have been submitted to the insurer/entered into Claimsure.',
    phase: ClaimPhase.POST_SUBMIT
  },
  {
    name: 'Pended',
    link: ConsultantClaimStateEnum.PENDED_WITH_HOSPITAL,
    count: 0,
    active: true,
    info: 'Submitted claims returned by the insurance company for which it has asked for additional information before adjudication can be performed.',
    phase: ClaimPhase.POST_SUBMIT
  },
  {
    name: 'Rejected',
    link: ConsultantClaimStateEnum.REJECTED,
    count: 0,
    active: true,
    info: 'Claims that have been adjudicated by insurance company and rejected for payment.',
    phase: ClaimPhase.POST_SUBMIT
  },
  {
    name: 'For Appeal',
    link: ConsultantClaimStateEnum.FOR_APPEAL,
    count: 0,
    active: true,
    info: 'Claims rejected by the insurance company that the hospital is going to appeal.',
    phase: ClaimPhase.POST_SUBMIT
  },
  {
    name: 'Part Paid',
    link: ConsultantClaimStateEnum.PART_PAID,
    count: 0,
    active: true,
    info: 'Claims that the insurer has not paid 100% of the invoice.',
    phase: ClaimPhase.POST_SUBMIT
  },
  {
    name: 'Over-paid',
    link: ConsultantClaimStateEnum.OVER_PAID,
    count: 0,
    active: true,
    info: 'Claims where the remittance total is greater than the invoice total, which likely indicates an error in the remittances',
    phase: ClaimPhase.POST_SUBMIT
  },
  {
    name: 'Paid in Full',
    link: ConsultantClaimStateEnum.PAID_IN_FULL,
    count: 0,
    active: true,
    info: 'Claims for which the sum of payments received from the insurer matches the invoiced total',
    phase: ClaimPhase.POST_SUBMIT
  },
  {
    name: 'Written Off - Appeal Denied',
    link: ConsultantClaimStateEnum.WRITE_OFF_APPEAL_DENIED,
    count: 0,
    active: true,
    phase: ClaimPhase.FINISHED
  },
  {
    name: 'Written Off - Rejected',
    link: ConsultantClaimStateEnum.WRITE_OFF_REJECTED,
    count: 0,
    active: true,
    phase: ClaimPhase.FINISHED
  },
  {
    name: 'Written Off - Billed Incorrectly',
    link: ConsultantClaimStateEnum.WRITE_OFF_BILLED_INCORRECTLY,
    count: 0,
    active: true,
    phase: ClaimPhase.FINISHED
  },
  {
    name: 'Manually Rejected',
    link: ConsultantClaimStateEnum.MANUAL_REJECTED,
    count: 0,
    active: true,
    info: 'Claims rejected for further processing and will not be submitted to the insurer.',
    phase: ClaimPhase.FINISHED
  }
]);

const emit = defineEmits(['applied-filters']);

// State management
const claimStates = ref({});
const selectedItem = ref(menuItems.value[0]);
const claimTags = ref([]);
const selectedTag = ref(null);
const consultants = ref([]);
const selectedConsultants = ref([]);
const filters = ref([]);
const searchQuery = ref('');
const medic = ref(null);
const username = ref('');

// Pagination state
const itemsPerColumn = ref(10);
const showMoreVisible = ref(false);

// Watch for changes in selected items and update filters immediately
watch([selectedItem, selectedTag, selectedConsultants], () => {
  filters.value = {
    claimStatus: selectedItem.value,
    claimTag: selectedTag.value,
    consultants: selectedConsultants.value.map(consultant => consultant.medic.ro.consultant_id)
  };
});

// Filter consultants based on search query
const filteredConsultants = computed(() => {
  if (!searchQuery.value.trim()) {
    return consultants.value;
  }
  return consultants.value.filter(consultant =>
    consultant.medic.ro.full_name.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

// Modified computed properties for dynamic columns
const columnConsultants = computed(() => {
  const filtered = filteredConsultants.value;
  const total = filtered.length;
  const columns = [];

  // Calculate items per column
  for (let i = 0; i < 3; i++) {
    const start = i * itemsPerColumn.value;
    const end = start + itemsPerColumn.value;
    columns.push(filtered.slice(start, end));
  }

  // Update show more visibility
  showMoreVisible.value = total > itemsPerColumn.value * 3;

  return columns;
});

// Check if all consultants are selected
const allConsultantsSelected = computed(() => {
  return selectedConsultants.value.length === consultants.value.length;
});

// Function to handle show more click
const handleShowMore = () => {
  if (itemsPerColumn.value === 10) {
    itemsPerColumn.value = 15;
  } else {
    itemsPerColumn.value += 5;
  }
};

// API Calls
const getClaimCount = async () => {
  const oapi = new OpenAPIJSON();

  const response = props.claimTypes === 'claim' ? await oapi.getClaimCount() : await oapi.getCompositeClaimCount();

  claimStates.value = response;
};

const getClaimTagsCount = async () => {
  const claimState = selectedItem.value && selectedItem.value.link !== 'all' ? [selectedItem.value.link] : null;
  const oapi = new OpenAPIJSON();
  console.log(props.claimTypes);
  const response =
    props.claimTypes === 'claim'
      ? await oapi.getClaimTagsCount(null, null, claimState)
      : await oapi.getCompositeClaimTagsCount();

  if (response.length > 0) {
    for (const item of response) {
      item.on = false;
    }
  }

  const allTag = {
    name: 'all',
    count: -1,
    on: true,
    active: true
  };

  response.splice(0, 0, allTag);
  claimTags.value = response;

  if (!selectedTag.value && claimTags.value.length > 0) {
    selectedTag.value = claimTags.value[0];
  }
};

// Load user preferences
const listPreference = () => {
  const ls = JSON.parse(localStorage.getItem('preferences'));
  const tagsLayout = ls?.tagsLayout;
  const menuLayout = ls?.menuLayout;

  if (tagsLayout) {
    claimTags.value.forEach(tag => {
      tagsLayout.forEach(item => {
        if (tag.name.toLowerCase() === item.name.toLowerCase()) {
          tag.active = item.active;
        }
      });
    });
  }

  if (menuLayout) {
    menuItems.value.forEach(m => {
      menuLayout.forEach(item => {
        if (m.name.toLowerCase() === item.name.toLowerCase()) {
          m.active = item.active;
        }
      });
    });
  }
};

// Initial data fetch
const fetch = async () => {
  await getClaimCount();
  await getClaimTagsCount();

  let object = {};
  let count = 0;
  object = claimStates.value;

  for (const property in object) {
    menuItems.value.forEach(item => {
      if (`${property}` === item.link) {
        item.count = parseInt(`${object[property]}`);
      }
    });
    count += parseInt(`${object[property]}`);
  }

  menuItems.value.find(item => item.link === 'all').count = count;
};

const fetchMedics = async () => {
  const oapi = new OpenAPIJSON();
  const response = await oapi.searchDoctors(null, null, null, true, false);
  consultants.value = response;

  // Restore selected queries to their initial state
  if (props.initialState.consultantId?.length > 0) {
    const savedConsultants = consultants.value.filter(consultant =>
      props.initialState.consultantId.includes(consultant.medic.ro.consultant_id)
    );
    selectedConsultants.value = savedConsultants;
  }
};

// Event Handlers
const handleMenuItemClick = async item => {
  selectedItem.value = item;
  await getClaimTagsCount();
};

const handleTagClick = tag => {
  selectedTag.value = tag;
};

const toggleAllConsultants = () => {
  if (allConsultantsSelected.value) {
    selectedConsultants.value = [];
  } else {
    selectedConsultants.value = [...consultants.value];
  }
};

const handleConsultantClick = consultant => {
  const index = selectedConsultants.value.findIndex(c => c.medic.ro.full_name === consultant.medic.ro.full_name);

  if (index === -1) {
    selectedConsultants.value.push(consultant);
  } else {
    selectedConsultants.value.splice(index, 1);
  }
};

// Apply selected filters
const applyFilters = () => {
  const consultantIds = selectedConsultants.value.map(item => item.medic.ro.consultant_id);

  filters.value = {
    claimStatus: selectedItem.value,
    claimTag: selectedTag.value,
    consultants: consultantIds
  };

  emit('applied-filters', filters.value);
};

// Initialize component
onMounted(async () => {
  await fetch();
  listPreference();
  await fetchMedics();

  // Restore selected claim status
  if (props.initialState.claimStatus) {
    const savedStatus = menuItems.value.find(item => item.link === props.initialState.claimStatus[0]);
    if (savedStatus) {
      selectedItem.value = savedStatus;
      await getClaimTagsCount();
    }
  }

  // Restore selected tag
  if (props.initialState.claimTag) {
    const savedTag = claimTags.value.find(tag => tag.name === props.initialState.claimTag[0]);
    if (savedTag) {
      selectedTag.value = savedTag;
    }
  }

  try {
    const storedConfig = localStorage.getItem('me');
    const config = JSON.parse(storedConfig);
    medic.value = config.medic;
    username.value = config.username;
  } catch (error) {
    // Handle config parsing error silently
  }
});

defineExpose({
  applyFilters
});
</script>

<template>
  <div
    class="bg-white border border-medosync-light-grey w-full absolute top-[80px] p-3 shadow z-50 left-[-1px]"
    data-test-id="claims-filter-container"
  >
    <!-- Search Section -->
    <div v-if="!medic" class="grid grid-cols-5 gap-5 mb-4">
      <div></div>
      <div></div>
      <div class="col-span-3">
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Search list for consultant name"
          class="border border-black bg-[#F9F9F9] rounded w-full p-2 placeholder:text-gray-600 placeholder:text-sm placeholder:font-sans"
          style="--tw-placeholder-color: #545454; font-family: 'Inter', sans-serif"
          data-test-id="consultant-search-input"
        />
      </div>
    </div>

    <!-- Main Grid Layout -->
    <div class="grid grid-cols-5 gap-5">
      <!-- Claim Status Section -->
      <div data-test-id="claim-status-section">
        <h5 class="text-xs font-bold" data-test-id="claim-status-title">Claim status</h5>
        <span v-for="(item, index) in menuItems" :key="index">
          <div
            v-if="item.active"
            class="nav-item"
            :class="{ 'nav-item-active': selectedItem === item }"
            :title="item.info"
            @click="handleMenuItemClick(item)"
            :data-test-id="`claim-status-item-${item.link}`"
          >
            <div class="item-name" :data-test-id="`claim-status-name-${item.link}`">{{ item.name }}</div>
            <div class="item-count" :data-test-id="`claim-status-count-${item.link}`">{{ item.count }}</div>
          </div>
        </span>
      </div>

      <!-- Claim Tags Section -->
      <div data-test-id="claim-tags-section">
        <h5 class="text-xs font-bold" data-test-id="claim-tags-title">Claim Tags</h5>
        <span v-for="(tag, index) in claimTags" :key="index">
          <div
            class="nav-item"
            :class="{ 'nav-item-active': selectedTag === tag }"
            @click="handleTagClick(tag)"
            :data-test-id="`claim-tag-item-${tag.name}`"
          >
            <div class="item-name" :data-test-id="`claim-tag-name-${tag.name}`">{{ tag.name }}</div>
            <div class="item-count" v-if="tag.count > 0" :data-test-id="`claim-tag-count-${tag.name}`">
              {{ tag.count }}
            </div>
          </div>
        </span>
      </div>

      <!-- Consultant Section -->
      <div class="col-span-3" data-test-id="consultants-section">
        <h5 class="text-xs font-bold mb-4" data-test-id="consultants-title">Consultant</h5>

        <!-- Single Medic View -->
        <div v-if="medic" class="space-y-2">
          <div class="mb-4 flex items-center">
            <div class="relative w-10 h-10">
              <input
                type="checkbox"
                class="absolute inset-0 w-10 h-10 opacity-0 cursor-pointer z-10"
                :checked="true"
                data-test-id="medic-checkbox"
              />
              <div
                class="w-10 h-10 rounded border-2 pointer-events-none"
                :class="[true ? 'border-blue-400 bg-blue-400' : 'border-black']"
              >
                <svg
                  v-if="true"
                  class="w-6 h-6 text-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  data-test-id="medic-checkbox-icon"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
              </div>
            </div>
            <label class="text-sm ml-4" data-test-id="medic-username">{{ username }}</label>
          </div>
        </div>

        <!-- Multiple Consultants View -->
        <div v-else>
          <!-- All Consultants Option -->
          <div class="mb-6 flex items-center pb-4" data-test-id="all-consultants-container">
            <div class="relative w-10 h-10">
              <input
                type="checkbox"
                class="absolute inset-0 w-10 h-10 opacity-0 cursor-pointer z-10"
                :checked="allConsultantsSelected"
                @change="toggleAllConsultants"
                data-test-id="all-consultants-checkbox"
              />
              <div
                class="w-10 h-10 rounded border-2 pointer-events-none"
                :class="[allConsultantsSelected ? 'border-blue-400 bg-blue-400' : 'border-black']"
              >
                <svg
                  v-if="allConsultantsSelected"
                  class="w-6 h-6 text-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  data-test-id="all-consultants-checkbox-icon"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
              </div>
            </div>
            <label class="text-sm ml-4 font-semibold" data-test-id="all-consultants-label">All Consultants</label>
          </div>

          <!-- Consultants Grid -->
          <div class="grid grid-cols-3 gap-4" data-test-id="consultants-grid">
            <!-- Dynamic Columns -->
            <div
              v-for="(column, columnIndex) in columnConsultants"
              :key="columnIndex"
              class="space-y-2 min-h-[40px]"
              :data-test-id="`consultants-column-${columnIndex + 1}`"
            >
              <div
                v-for="consultant in column"
                :key="consultant.medic.ro.consultant_id"
                class="mb-4 flex items-center break-inside-avoid"
                :data-test-id="`consultant-item-${consultant.medic.ro.consultant_id}`"
              >
                <div class="relative w-10 h-10">
                  <input
                    type="checkbox"
                    class="absolute inset-0 w-10 h-10 opacity-0 cursor-pointer z-10"
                    :checked="selectedConsultants.some(c => c.medic.ro.full_name === consultant.medic.ro.full_name)"
                    @change="handleConsultantClick(consultant)"
                    :data-test-id="`consultant-checkbox-${consultant.medic.ro.consultant_id}`"
                  />
                  <div
                    class="w-10 h-10 rounded border-2 pointer-events-none"
                    :class="[
                      selectedConsultants.some(c => c.medic.ro.full_name === consultant.medic.ro.full_name)
                        ? 'border-blue-400 bg-blue-400'
                        : 'border-black'
                    ]"
                  >
                    <svg
                      v-if="selectedConsultants.some(c => c.medic.ro.full_name === consultant.medic.ro.full_name)"
                      class="w-6 h-6 text-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      :data-test-id="`consultant-checkbox-icon-${consultant.medic.ro.consultant_id}`"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                </div>
                <label class="text-sm ml-4" :data-test-id="`consultant-name-${consultant.medic.ro.consultant_id}`">
                  {{ consultant.medic.ro.full_name }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Show More Button -->
    <div v-if="showMoreVisible" class="text-center mt-4">
      <button @click="handleShowMore" class="text-medosync-black text-sm underline" data-test-id="show-more-button">
        Show More
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
// Navigation item styling
.nav-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
  margin-top: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  color: #0c276c;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  .item-name {
    font-size: 14px;
    float: left;
    width: 100px;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .item-count {
    display: flex;
    height: 100%;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    align-items: center;
    align-self: center;
    padding: 3px 6px 1px 6px;
    border-radius: 20px;
    background: #00a7e1;
    color: #fff;
  }
}

// Active state styling for navigation items
.nav-item-active {
  background-color: #00a7e1;
  color: white;

  .item-count {
    background: white;
    color: #0c276c;
  }
}

// Active state styling for tag items
.tag-item-active {
  border-left: 5px solid #00a7e1;
  background: #00a7e1;
  color: white;

  .item-count {
    background: white;
    color: black;
  }
}
</style>
