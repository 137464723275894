<script setup lang="ts">
import { onMounted, ref, watch, computed } from 'vue';
import type { Ref } from 'vue';

import { FileCategoryEnum } from 'enums';
import { OpenAPIJSON } from 'api';
import { toBase64 } from 'utils';

import FullscreenOverlay from '@/components/modal/FullscreenOverlay.vue';
import DownloadInsuranceContractTemplate from '@/views/user-management/DownloadInsuranceContractTemplate.vue';
import UploadFiles from '@/components/misc/UploadFiles.vue';
import { ToastComponent } from 'ui';
import DefaultModal from '@/components/claim-forms/components/DefaultModal.vue';

const props = defineProps({
  contractList: { type: Array as () => any[], required: true },
  insurer: { type: Object as () => any, required: true }
});
const emits = defineEmits(['close']);

const handleModalClose = (newContractId: number | null = null) => {
  file.value = null;
  emits('close', newContractId ? newContractId : null);
};

const file = ref<File | null>(null);
const fileName = ref<string | null>(null);
const isFileUploadSuccess: Ref<boolean> = ref(false);
const isLoading = ref(false);
const openApi = new OpenAPIJSON();
const toast = ref<any>(null);
const errorMessage = ref<string | null>(null);
const errorNoReuploadNeeded = ref(false);
const errorDetailedByLine: Ref<any> = ref([]);
const currentValidContract = ref(null);
const contractName = ref<string | null>(null);
const startDate = ref<string | null>(null);
const sameYearWarning = ref(false);
const sameYearWarningText = ref('');
const newContractId = ref(null);

const uploadButtonDisabled = computed(() => {
  return !file.value || !contractName.value || !startDate.value;
});

watch(file, newFile => {
  if (newFile) {
    resetErrorMessage();
  }
});

const setCurrentValidContract = () => {
  const today = new Date();
  const pastDatesOnly = props.contractList.filter(contract => new Date(contract.valid_from) <= today);
  pastDatesOnly.sort((a, b) => {
    const diffA = Math.abs(today - new Date(a.valid_from));
    const diffB = Math.abs(today - new Date(b.valid_from));
    return diffA - diffB;
  });
  currentValidContract.value = pastDatesOnly?.[0] || null;
};
const isFutureDate = (dateString: string): boolean => {
  const inputDate = new Date(dateString);
  const today = new Date();
  inputDate.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);
  return inputDate > today;
};
const sameContractsByYear = (dateString: string): string | null => {
  const inputYear = new Date(dateString).getFullYear();
  const contractsByYear = props.contractList.filter(
    contract => new Date(contract.valid_from).getFullYear() === inputYear
  );
  if (contractsByYear.length > 0) {
    const contracts = contractsByYear.map(contract => contract.contract_name).join(', ');
    return `The new contract you are trying to upload has the same year as an existing contract: ${contracts}. Please make sure that the new contract is not a duplicate.`;
  }
  return null;
};
const validateFieldsAndFile = () => {
  resetErrorMessage();
  if (!file.value) return;

  if (!isFutureDate(startDate.value as string)) {
    errorNoReuploadNeeded.value = true;
    errorMessage.value = 'The start date should be set to a future date.';
    isLoading.value = false;
    return;
  }
  // check if contract date is the same year as any other contract - if so, show warning modal
  const sameYearContract = sameContractsByYear(startDate.value as string);
  if (sameYearContract) {
    sameYearWarningText.value = sameYearContract;
    sameYearWarning.value = true;
    isLoading.value = false;
    return;
  } else {
    uploadFile();
  }
};
const uploadFile = async () => {
  try {
    if (sameYearWarning.value) {
      sameYearWarning.value = false;
    }
    isLoading.value = true;

    const base64 = await toBase64(file.value);
    const currentTime = new Date().getTime().toString();
    // check file type - it should be excel
    if (file.value.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      errorMessage.value = 'File type not supported. Please use template file.';
      resetFile();
      isFileUploadSuccess.value = false;
      isLoading.value = false;
      return;
    }

    const { contract } = await openApi.file_upload_contract_with_prices({
      contract_name: contractName.value,
      valid_from: startDate.value,
      data: base64.split(',')[1],
      mime_type: file.value.type,
      file_category: FileCategoryEnum.NewContractPricing
    });
    newContractId.value = contract.id;

    isLoading.value = false;
    isFileUploadSuccess.value = true;
  } catch (error) {
    processErrorMessages(error);
    isLoading.value = false;
    isFileUploadSuccess.value = false;
  }
};
const processErrorMessages = error => {
  const responseErrorMessage = error?.response?.data?.reason;
  if (responseErrorMessage.includes('ICON-001')) {
    errorNoReuploadNeeded.value = true;
    errorMessage.value = `Insurance contract name ${contractName.value} is invalid. Please make sure that the new contract name is at least 4 characters long.`;
  } else if (responseErrorMessage.includes('ICON-002')) {
    errorNoReuploadNeeded.value = true;
    errorMessage.value = `Insurance contract name ${contractName.value} already exist. Please make sure that the new contract name is unique and not a duplicate.`;
  } else if (responseErrorMessage.includes('ICON-004')) {
    errorNoReuploadNeeded.value = true;
    const duplicateContract = responseErrorMessage.replace(/^ICON-004\s*/i, '');
    errorMessage.value = `${duplicateContract}. Please make sure that the new contract is not a duplicate.`;
  } else if (
    responseErrorMessage.includes('ICON-006') ||
    responseErrorMessage.includes('ICON-007') ||
    responseErrorMessage.includes('ICON-008') ||
    responseErrorMessage.includes('ICON-009')
  ) {
    errorMessage.value =
      'An error occurred while uploading and processing file. Please use the template file provided.';
  } else if (responseErrorMessage.includes('ICON-010')) {
    const missingHeaders = responseErrorMessage.replace(/^ICON-010 Error\s*/i, '');
    errorMessage.value = `The file is ${missingHeaders}. Please check the file headers or use the template file provided.`;
  } else if (responseErrorMessage.includes('ICON-011')) {
    errorMessage.value = 'The file contains no data records. Please check the file and try again.';
  } else if (responseErrorMessage.includes('ICON-013')) {
    errorNoReuploadNeeded.value = true;
    errorMessage.value = 'The start date should be set to a future date.';
  } else if (responseErrorMessage.includes('ICON-012')) {
    errorMessage.value = 'The file contains invalid data. Please check the file and try again.';
    const errorData = JSON.parse(responseErrorMessage.split(':').slice(1).join(':').trim());
    errorData.forEach(error => {
      errorDetailedByLine.value.push({
        row: error.row,
        error: error.error,
        description: setErrorDescription(error.error)
      });
    });
  } else {
    errorMessage.value =
      'An error occurred while uploading and processing the file. Please check the file format, data, and try again. If the issue persists try using the template file provided or contact support.';
  }
};
const setErrorDescription = errorValue => {
  if (errorValue.includes('Missing')) {
    const fieldName = errorValue.replace(/^Missing\s*/i, '').trim();
    return `The required field: "${fieldName}" is empty. Please review the form, and fill in all required fields before submitting again.`;
  } else if (errorValue.includes('Invalid')) {
    const fieldName = errorValue.replace(/Invalid | format/g, '').trim();
    return `The value you entered in the ${fieldName} field is invalid. Please review the form, and ensure that all values are in the correct format before submitting again.`;
  } else if (errorValue.includes('Duplicate')) {
    return `${errorValue}. Please review the form, and ensure that all procedure codes are unique before submitting again.`;
  } else {
    return `An unexpected issue has occurred with the value you entered. Please review the form, and ensure that all values are correct before submitting again.`;
  }
};
const downloadErrorList = () => {
  const rows = [
    ['Table row', 'Error name', 'Description of error'],
    ...errorDetailedByLine.value.map(error => [error.row, error.error, `"${error.description.replace(/"/g, '""')}"`])
  ];

  let csvContent = 'data:text/csv;charset=utf-8,' + rows.map(e => e.join(',')).join('\n');

  var encodedUri = encodeURI(csvContent);
  var link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  const date = new Date().toISOString().split('T')[0].replace(/-/g, '');
  link.setAttribute('download', `${date}_ErrorList-${file.value?.name}.csv`);
  document.body.appendChild(link);

  link.click();
};
const removeFile = () => {
  resetFile();
  resetErrorMessage();
};
const resetFile = () => {
  file.value = null;
  fileName.value = null;
};
const resetErrorMessage = () => {
  isFileUploadSuccess.value = false;
  errorMessage.value = null;
  errorDetailedByLine.value = [];
  errorNoReuploadNeeded.value = false;
};
const openNewContractWindow = () => {
  if (newContractId.value) {
    handleModalClose(newContractId.value);
  }
};

onMounted(() => {
  setCurrentValidContract();
});
</script>

<template>
  <FullscreenOverlay @close-modal="handleModalClose">
    <template #header>
      <h2 class="text-2xl font-semibold text-medosync-violet-highlight mb-5">Upload Insurance Contract</h2>
    </template>
    <template #body>
      <DownloadInsuranceContractTemplate :currentValidContract="currentValidContract" :insurer="insurer" />

      <div class="border-2 border-black p-4 my-5">
        <h3 class="text-xl font-medium mb-5" :class="{ 'text-medosync-red': errorMessage }">
          {{
            errorMessage
              ? 'Upload Insurance Contract - Error(s) occurred. Data was not uploaded.'
              : 'Upload Insurance Contract'
          }}
        </h3>
        <template v-if="errorMessage">
          <div class="pt-2 pb-12">
            <h4 class="text-medosync-red font-bold">{{ errorMessage }}</h4>
          </div>
        </template>
        <UploadFiles
          v-if="!file"
          v-model="file"
          :multiple="false"
          :showButtons="true"
          :no-close="true"
          @cancel="handleModalClose"
        />
        <template v-if="file">
          <div class="mb-20">
            <div v-if="!isFileUploadSuccess && !isLoading" class="flex flex-col">
              <div class="flex justify-between items-center bg-medosync-grey px-4 py-2">
                <h5>
                  {{ file.name }}
                </h5>
                <i class="fa-regular fa-trash-can cursor-pointer text-xl" @click="removeFile"></i>
              </div>
            </div>

            <div
              v-else-if="!isFileUploadSuccess && isLoading"
              class="px-5 bg-[#F9F9F9] flex flex-col justify-around align-center h-full"
            >
              <h4>Your file is uploading and processing. Please wait... this can take up to 5 minutes.</h4>
              <div class="flex justify-start items-center">
                <i class="fa-solid fa-spinner fa-spin-pulse text-medosync-blue fa-3x me-6" />
                <p>{{ file.name }}</p>
              </div>
            </div>
            <div
              v-if="isFileUploadSuccess && !isLoading"
              class="flex items-center border-2 bg-[#F9F9F9] p-4"
              :class="errorMessage ? 'border-medosync-red' : 'border-medosync-dark-green'"
            >
              <i v-if="!errorMessage" class="fa-solid fa-check text-medosync-dark-green fa-xl me-4" />
              <i v-else class="fa-solid fa-x text-medosync-red fa-xl me-4" />
              <p data-test-id="uploaded-file-name-text">{{ file.name }}</p>
            </div>
          </div>
          <section v-if="file">
            <div class="max-w-2xl">
              <label for="new-contract-name" class="block font-bold mb-2">New Contract Name</label>
              <input
                id="new-contract-name"
                type="text"
                placeholder="Enter new contract name"
                v-model="contractName"
                class="w-full rounded mb-8"
              />
              <label for="start-date" class="block font-bold mb-2">Start Date</label>
              <date-picker
                id="start-date"
                v-model:value="startDate"
                class="mb-8"
                value-type="YYYY-MM-DD"
                format="DD-MM-YYYY"
              />
            </div>
          </section>
        </template>
        <section v-if="errorMessage && errorDetailedByLine.length" class="pt-8">
          <div class="flex justify-between">
            <h3 class="text-xl font-medium text-medosync-red">List of error(s)</h3>
            <div class="flex items-center gap-3.5 cursor-pointer" @click="downloadErrorList">
              <i class="fa-solid fa-download fa-xl" />
              <span class="underline">Download error list</span>
            </div>
          </div>
          <div class="pt-6">
            <table class="min-w-full table-auto border-collapse border border-gray-200">
              <thead class="bg-[#C4C4D3] text-left h-12">
                <tr>
                  <td class="text-center" style="width: 15%">Table row</td>
                  <td class="pl-2" style="width: 25%">Error name</td>
                  <td class="pl-2" style="width: 60%">Description of error</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(error, rowIndex) in errorDetailedByLine"
                  :key="rowIndex"
                  :class="rowIndex % 2 === 0 ? 'bg-white' : 'bg-[#EEEFF3]'"
                  class="hover:bg-[#00a3f4]/50"
                >
                  <td class="text-center">{{ error.row }}</td>
                  <td class="p-2">{{ error.error }}</td>
                  <td class="p-2">{{ error.description }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <div v-if="file" class="flex justify-end items-center mt-6">
          <button
            v-if="!isFileUploadSuccess && !errorMessage"
            :disabled="isLoading || uploadButtonDisabled"
            type="button"
            class="flex items-center bg-medosync-blue px-6 py-2 text-sm rounded-full"
            @click="validateFieldsAndFile"
          >
            Upload File
          </button>
          <div class="flex gap-8" v-if="!isFileUploadSuccess && errorMessage && errorNoReuploadNeeded">
            <button
              :disabled="isLoading || uploadButtonDisabled"
              data-test-id="close-modal-button"
              type="button"
              class="flex items-center bg-medosync-blue px-6 py-2 text-sm rounded-full"
              @click="validateFieldsAndFile"
            >
              Revalidate File
            </button>
          </div>
          <div class="flex gap-8" v-if="!isFileUploadSuccess && errorMessage && !errorNoReuploadNeeded">
            <button
              :disabled="isLoading || uploadButtonDisabled"
              data-test-id="close-modal-button"
              type="button"
              class="flex items-center bg-medosync-blue px-6 py-2 text-sm rounded-full"
              @click="removeFile"
            >
              Upload a new file
            </button>
          </div>
          <button
            v-if="isFileUploadSuccess"
            data-test-id="close-modal-button"
            type="button"
            class="flex items-center bg-medosync-blue px-6 py-2 text-sm rounded-full"
            @click="openNewContractWindow()"
          >
            Start working with uploaded data
          </button>
        </div>
      </div>
      <DefaultModal
        v-if="sameYearWarning"
        :showManageModal="true"
        title="Your new contract has the same year as an existing contract"
        :text="sameYearWarningText"
        subtext="Do you want to continue with the upload?"
        secondaryButtonText="Cancel"
        primaryButtonText="Continue"
        @primary-changes="uploadFile"
        @secondary-changes="sameYearWarning = false"
        @close-modal="sameYearWarning = false"
      >
      </DefaultModal>
      <ToastComponent ref="toast"></ToastComponent>
    </template>
  </FullscreenOverlay>
</template>
