<template>
  <div
    ref="claimDetails"
    :class="[notesOpened ? 'third-width notes-transition-enter' : 'full-width notes-transition-exit']"
    class="py-8 px-6"
    v-if="claim"
  >
    <Breadcrumbs v-if="isTextBoxDisabled" :links="breadcrumbs" />
    <div v-else class="cursor-pointer w-fit" @click="backButton">
      <i class="fas fa-chevron-left mr-2.5"></i>
      back
    </div>

    <ClaimDetailsHeader
      v-if="claim"
      :claim="claim"
      :filteredDetails="insurerPaymentFiles"
      :editMode="!isTextBoxDisabled"
      :allClaimFormsNum="allClaimForms?.length || 0"
      @init-claim="getClaim(true)"
      @refresh-files="refreshFiles"
      @scroll-to-field="scrollToField"
    />
    <TabNav
      v-if="isTextBoxDisabled"
      :navs="navs"
      :selected="selectedTab"
      :max-items="notesOpened ? navs.length - 1 : navs.length"
      :sub-menu-items="allClaimForms"
      :selected-claim-form-id="claimData?.claim_form_id"
      :claimId="claim?.claim_id"
      :claim-consultant-id="claim?.claim_consultant_id"
      @switch-tab="switchTab"
      @add-item="newClaimFormTabOpen = true"
      @select-item="selectClaimForm"
      @remove-item="removeClaimForm"
    />

    <div v-if="!newClaimFormTabOpen" class="flex flex-row justify-between items-center my-2">
      <div class="flex relative">
        <h2 :data-id-test="dataIdTest" class="text-xl font-medium cursor-pointer text-blue-900">
          {{ tabTitle }}<span v-if="currentClaimTypeName"> - {{ currentClaimTypeName }}</span>
          <i
            v-if="selectedTab === 'claimForm' && claimData && isTextBoxDisabled && !claim.claim_is_readonly && showForm"
            class="fas fa-pen cursor-pointer ml-2"
            @click="disableTextBox()"
            data-test-id="edit-pen-icon"
          ></i>
          <i
            v-if="selectedTab == 'invoice' && isTextBoxDisabled && configRoute"
            class="fa-solid fa-pen ml-2"
            @click="disableTextBox()"
            data-test-id="edit-pen-icon-invoice"
          ></i>
          <i
            v-if="selectedTab == 'hospitalForm' && isTextBoxDisabled"
            class="fa-solid fa-pen ml-2"
            @click="disableTextBox()"
            data-test-id="edit-pen-icon-invoice"
          ></i>
          <i
            v-if="
              claimData &&
              selectedTab == 'claimForm' &&
              claimData.claim_form_type != 'self_pay' &&
              allClaimForms?.length === 1 &&
              isTextBoxDisabled
            "
            class="fa-regular fa-trash-can ml-4"
            @click="deleteFirstForm"
            data-test-id="delete-claim-icon"
          ></i>
        </h2>
      </div>
      <!-- Patient FORM  -->

      <div
        data-id-test="preview-claim-form-btn"
        v-if="selectedTab == 'patientForm'"
        class="cursor-pointer"
        @click="patientPdf()"
      >
        <i class="fa-regular fa-file-pdf fa-xl me-1"></i>
        <span class="underline text-sm pt-1">Preview form</span>
      </div>

      <div
        data-id-test="preview-claim-form-btn"
        v-if="selectedTab == 'hospitalForm'"
        class="cursor-pointer"
        @click="patientPdf()"
      >
        <i class="fa-regular fa-file-pdf fa-xl me-1"></i>
        <span class="underline text-sm pt-1">Preview form</span>
      </div>

      <!-- CLAIM FORM  -->
      <div
        data-id-test="preview-claim-form-btn"
        v-if="
          claimData && selectedTab == 'claimForm' && claimData.claim_form_type != 'self_pay' && !claimData.is_composite
        "
        class="cursor-pointer"
        @click="handlePreview"
      >
        <i class="fa-regular fa-file-pdf fa-xl me-1"></i>
        <span class="underline text-sm pt-1">Preview form</span>
      </div>

      <div
        data-id-test="preview-claim-form-btn"
        v-if="claim && selectedTab == 'invoice' && claim.insurance.insurer_name != 'Self Pay'"
        class="cursor-pointer"
        @click="handleInvoicePreview"
      >
        <i class="fa-regular fa-file-pdf fa-xl me-1"></i>
        <span class="underline text-sm pt-1">Preview invoice</span>
      </div>
    </div>

    <AdmissionClaimForm
      ref="claimFormRef"
      v-if="selectedTab === 'patientForm' && claimformData"
      :disableEditMode="false"
      :claimform="claimformData"
      :claim-section-warnings="claimSectionWarnings"
      :signature="patientSignature"
      :locatorPatient="locatorPatient"
      :locatorPatientInfo="locatorPatientInfo"
      :admission="claimData?.admission.on"
      @edit-mode="value => (formEditMode = value)"
      @generate-pdf="generatePdf"
    />

    <ClaimForm
      v-if="selectedTab == 'claimForm' && claimData && !newClaimFormTabOpen && showForm && !claim.is_composite"
      :claim="claimData"
      :claimDataPreviousState="claimDataPreviousState"
      @set-previous-form-state="setPreviousFormState"
      @update-header-info="updateHeaderInfo"
    />

    <HospitalForm
      v-if="selectedTab == 'hospitalForm'"
      :claim="claimData"
      :claimDataPreviousState="claimDataPreviousState"
      @set-previous-form-state="setPreviousFormState"
      @update-header-info="updateHeaderInfo"
    />

    <ClaimFormNew
      v-if="selectedTab == 'claimForm' && newClaimFormTabOpen"
      ref="claimFormNew"
      :claimConsultantId="claim?.claim_consultant_id"
      :claimId="claim?.claim_id"
      :appointmentId="claim?.app_id"
      :claimTypes="newClaimFormOptions"
      @add-form="addNewClaimToList"
      @close-adding-form="closeForm"
    />
    <ClaiAttchments
      v-if="selectedTab == 'attachments'"
      :files="files"
      :claim="claim"
      :uploadVal="uploadVal"
      @remove-file-from-list="removeFileFromList"
      @refetch-files="listFiles"
    />
    <Invoice v-if="selectedTab == 'invoice'" :claim="claim" />
    <InsurerPaymentFiles
      v-if="selectedTab == 'paymentFiles'"
      :filteredData="insurerPaymentFiles"
      :appointmentId="claim?.app_id"
      :consultantId="claim?.admitted_by?.person_id"
      :claimId="claim?.claim_id"
      :claim="claim"
    />
  </div>

  <DefaultModal
    v-if="removeSelectedItem"
    :showManageModal="true"
    :title="`Delete Claim Form `"
    :text="`Are you sure you want to delete this Consultant Claim?`"
    secondaryButtonText="Yes, delete <span class='block'>Claim Form</span>"
    primaryButtonText="Cancel"
    @secondary-changes="removeItem(removeSelectedItem)"
    @primary-changes="deleteFirstForm(true)"
    @close-modal="deleteFirstForm(true)"
  ></DefaultModal>
  <SpinnerLoader v-if="isLoading" />
  <ToastComponent ref="refToast"></ToastComponent>
</template>

<script setup>
import { ref, computed, onMounted, nextTick, watch, onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router';
import _ from 'lodash';
import { AssocType, ClaimPhase } from 'enums';
import Breadcrumbs from './Breadcrumbs.vue';
import ClaimDetailsHeader from './ClaimDetailsHeader.vue';
import TabNav from './TabNav.vue';
import { OpenAPIJSON, OpenAPIPDF } from 'api/openapi';
import ClaimForm from './ClaimForm.vue';
import { notes_store } from '@/store/notes';
import Invoice from '@/views/claims/invoices/Invoice.vue';
import ClaiAttchments from './ClaimAdditionalAttachments.vue';
import InsurerPaymentFiles from './InsurerPaymentFiles.vue';
import { useStore } from 'vuex';
import { ToastComponent } from 'ui';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';
import ClaimFormNew from './ClaimFormNew.vue';
import DefaultModal from '@/components/claim-forms/components/DefaultModal.vue';
import AdmissionClaimForm from '../../admission/AdmissionClaimForm.vue';
import { hospitalConfigStore } from '@/store/config';
import HospitalForm from './HospitalForm.vue';

// Handling API errors
const handleApiError = (error, customMessage) => {
  console.error('[API Error]:', error);
  const defaultMessage = 'Something went wrong. Please try again later.';
  const errorMessage = customMessage || defaultMessage;

  return {
    error: true,
    message: errorMessage
  };
};

// Loading wrapper
const withLoading = async (loadingRef, apiCall, options = {}) => {
  const { loadingDelay = 300, errorMessage, retryCount = 0 } = options;

  let attempts = 0;
  const loadingTimeout = setTimeout(() => {
    loadingRef.value = true;
  }, loadingDelay);

  try {
    while (attempts <= retryCount) {
      try {
        const result = await apiCall();
        return { data: result, error: null };
      } catch (error) {
        attempts++;
        if (attempts > retryCount) throw error;
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
  } catch (error) {
    return handleApiError(error, errorMessage);
  } finally {
    clearTimeout(loadingTimeout);
    loadingRef.value = false;
  }
};

// Constants
const WHITE_LIST_HOSPITAL_FORM = ref([
  // 'dev.hospital.medosync.com',
  // 'uat.hospital.medosync.com',
  // 'hospital.medosync.com',
  // 'uat.beacon.medosync.com',
  // 'beacon.medosync.com'
]);

const MAX_CLAIM_FORMS = ref(2);

// Core refs
const refToast = ref(null);
const isLoading = ref(true);
const route = useRoute();
const store = useStore();
const openApiPdf = new OpenAPIPDF();
const openApi = new OpenAPIJSON();
const locatorPatient = ref(null);
const locatorPatientInfo = ref(null);

// Data refs
const claimFormRef = ref(null);
const claimSectionWarnings = ref([]);
const allClaimFetchedData = ref([]);
const allClaimForms = ref([]);
const breadcrumbs = ref([]);
const claim = ref(null);
const claimData = ref(null);
const claimDataPreviousState = ref(null);
const claimTypes = ref(null);
const claimformData = ref(null);
const files = ref([]);
const filesCount = ref(0);
const insurerPaymentFiles = ref(null);
const patientSignature = ref(null);
const claimFormNew = ref(null);
const configRoute = ref(null);

// UI State refs
const selectedTab = ref('claimForm');
const showManageModal = ref(true);
const uploadVal = ref(false);
const newClaimFormTabOpen = ref(false);
const showForm = ref(true);
const removeSelectedItem = ref(null);
const newClaimFormOptions = ref([]);

// Navigation
const navs = ref([
  { type: 'patientForm', name: 'Patient Form', number: null, dataIdTest: 'patient-form-type', visible: false },
  { type: 'hospitalForm', name: 'Hospital Form', number: null, dataIdTest: 'hospital-form-tab', visible: false },
  { type: 'claimForm', name: 'Consultant Form', number: null, dataIdTest: 'claim-form-tab', visible: true },
  { type: 'invoice', name: 'Invoice', number: null, dataIdTest: 'invoice-tab', visible: true },
  {
    type: 'paymentFiles',
    name: 'Insurer Payment',
    number: 0,
    icon: null,
    dataIdTest: 'insurer-pymt-tab',
    visible: true
  },
  { type: 'attachments', name: 'Claim Documents', number: 0, dataIdTest: 'attachments-tab', visible: true }
]);

// Watchers
watch(
  () => claim.value?.claim_state,
  newState => {
    if (newState) {
      navSetIcon('paymentFiles', insurerPaymentFilesIcon.value);
    }
  }
);

watch(
  () => insurerPaymentFiles.value,
  newFiles => {
    if (newFiles) {
      navSetNumber('paymentFiles', newFiles.length);
    }
  }
);

watch(
  () => selectedTab.value,
  newTab => {
    if (newTab === 'claimForm' && newClaimFormTabOpen.value) {
      newClaimFormTabOpen.value = false;
    }
  }
);

// Computed Properties
const isTextBoxDisabled = computed(() => store.state.textBoxDisabled);

const tabTitle = computed(() => {
  return navs.value.find(nav => nav.type === selectedTab.value)?.name;
});

const dataIdTest = computed(() => {
  const dataIdTest = navs.value.find(nav => nav.type === selectedTab.value)?.dataIdTest;
  return `page-title-${dataIdTest}`;
});

const notesOpened = computed(() => notes_store.state.notesOpened);

const insurerPaymentFilesIcon = computed(() => {
  switch (claim.value?.claim_state) {
    case 'pended_with_hospital':
      return 'Yellow Triangle';
    case 'rejected':
      return 'Red Triangle';
    case 'part_paid':
      return 'Yellow Triangle';
    case 'paid_in_full':
      return 'Green Triangle';
    default:
      return null;
  }
});

const currentClaimTypeName = computed(() => {
  if (!claimTypes.value) return null;
  return claimTypes.value.find(type => type.claim_form_type === claimData.value?.claim_form_type)?.name;
});

// API Methods

/**
 * Retrieves claim types for the current insurer
 * Updates claimTypes state and sets new form options
 */
const getClaimTypes = async () => {
  if (!claim.value?.insurance?.insurer_id) {
    console.warn('No insurer_id available for getting claim types');
    return;
  }

  const insurer_id = claim.value.insurance?.insurer_id;
  const claim_consultant_id = claim.value.claim_consultant_id;

  try {
    claimTypes.value = await openApi.getInsurerClaimForms(insurer_id, null, null, claim_consultant_id);
    setNewClaimFormOptions(claimTypes.value);
    setAddIcon();
  } catch (err) {
    console.log(err);
    refToast.value.showToast(
      'Something went wrong getting the claim form. Please try again later.',
      'fa-solid fa-triangle-exclamation',
      'error'
    );
  }
};

/**
 * Removes a file from the files list and updates the counter
 * @param {string} fileId - ID of the file to remove
 */
const removeFileFromList = fileId => {
  files.value = files.value.filter(file => file.id !== fileId);
  filesCount.value = files.value.length;
  navSetNumber('attachments', filesCount.value);
};

/**
 * Refreshes both files and insurer payment files lists
 */

const getPatientClaimform = async locator => {
  if (!locator) {
    refToast.value?.showToast('Missing required locator parameter', 'fa-solid fa-triangle-exclamation', 'error');
    return;
  }
  locatorPatientInfo.value = {
    app_id: claim.value.app_id,
    claim_id: claim.value.claim_id,
    locator: locator
  };
};

const getClaim = async (setClaimFormOptions = false) => {
  const { data, error } = await withLoading(
    isLoading,
    async () => {
      const claim_consultant_id = parseInt(route.params.id);
      const result = await openApi.searchClaims(null, null, claim_consultant_id);
      return result;
    },
    {
      errorMessage: 'Error fetching claim data'
    }
  );

  if (error) {
    refToast.value?.showToast(error.message, 'fa-solid fa-triangle-exclamation', 'error');
    return;
  }

  claim.value = data.items[0];
  navSetIcon('paymentFiles', insurerPaymentFilesIcon.value);
  allClaimForms.value = await getAllClaimForms(claim.value.claim_consultant_id);

  if (setClaimFormOptions) {
    setNewClaimFormOptions(claimTypes.value);
  }

  if (allClaimForms.value?.length) {
    const claimFormData = await openApi.claimform_get(
      claim.value.app_id,
      claim.value.claim_id,
      allClaimForms.value[0].claim_form_id
    );
    claimData.value = claimFormData;
    addClaimToList(claimData.value);
  }
};

const getAllClaimForms = async id => {
  const { data, error } = await withLoading(
    isLoading,
    async () => {
      const response = await openApi.claimform_consultant_list({
        claim_ref: { id, type: 'id' }
      });
      return response;
    },
    {
      errorMessage: 'Error fetching claim forms'
    }
  );

  if (error) {
    refToast.value?.showToast(error.message, 'fa-solid fa-triangle-exclamation', 'error');
    return [];
  }

  return data.claim_forms;
};

const getAllClaims = async () => {
  const { data, error } = await withLoading(
    isLoading,
    async () => {
      return await openApi.claimform_patient_list(claim.value.app_id);
    },
    {
      errorMessage: 'Error fetching claims'
    }
  );

  if (error) {
    refToast.value?.showToast(error.message, 'fa-solid fa-triangle-exclamation', 'error');
    return;
  }

  const { claim_form_list } = data;

  if (claim_form_list.length > 0) {
    await getAppointmentSignature(claim_form_list[0].locator);
    await getPatientClaimform(claim_form_list[0].locator.claim_form_id);
    navSetVisible('patientForm');
  } else {
    navSetHidden('patientForm');
  }

  setAddIcon();
};

const listFiles = async () => {
  const { data, error } = await withLoading(
    isLoading,
    async () => {
      if (hospitalConfigStore.getters.is_single_consultant_hospital) {
        return await openApi.file_list_by_id(claim.value.claim_id, AssocType.ClaimNested);
      }
      return await openApi.file_list_by_id(claim.value.claim_consultant_id, AssocType.ConsultantClaim);
    },
    {
      errorMessage: 'Error fetching files'
    }
  );

  if (error) {
    refToast.value?.showToast(error.message, 'fa-solid fa-triangle-exclamation', 'error');
    return;
  }

  files.value = data.files;
  filesCount.value = data.files?.length || 0;
  navSetNumber('attachments', filesCount.value);
};

const listInsurerPaymentFiles = async () => {
  if (claim.value?.claim_phase === ClaimPhase.PRE_SUBMIT) {
    insurerPaymentFiles.value = [];
    return;
  }

  const { data, error } = await withLoading(
    isLoading,
    async () => {
      return await openApi.claim_statement_file_search(
        claim.value.claim_consultant.consultant_id,
        null,
        null,
        null,
        claim.value.invoice_no,
        null,
        null,
        claim.value.insurance.insurer_id,
        null
      );
    },
    {
      errorMessage: 'Error fetching insurer payment files'
    }
  );

  if (error) {
    refToast.value?.showToast(error.message, 'fa-solid fa-triangle-exclamation', 'error');
    return;
  }

  insurerPaymentFiles.value = data.items.filter(
    item => item.hospital_appointment_id === claim.value.hospital_appointment_id
  );
};

// UI Interaction Methods

const selectClaimForm = async ({ claimConsultantId, claimFormId }) => {
  if (claimData.value?.claim_form_id === claimFormId) return;

  const claimForm = allClaimFetchedData.value.find(claim => claim.claim_form_id === claimFormId);
  if (claimForm) {
    claimData.value = claimForm;
    return;
  }

  const { data, error } = await withLoading(
    isLoading,
    async () => {
      return await openApi.claimform_get(claim.value.app_id, claimConsultantId, claimFormId);
    },
    {
      errorMessage: 'Error fetching claim form'
    }
  );

  if (error) {
    refToast.value?.showToast(error.message, 'fa-solid fa-triangle-exclamation', 'error');
    return;
  }

  claimData.value = data;
  allClaimFetchedData.value.push(claimData.value);
};

const getAppointmentSignature = async locator => {
  if (!locator) {
    refToast.value?.showToast('Missing required locator parameter', 'fa-solid fa-triangle-exclamation', 'error');
    return;
  }
  locatorPatient.value = locator;
};

const removeItem = async item => {
  const claim_consultant_id = parseInt(route.params.id);
  const { error } = await withLoading(
    isLoading,
    async () => {
      await openApi.claimform_consultant_delete(claim_consultant_id, item.claim_form_id);
      return { success: true };
    },
    {
      errorMessage: 'Error removing claim form'
    }
  );

  if (error) {
    const errorMessage = error.response?.data?.message || 'An unexpected error occurred';
    refToast.value?.showToast(errorMessage, 'fa-solid fa-triangle-exclamation', 'error');
    return;
  }

  refToast.value.showToast('The claim form was deleted successfully.', 'fas fa-check', 'success');
  resetState();
  init();
  removeSelectedItem.value = null;
};

// UI Methods
const backButton = () => {
  if (newClaimFormTabOpen.value) {
    closeAddingModal();
  } else {
    if (_.isEqual(claimData.value, claimDataPreviousState.value)) {
      store.commit('setTextBoxDisabled', true);
      claimDataPreviousState.value = null;
    } else {
      store.commit('setRevertModal', true);
    }
  }
};

const closeAddingModal = () => {
  if (claimFormNew.value) {
    claimFormNew.value.closeAddingModal();
  }
};

const closeForm = () => {
  newClaimFormTabOpen.value = false;
  init();
};

const removeClaimForm = async claimFormId => {
  allClaimForms.value = allClaimForms.value.filter(claim => claim.claim_form_id !== claimFormId);
  if (claimData.value?.claim_form_id === claimFormId && allClaimForms.value.length) {
    selectClaimForm({
      claimConsultantId: claim.value.claim_consultant_id,
      claimFormId: allClaimForms.value[0].claim_form_id,
      appointmentId: claim.value.app_id
    });
  } else if (!allClaimForms.value.length) {
    claimData.value = null;
  }
  allClaimFetchedData.value = allClaimFetchedData.value.filter(claim => claim.claim_form_id !== claimFormId);
  setNewClaimFormOptions(claimTypes.value);
  setAddIcon();
};

const deleteFirstForm = value => {
  if (value === true) {
    showForm.value = false;
  }
  showForm.value = !showForm.value;

  removeSelectedItem.value = null;
  if (!showForm.value) {
    removeSelectedItem.value = claimData.value;
  }
};

const disableTextBox = () => {
  claimDataPreviousState.value = JSON.parse(JSON.stringify(claimData.value));
  store.commit('setTextBoxDisabled', false);
};

const enableTextBox = () => {
  store.commit('setTextBoxDisabled', true);
};

const setNewClaimFormOptions = async claimTypes => {
  const { error } = await withLoading(
    isLoading,
    async () => {
      allClaimForms.value = allClaimForms.value.map(claim => {
        const currentClaim = claimTypes.find(claimType => claimType.claim_form_type === claim.claim_form_type);
        if (currentClaim) {
          return {
            ...claim,
            display_name: currentClaim.name,
            claim_id: claim.claim_id
          };
        }
        return claim;
      });

      newClaimFormOptions.value = claimTypes.filter(
        claimType => !allClaimForms.value.some(claimForm => claimForm.claim_form_type === claimType.claim_form_type)
      );

      return { success: true };
    },
    {
      errorMessage: 'Error processing claim form options'
    }
  );

  if (error) {
    refToast.value?.showToast(error.message, 'fa-solid fa-triangle-exclamation', 'error');
  }
};

const setAddIcon = () => {
  if (newClaimFormOptions.value?.length && allClaimForms.value?.length < MAX_CLAIM_FORMS.value) {
    if (
      (newClaimFormOptions.value.length > 1 && allClaimForms.value[0]?.insurer_id === 6) ||
      newClaimFormOptions.value.length <= 1 ||
      allClaimForms.value.length === 0
    ) {
      if (!claimformData.value) {
        navSetIcon('claimForm', 'Add');
      }
    } else {
      navClearIcon('claimForm');
      navClearIcon('invoice');
    }
  } else {
    navClearIcon('claimForm');
    navClearIcon('invoice');
  }
};

// Navigation Methods
const getBreadcrumbs = () => {
  breadcrumbs.value = [
    { name: 'Home', to: '/navigator/claims/draft/all' },
    { name: 'All Claims', to: '/navigator/claims/' },
    { name: `Claim ${claim.value?.hospital_appointment_id}`, to: null }
  ];
};

const switchTab = type => {
  if (type === selectedTab.value) return;
  if (type === 'claimForm' && newClaimFormTabOpen.value) {
    newClaimFormTabOpen.value = false;
  }
  selectedTab.value = type;
};

const filteredNavs = () => {
  navs.value = navs.value.map(nav => {
    if (nav.type === 'hospitalForm') {
      nav.visible = WHITE_LIST_HOSPITAL_FORM.value.includes(window.location.hostname);
    }
    return nav;
  });
};

// Nav Utility Methods
const navSetNumber = (field_type_reference, value) => {
  navs.value = navs.value.map(nav => (nav.type === field_type_reference ? { ...nav, number: value } : nav));
};

const navSetIcon = (field_type_reference, icon) => {
  navs.value = navs.value.map(nav => (nav.type === field_type_reference ? { ...nav, icon } : nav));
};

const navClearIcon = field_type_reference => {
  navs.value = navs.value.map(nav => (nav.type === field_type_reference ? { ...nav, icon: null } : nav));
};

const navSetVisible = field_type_reference => {
  navs.value = navs.value.map(nav => (nav.type === field_type_reference ? { ...nav, visible: true } : nav));
};

const navSetHidden = field_type_reference => {
  navs.value = navs.value.map(nav => (nav.type === field_type_reference ? { ...nav, visible: false } : nav));
};

// Data Management Methods
const addClaimToList = async claimData => {
  const { error } = await withLoading(
    isLoading,
    async () => {
      const index = allClaimFetchedData.value.findIndex(claim => claim.claim_form_id === claimData.claim_form_id);

      if (index !== -1) {
        allClaimFetchedData.value[index] = claimData;
      } else {
        allClaimFetchedData.value.push(claimData);
      }
      return { success: true };
    },
    {
      errorMessage: 'Error adding claim to list'
    }
  );

  if (error) {
    refToast.value?.showToast(error.message, 'fa-solid fa-triangle-exclamation', 'error');
  }
};

const patientPdf = () => {
  claimFormRef.value.generatePdf();
};

const generatePdf = async fetchedClaimformData => {
  const { error } = await withLoading(
    async () => {
      await openApiPdf.claimformPdfPreview(fetchedClaimformData);
      return { success: true };
    },
    {
      errorMessage: 'Error generating PDF',
      timeout: 60000
    }
  );

  if (error) return;
};

const handlePreview = async () => {
  const { error } = await withLoading(
    isLoading,
    async () => {
      await openApiPdf.claimformPdfPreview(claimData.value);
      return { success: true };
    },
    {
      errorMessage: 'Error getting the claim form PDF',
      retryCount: 1
    }
  );

  if (error) {
    refToast.value?.showToast(
      'Error getting the claim form PDF. Please check if all mandatory fields are present and try again.',
      'fa-solid fa-triangle-exclamation',
      'error'
    );
  }
};

const handleInvoicePreview = async () => {
  const { error } = await withLoading(
    isLoading,
    async () => {
      await openApiPdf.invoicePdfPreview(claim.value);
      return { success: true };
    },
    {
      errorMessage: 'Error generating invoice preview'
    }
  );

  if (error) {
    refToast.value?.showToast(error.message, 'fa-solid fa-triangle-exclamation', 'error');
  }
};

const updateHeaderInfo = async ({ appId, mrn }) => {
  const { error } = await withLoading(
    isLoading,
    async () => {
      if (!claim.value) {
        throw new Error('No claim data available');
      }
      claim.value.hospital_appointment_id = appId;
      claim.value.patient.mrn = mrn;
      return { success: true };
    },
    {
      errorMessage: 'Error updating header information'
    }
  );

  if (error) {
    refToast.value?.showToast(error.message, 'fa-solid fa-triangle-exclamation', 'error');
  }
};

const addNewClaimToList = ({ claimForm, claimType }) => {
  allClaimFetchedData.value.push(claimForm);
  claimType.claim_form_id = claimForm.claim_form_id;
  allClaimForms.value.push(claimType);

  selectClaimForm({
    claimConsultantId: claimForm.claim_id,
    claimFormId: claimForm.claim_form_id,
    appointmentId: claimForm.app_id
  });

  setNewClaimFormOptions(claimTypes.value);
  setAddIcon();
  newClaimFormTabOpen.value = false;
};

const scrollToField = async elementId => {
  const wasClaimTab = selectedTab.value !== 'claimForm';

  if (wasClaimTab) {
    selectedTab.value = 'claimForm';
  }

  await nextTick();
  disableTextBox();

  await nextTick();
  setTimeout(
    () => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        element.focus({ preventScroll: true, focusVisible: true });
      }
    },
    wasClaimTab ? 400 : 0
  );
};

const setPreviousFormState = async newValue => {
  const { error } = await withLoading(
    isLoading,
    async () => {
      if (newValue) {
        claimDataPreviousState.value = JSON.parse(JSON.stringify(newValue));
      } else {
        if (!claimDataPreviousState.value) {
          throw new Error('No previous state available');
        }
        claimData.value = claimDataPreviousState.value;
        claimDataPreviousState.value = null;
      }
      return { success: true };
    },
    {
      errorMessage: 'Error setting previous form state'
    }
  );

  if (error) {
    refToast.value?.showToast(error.message, 'fa-solid fa-triangle-exclamation', 'error');
  }
};

const refreshFiles = () => {
  listFiles();
  listInsurerPaymentFiles();
};

const resetState = () => {
  allClaimFetchedData.value = [];
  claimData.value = null;
  claimDataPreviousState.value = null;
  claimTypes.value = null;
  allClaimForms.value = [];
  breadcrumbs.value = [];
  selectedTab.value = 'claimForm';
  showManageModal.value = false;
  files.value = [];
  filesCount.value = 0;
  uploadVal.value = false;
  insurerPaymentFiles.value = null;
  isLoading.value = false;
  newClaimFormTabOpen.value = false;
  newClaimFormOptions.value = [];
  showForm.value = true;
  removeSelectedItem.value = null;
};

// Initialization
onMounted(async () => {
  await init();
  const storedConfig = localStorage.getItem('configRoute');
  try {
    const config = JSON.parse(storedConfig);
    configRoute.value = config.edit_invoice_line_items;
  } catch (error) {
    console.error('Error parsing config', error);
  }
});

const init = async () => {
  await getClaim();

  if (hospitalConfigStore.getters.has_product_access) {
    getAllClaims();
  }

  await getClaimTypes();
  getBreadcrumbs();
  listFiles();
  enableTextBox();
  listInsurerPaymentFiles();
  filteredNavs();
};

onBeforeUnmount(() => {
  store.commit('setTextBoxDisabled', false);
});
</script>
<style lang="scss" scoped>
.third-width {
  width: calc(100% - 400px);
}

.full-width {
  width: 100%;
}

.addAttachments {
  background-color: #d9d9d9;
  border-width: 0.1px;
}

.modalDelete {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.switch-container {
  width: 50px;
}
</style>
